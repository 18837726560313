import moment from 'moment-timezone';
import 'moment/locale/ru';

export default {
    methods: {
        $now() {
            return moment().format('YYYY-MM-DD');
        },
        getDateFormat(data) {
            return moment.tz(data, 'Asia/Yekaterinburg').format('DD.MM.YYYY');
        },
        getDateTimeFormat(data) {
            let newData = moment.tz(data, 'Asia/Yekaterinburg');
            return newData.format('DD.MM.YYYY') + ' ' + newData.format('HH:mm');
        },
        getDateTimeFormatSeconds(data) {
            let newData = moment.tz(data, 'Asia/Yekaterinburg');
            return newData.format('DD.MM.YYYY') + ' ' + newData.format('HH:mm:ss');
        },
        $formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        $formatDateTime(date) {
            return moment(date).format('DD.MM.YYYY HH:mm');
        },
        $formatDateTimeSeconds(date) {
            return moment(date).format('DD.MM.YYYY HH:mm:ss');
        },
        getDate(data) {
            return moment.tz(data, 'Asia/Yekaterinburg');
        },
        num2str(n, text_forms) {
            n = Math.abs(n) % 100;
            var n1 = n % 10;
            if (n > 10 && n < 20) {
                return text_forms[2];
            }
            if (n1 > 1 && n1 < 5) {
                return text_forms[1];
            }
            if (n1 == 1) {
                return text_forms[0];
            }
            return text_forms[2];
        }
    }
};
