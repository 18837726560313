var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", [
    _c(
      "ul",
      {
        staticClass:
          "pagination b-pagination pagination-md justify-content-center",
      },
      [
        _c("li", { class: ["page-item", { disabled: _vm.isOnFirstPage }] }, [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage(1)
                },
              },
            },
            [_c("span", [_vm._v("«")])]
          ),
        ]),
        _c("li", { class: ["page-item", { disabled: _vm.isOnFirstPage }] }, [
          _c(
            "a",
            {
              staticClass: "page-link",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage("prev")
                },
              },
            },
            [_c("span", [_vm._v("<")])]
          ),
        ]),
        _vm.notEnoughPages
          ? _vm._l(_vm.totalPage, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  class: ["page-item", { active: _vm.isCurrentPage(n) }],
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.loadPage(n)
                        },
                      },
                    },
                    [_vm._v(_vm._s(n))]
                  ),
                ]
              )
            })
          : _vm._l(_vm.windowSize, function (n) {
              return _c(
                "li",
                {
                  key: n,
                  class: [
                    "page-item",
                    { active: _vm.isCurrentPage(_vm.windowStart + n - 1) },
                  ],
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page-link",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.loadPage(_vm.windowStart + n - 1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.windowStart + n - 1))]
                  ),
                ]
              )
            }),
        _c("li", { class: ["page-item", { disabled: _vm.isOnLastPage }] }, [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage("next")
                },
              },
            },
            [_c("span", [_vm._v(">")])]
          ),
        ]),
        _c("li", { class: ["page-item", { disabled: _vm.isOnLastPage }] }, [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { href: "" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadPage(_vm.totalPage)
                },
              },
            },
            [_c("span", [_vm._v("»")])]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }