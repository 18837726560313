var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("purchase-filters-controller", {
    attrs: {
      requestfilter: _vm.elpc.requestfilter,
      resetfilters: _vm.elpc.routereset,
      types: _vm.elpc.dictionaries.types,
    },
    on: { "filters:update": _vm.elpc.processRequestUpdate },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (pfc) {
          return [
            _c("div", { staticClass: "atmo-entity-filters" }, [
              _c(
                "form",
                {
                  staticClass: "atmo-form",
                  attrs: { id: "form-purchase-filters" },
                },
                [
                  _c("fieldset", { staticClass: "atmo-group atmo-mobile" }, [
                    _c(
                      "div",
                      { staticClass: "atmo-form__field" },
                      [
                        _c(
                          "multiselect",
                          {
                            attrs: {
                              id: "purchase-deliverables-groups",
                              options: _vm.elpc.dictionaries.deliverablesgroups,
                              multiple: true,
                              "allow-empty": true,
                              "close-on-select": true,
                              "clear-on-select": false,
                              "preserve-search": false,
                              "show-labels": false,
                              placeholder: "Выберите предметы закупки",
                              label: "title",
                              "track-by": "id",
                            },
                            on: {
                              select: function ($event) {
                                return pfc.processDictionaryFilterUpdate(
                                  $event,
                                  "add",
                                  "purchaseCategories"
                                )
                              },
                              remove: function ($event) {
                                return pfc.processDictionaryFilterUpdate(
                                  $event,
                                  "remove",
                                  "purchaseCategories"
                                )
                              },
                            },
                            model: {
                              value: pfc.deliverablesgroups,
                              callback: function ($$v) {
                                _vm.$set(pfc, "deliverablesgroups", $$v)
                              },
                              expression: "pfc.deliverablesgroups",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                attrs: { slot: "noOptions" },
                                slot: "noOptions",
                              },
                              [_vm._v("Нет данных.")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "noResult" }, slot: "noResult" },
                              [
                                _vm._v(
                                  "Записей, соответствующих вашему запросу, не найдено."
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "label",
                          { attrs: { for: "purchase-deliverables-groups" } },
                          [_vm._v("Предметы закупки")]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "atmo-form__field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: pfc.filters.conditionname,
                            expression: "pfc.filters.conditionname",
                          },
                        ],
                        attrs: {
                          type: "text",
                          id: "purchase-conditionname",
                          placeholder: "Укажите наименование ТРУ",
                          autocomplete: "off",
                        },
                        domProps: { value: pfc.filters.conditionname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              pfc.filters,
                              "conditionname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "label",
                        { attrs: { for: "purchase-conditionname" } },
                        [_vm._v("Наименование ТРУ")]
                      ),
                    ]),
                  ]),
                  _c(
                    "fieldset",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: pfc.isFiltersVisible,
                          expression: "pfc.isFiltersVisible",
                        },
                      ],
                      staticClass: "atmo-group atmo-tablet",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "atmo-form__field atmo-50" },
                        [
                          _c("dropdown-select", {
                            attrs: {
                              list: pfc.types,
                              currentid: pfc.filters.orderType,
                              initialid: pfc.filters.orderType,
                            },
                            on: {
                              "dropdown:select:selected": pfc.manageSelection,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ds) {
                                    return [
                                      _c("on-off", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (onoff) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "atmo-dropdown-select",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return onoff.toggle.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          !ds.hasSelected
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Укажите тип закупки"
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          ds.hasSelected
                                                            ? _c("span", {
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      ds.text
                                                                    ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          !onoff.ison
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "atmo-icon-angle-down",
                                                              })
                                                            : _c("i", {
                                                                staticClass:
                                                                  "atmo-icon-angle-up",
                                                              }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "ul",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: onoff.ison,
                                                              expression:
                                                                "onoff.ison",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "atmo-dropdown",
                                                        },
                                                        [
                                                          _c(
                                                            "li",
                                                            {
                                                              on: {
                                                                click: ds.clear,
                                                              },
                                                            },
                                                            [_vm._v("Любой")]
                                                          ),
                                                          _vm._l(
                                                            ds.list,
                                                            function (item) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  key: item.id,
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return ds.select(
                                                                          item.id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("span", {
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          item.title
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c(
                            "label",
                            { attrs: { for: "purchase-orderType" } },
                            [_vm._v("Тип закупки")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "atmo-form__field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: pfc.filters.customer,
                              expression: "pfc.filters.customer",
                            },
                          ],
                          attrs: {
                            type: "text",
                            id: "purchase-customer",
                            placeholder: "Укажите наименование/ИНН заказчика",
                            autocomplete: "off",
                          },
                          domProps: { value: pfc.filters.customer },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                pfc.filters,
                                "customer",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "purchase-customer" } }, [
                          _vm._v("Заказчик"),
                        ]),
                      ]),
                      _c("div", { staticClass: "atmo-form__field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: pfc.filters.regNumber,
                              expression: "pfc.filters.regNumber",
                            },
                          ],
                          attrs: {
                            type: "text",
                            id: "purchase-regNumber",
                            placeholder: "Укажите номер закупки",
                            autocomplete: "off",
                          },
                          domProps: { value: pfc.filters.regNumber },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                pfc.filters,
                                "regNumber",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "purchase-regNumber" } }, [
                          _vm._v("Регистрационный номер закупки"),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "fieldset",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: pfc.isFiltersVisible,
                          expression: "pfc.isFiltersVisible",
                        },
                      ],
                      staticClass: "atmo-group atmo-tablet",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "atmo-form__field" },
                        [
                          _c("currency-input", {
                            attrs: {
                              currency: "RUB",
                              locale: "ru",
                              "allow-negative": false,
                              precision: 0,
                              type: "text",
                              id: "purchase-priceStartFrom",
                              placeholder: "НМЦК от...",
                              autocomplete: "off",
                            },
                            model: {
                              value: pfc.filters.priceStartFrom,
                              callback: function ($$v) {
                                _vm.$set(pfc.filters, "priceStartFrom", $$v)
                              },
                              expression: "pfc.filters.priceStartFrom",
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "purchase-priceStartFrom" } },
                            [_vm._v("НМЦК от...")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "atmo-form__field" },
                        [
                          _c("currency-input", {
                            attrs: {
                              currency: "RUB",
                              locale: "ru",
                              "allow-negative": false,
                              precision: 0,
                              type: "text",
                              id: "purchase-priceStartTo",
                              placeholder: "НМЦК до...",
                              autocomplete: "off",
                            },
                            model: {
                              value: pfc.filters.priceStartTo,
                              callback: function ($$v) {
                                _vm.$set(pfc.filters, "priceStartTo", $$v)
                              },
                              expression: "pfc.filters.priceStartTo",
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "purchase-priceStartTo" } },
                            [_vm._v("НМЦК до...")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "atmo-form__field" },
                        [
                          _c("date-picker", {
                            attrs: {
                              id: "purchase-orderDateStart",
                              position: "bottom",
                              label: "Укажите дату проведения с",
                              "button-now-translation": "Сегодня",
                              format: "YYYY-MM-DD",
                              formatted: "DD.MM.YYYY",
                              "max-date": pfc.filters.orderDateFinish,
                              "no-label": !!pfc.filters.orderDateStart,
                              "auto-close": true,
                              "only-date": true,
                            },
                            model: {
                              value: pfc.filters.orderDateStart,
                              callback: function ($$v) {
                                _vm.$set(pfc.filters, "orderDateStart", $$v)
                              },
                              expression: "pfc.filters.orderDateStart",
                            },
                          }),
                          _c(
                            "label",
                            { attrs: { for: "purchase-orderDateStart-input" } },
                            [_vm._v("Дата проведения с...")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "atmo-form__field" },
                        [
                          _c("date-picker", {
                            attrs: {
                              id: "purchase-orderDateFinish",
                              position: "bottom",
                              label: "Укажите дату проведения по",
                              "button-now-translation": "Сегодня",
                              format: "YYYY-MM-DD",
                              formatted: "DD.MM.YYYY",
                              "min-date": pfc.filters.orderDateStart,
                              "no-label": !!pfc.filters.orderDateFinish,
                              "auto-close": true,
                              "only-date": true,
                            },
                            model: {
                              value: pfc.filters.orderDateFinish,
                              callback: function ($$v) {
                                _vm.$set(pfc.filters, "orderDateFinish", $$v)
                              },
                              expression: "pfc.filters.orderDateFinish",
                            },
                          }),
                          _c(
                            "label",
                            {
                              attrs: { for: "purchase-orderDateFinish-input" },
                            },
                            [_vm._v("Дата проведения по...")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "fieldset",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: pfc.isFiltersVisible,
                          expression: "pfc.isFiltersVisible",
                        },
                      ],
                      staticClass: "atmo-group atmo-tablet",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "atmo-form__field" },
                        [
                          _c(
                            "multiselect",
                            {
                              attrs: {
                                id: "purchase-municipalities",
                                options: _vm.elpc.dictionaries.municipalities,
                                multiple: true,
                                "allow-empty": true,
                                "close-on-select": true,
                                "clear-on-select": false,
                                "preserve-search": false,
                                "show-labels": false,
                                placeholder: "Выберите муниципалитет",
                                label: "title",
                                "track-by": "id",
                              },
                              on: {
                                select: function ($event) {
                                  return pfc.processDictionaryFilterUpdate(
                                    $event,
                                    "add",
                                    "municipalities"
                                  )
                                },
                                remove: function ($event) {
                                  return pfc.processDictionaryFilterUpdate(
                                    $event,
                                    "remove",
                                    "municipalities"
                                  )
                                },
                              },
                              model: {
                                value: pfc.municipalities,
                                callback: function ($$v) {
                                  _vm.$set(pfc, "municipalities", $$v)
                                },
                                expression: "pfc.municipalities",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noOptions" },
                                  slot: "noOptions",
                                },
                                [_vm._v("Нет данных.")]
                              ),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult",
                                },
                                [
                                  _vm._v(
                                    "Записей, соответствующих вашему запросу, не найдено."
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "label",
                            { attrs: { for: "purchase-municipalities" } },
                            [_vm._v("Муниципалитеты")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "ul",
                { staticClass: "atmo-buttons atmo-special atmo-mobile" },
                [
                  _c(
                    "li",
                    {
                      attrs: {
                        title:
                          "Кнопка станет активна, если изменить поля фильтра. При активной кнопке можно просто нажать Enter для поиска.",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button-inverse",
                          attrs: {
                            disabled:
                              !pfc.hasFiltersChanged || pfc.isFiltersEmpty,
                          },
                          on: { click: pfc.search },
                        },
                        [_vm._v("Найти")]
                      ),
                    ]
                  ),
                  _c("li", [
                    _c(
                      "button",
                      {
                        staticClass: "atmo-button-border-only",
                        on: { click: pfc.toggleFilters },
                      },
                      [
                        !pfc.isFiltersVisible
                          ? _c("span", [_vm._v("Все фильтры")])
                          : _vm._e(),
                        pfc.isFiltersVisible
                          ? _c("span", [_vm._v("Меньше фильтров")])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _c(
                    "li",
                    {
                      attrs: {
                        title: "Кнопка станет активна, если фильтр не пустой",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "atmo-button-border-only",
                          attrs: { disabled: pfc.isFiltersEmpty },
                          on: { click: pfc.reset },
                        },
                        [_vm._v("Сбросить")]
                      ),
                    ]
                  ),
                  _vm.purchases_map && _vm.purchases_map_url
                    ? _c(
                        "li",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "atmo-button-inverse",
                              attrs: { to: { name: "purchases.map" } },
                            },
                            [_vm._v("Карта закупок")]
                          ),
                          _c("span", { staticClass: "atmo-badge" }, [
                            _vm._v("Новое"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }