const initialState = () => ({
    id: null,
    existing_ktru_item_id: null,
    create_method: null, // 1 - на основании имеющейся, 2 - вручную
    name: '',
    ktru_group_id: null,
    ktru_type_id: null,
    description: null,
    okei_id: null,
    okpd_id: null,
    specs: [{ Name: 'Минимальные требования', Value: '' }]
});

// State object
const state = initialState();

// Getter functions
const getters = {};

// Actions
const actions = {};

// Mutations
const mutations = {
    UPDATE_NAME(state, name) {
        state.name = name;
    },
    SET_CREATE_METHOD(state, createMethod) {
        state.create_method = createMethod;
    },
    SET_EXISTING_KTRU(state, existingKtru) {
        state.id = null;
        state.existing_ktru_item_id = existingKtru.id;
        state.name = existingKtru.name;
        state.ktru_group_id = existingKtru.ktru_group_id;
        state.ktru_type_id = existingKtru.ktru_type_id;
        state.description = existingKtru.description;
        state.okei_id = existingKtru.okei_id;
        state.okpd_id = existingKtru.okpd_id;
        state.specs = [{ Name: 'Минимальные требования', Value: '' }, ...existingKtru.specs];
    },
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
