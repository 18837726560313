<template>
    <b-modal ref="ktru-select-modal" title="Поиск ТРУ" size="xl" scrollable centered no-close-on-esc no-close-on-backdrop hide-footer @close="cancelKtruSelectModal" @cancel="cancelKtruSelectModal" :visible="show">
        <b-row>
            <b-col md="4">
                <b-form-group label="Рег.номер">
                    <b-input-group>
                        <b-form-input v-model="ktruTableFilter.reg_number" @change="onKtruFilterChange" placeholder="Введите рег.номер" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!ktruTableFilter.reg_number"
                                @click="
                                    ktruTableFilter.reg_number = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Название">
                    <b-input-group>
                        <b-form-input v-model="ktruTableFilter.name" @change="onKtruFilterChange" placeholder="Введите название" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!ktruTableFilter.name"
                                @click="
                                    ktruTableFilter.name = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Спецификация">
                    <b-input-group>
                        <b-form-input v-model="ktruTableFilter.specs" @change="onKtruFilterChange" placeholder="Введите текст спецификации" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!ktruTableFilter.specs"
                                @click="
                                    ktruTableFilter.specs = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-form-group label="Код ОКПД2">
                    <b-input-group>
                        <b-form-input v-model="ktruTableFilter.okpd_code" @change="onKtruFilterChange" placeholder="Введите код ОКПД2 или его часть" />
                        <b-input-group-append>
                            <b-button
                                :disabled="!ktruTableFilter.okpd_code"
                                @click="
                                    ktruTableFilter.okpd_code = '';
                                    onKtruFilterChange();
                                "
                                size="sm">
                                <span>×</span>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="table-responsive">
            <vuetable
                ref="ktruvuetable"
                :fields="ktruTableColumns"
                :css="vuetable2Config.table"
                :sort-order="sortOrder"
                :load-on-start="true"
                :query-params="ktruTableQueryParams"
                :append-params="{ filter: ktruTableFilter }"
                :http-fetch="ktruFetchData"
                @vuetable:pagination-data="onKtruPaginationData"
                api-url="/ktru"
                data-path="data"
                pagination-path="meta"
                no-data-template="Данные не найдены">
                <template slot="specs" slot-scope="props">
                    <b-button v-b-toggle="'collapse-' + props.rowData.id" size="sm" variant="custom-green">{{ visible[props.rowData.id] ? 'Скрыть' : 'Показать' }} описание/характеристики</b-button>
                    <b-collapse :id="'collapse-' + props.rowData.id" v-model="visible[props.rowData.id]" class="mt-2">
                        <p v-if="props.rowData.description"><b>Описание:</b> {{ props.rowData.description }}</p>
                        <p v-if="props.rowData.specs && props.rowData.specs.length > 0"><b>Характеристики:</b></p>
                        <ul v-if="props.rowData.specs && props.rowData.specs.length > 0">
                            <li v-for="(spec, key) in props.rowData.specs" v-bind:key="'spec' + key">
                                <span style="font-weight: 900">{{ spec.Name }}</span
                                >: {{ spec.Value }}
                            </li>
                        </ul>
                    </b-collapse>

                    <!--          <ul>-->
                    <!--            <li v-for="(item, key) in props.rowData.specs" v-bind:key="item + key">-->
                    <!--              <span style="font-weight: 900">{{ item.Name }}</span-->
                    <!--              >: {{ item.Value }}-->
                    <!--            </li>-->
                    <!--          </ul>-->
                </template>
                <template slot="actions" slot-scope="props">
                    <b-btn size="sm" variant="primary" @click="selectKtruItem(props.rowData)">Выбрать</b-btn>
                </template>
            </vuetable>
        </div>
        <div class="text-center">
            <vuetable-pagination ref="ktrupagination" :css="vuetable2Config.pagination" @vuetable-pagination:change-page="onKtruChangePage" />
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    name: 'KtruSelectModal',
    props: ['show'],
    data() {
        return {
            selectedKtruItem: null,
            ktruTableColumns: [
                { name: 'reg_number', sortField: 'reg_number', title: 'Рег.номер', width: '150px' },
                { name: 'okpd_code', title: 'ОКПД2', width: '150px' },
                { name: 'name', sortField: 'name', title: 'Название', width: '300px' },
                { name: 'specs', title: 'Описание/характеристики' },
                { name: 'actions', title: 'Действия', width: '100px' }
            ],
            visible: [],
            sortOrder: [{ field: 'id', direction: 'asc' }],
            ktruTableFilter: {
                name: '',
                reg_number: '',
                specs: '',
                okpd_code: ''
            }
        };
    },
    methods: {
        selectKtruItem(selectedItem) {
            this.$emit('select', selectedItem);
        },
        ktruTableQueryParams(sortOrder, currentPage, perPage) {
            return {
                sortField: sortOrder[0].sortField,
                sortOrder: sortOrder[0].direction,
                page: currentPage,
                per_page: perPage
            };
        },
        onKtruPaginationData(paginationData) {
            this.$refs.ktrupagination.setPaginationData(paginationData);
        },
        onKtruChangePage(page) {
            this.$refs.ktruvuetable.changePage(page);
        },
        ktruFetchData(apiUrl, httpOptions) {
            return axios.get(apiUrl, httpOptions);
        },
        onKtruFilterChange() {
            this.$refs.ktruvuetable.refresh();
        },
        cancelKtruSelectModal() {
            this.selectedKtruItem = null;
            this.$emit('cancel');
        }
    }
};
</script>
