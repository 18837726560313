'use strict';

import getByPath from 'get-value';
import Config from '@core/js/ddriven/application/config/Config';
import FormatDate from '@lib/js/src/misc/FormatDate';

import { IDeliveryAddress } from '@core/js/ddriven/domain/model/contracts/ContractItem.valueobject';
import OrganizationVO, { IRawOrganizationData } from '@core/js/ddriven/domain/model/users/Organization.valueobject';
import DeliverableItemVO, { IRawNormalizedDeliverableItem } from '../../../common/deliverable/DeliverableItem.valueobject';
import ProposalItemVO, { IRawProposalItemData } from './ProposalItem.valueobject';
import ContractItemDetailsVO, { IRawContractDataFromPurchase } from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import FileAttachmentVO, { IRawFileAttachmentData } from '../../../common/FileAttachment.valueobject';
import SupplierRequirementsVO, { IRawSupplierRequirements, IRawSupplierRequirementsDocument, TSelfPOJO as SRTSelfPOJO } from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirements.valueobject';
import SupplierRequirementDetailsA31s1p1VO from '../../update/supplier-requirements/SupplierRequirementDetailsA31s1p1.valueobject';
import SRDA31s1p1SupportingDocumentVO from '../../update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import { PurchaseStatuses } from '../../../common/PurchaseStatuses.enum';
import { IRawKBKLimit } from '../../update/KBKLimitSpecificationItemEditable.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from '../../update/KBKLimitsSpecificationItemsEditable.collection';
import SupplierRequirementAdditionalVO, { TRawAdditional } from '../../update/supplier-requirements/SupplierRequirementAdditional.valueobject';

export interface IRawPurchaseDetailsDataItem {
    [index: string]: unknown;

    id: number;
    is_external?: boolean;
    is_hidden: boolean; // If the proposals should be hidden or not;
    is_private_provider: boolean; // Individual supplier;
    is_hold?: boolean; // When status_id === 1 && is_hold === true means purchase in summarization state;
    is_participation?: boolean;

    notice_reg_number: string;
    purchase_name: string;
    purchase_object: string | null;
    status_id: number;
    order_type: { name: string };
    payment_type: string;
    start_price: string;
    purchase_start: string;
    purchase_end: string;
    purchase_limit_year: string;
    purchase_length: number;
    duration_type_title: string;
    planned_contract_date: string;
    planned_end_date: string;
    additional_info: string | null;
    additional_contact_info: string | null;
    delivery: { address: string }[];
    order_plan: string;
    max_date: string;

    // Supplier Requirements.
    only_smp: boolean;
    supplier_reqs_sonko: boolean;
    supplier_reqs_b: boolean;
    supplier_reqs_a: boolean;
    supplier_reqs_c: boolean;
    supplier_reqs_a_text: string | null;
    supplier_reqs_rating: boolean;
    supplier_required_documents: IRawSupplierRequirementsDocument[];
    supplier_requirements: {
        additional: TRawAdditional;
    };

    organization: IRawOrganizationData;
    organizationFinal: IRawOrganizationData;
    documents: IRawFileAttachmentData[];
    items: IRawNormalizedDeliverableItem[];
    limits: IRawKBKLimit[];
    offers: IRawProposalItemData[] | null;
    finalOffer?: {
        supplier_requirements: IRawSupplierRequirements;
    };
    contract: IRawContractDataFromPurchase | null;
    without_limits: boolean;
    customer_contract_number: string | null;
    with_advance: boolean;
    advance_percentage: number | null;
    protocol_date: string | null;
}

export interface IRawOutATMOPurchaseDetailsDataItem {
    id: number;
    is_external: boolean;
    is_private_provider: boolean; // Individual supplier;
    reg_number: string;
    purchase_name: string;
    status_id: number;
    start_price: string;
    order_type: { name: string };
    planned_contract_date: string;
    planned_end_date: string;
    organization: IRawOrganizationData;
    organizationFinal: IRawOrganizationData;
    items: IRawNormalizedDeliverableItem[];
    main_file: IRawFileAttachmentData;
    files: IRawFileAttachmentData[];
}

export default class PurchaseItemDetailsVO {
    id: number | null;
    is_out_atmo: boolean;
    is_proposals_info_hidden: boolean;
    is_individual_supplier: boolean;
    is_summarizing: boolean;
    has_proposal_from_current_supplier: boolean;

    registration_number: string | null;
    description: string | null;
    purchase_object: string | null;
    status_id: number | null;
    status_title: string | null;
    type_title: string | null;
    type_id: number | null;
    starting_price: number | null;
    limit_year: number | null;
    duration: number | null;
    duration_type_title: string | null;
    publication_date: string | null;
    proposal_accept_end_date: string | null;
    planned_contract_signature_date: string | null;
    planned_fulfillment_date: string | null;
    unilateral_contract_termination_info: string | null;
    additional_contact_info: string | null;
    delivery_addresses: string[];
    delivery_schedule: string | null;
    customer: OrganizationVO;
    supplier: OrganizationVO;
    attachments: FileAttachmentVO[] | [];
    deliverables: DeliverableItemVO[] | [];
    kbklimits: KBKLimitsSpecificationItemsEditableCollection;
    proposals: ProposalItemVO[] | [];
    accepted_proposal: {
        supplier_requirements: SupplierRequirementsVO;
    } | null;
    contract: ContractItemDetailsVO | {};
    supplier_requirements: SupplierRequirementsVO;
    without_limits: boolean;
    customer_contract_number: string | null;
    with_advance: boolean;
    advance_percentage: number | null;
    protocol_date: string | null;

    constructor(rawPurchaseItemDetailsData?: IRawPurchaseDetailsDataItem) {
        const timezone = Config.get('timezone.customer') as string;
        this.id = this.valueOrNull(rawPurchaseItemDetailsData, 'id');

        this.is_out_atmo = !!(rawPurchaseItemDetailsData && rawPurchaseItemDetailsData.is_external);
        this.is_proposals_info_hidden = rawPurchaseItemDetailsData && rawPurchaseItemDetailsData.is_hidden ? rawPurchaseItemDetailsData.is_hidden : false;
        this.is_individual_supplier = rawPurchaseItemDetailsData ? rawPurchaseItemDetailsData.is_private_provider : false;
        this.is_summarizing = rawPurchaseItemDetailsData?.status_id === PurchaseStatuses.Accepting && !!rawPurchaseItemDetailsData?.is_hold;
        this.has_proposal_from_current_supplier = rawPurchaseItemDetailsData?.is_participation ?? false;

        this.registration_number = this.valueOrNull(rawPurchaseItemDetailsData, 'notice_reg_number');
        this.description = this.valueOrNull(rawPurchaseItemDetailsData, 'purchase_name');
        this.purchase_object = this.valueOrNull(rawPurchaseItemDetailsData, 'purchase_object');
        this.status_id = this.valueOrNull(rawPurchaseItemDetailsData, 'status_id');
        this.status_title = this.valueOrNull(rawPurchaseItemDetailsData, 'status');
        this.type_title = this.valueOrNull(rawPurchaseItemDetailsData, 'order_type.name');
        this.type_id = this.valueOrNull(rawPurchaseItemDetailsData, 'order_type_id');
        this.starting_price = rawPurchaseItemDetailsData ? parseFloat(rawPurchaseItemDetailsData.start_price) : null;
        this.limit_year = this.valueOrNull(rawPurchaseItemDetailsData, 'purchase_limit_year');
        this.duration = this.valueOrNull(rawPurchaseItemDetailsData, 'purchase_length');
        this.duration_type_title = this.valueOrNull(rawPurchaseItemDetailsData, 'duration_type_title');

        this.publication_date = rawPurchaseItemDetailsData ? FormatDate.dateFromISOUTCToTimezoned(rawPurchaseItemDetailsData.purchase_start, timezone) : null;
        this.proposal_accept_end_date = rawPurchaseItemDetailsData ? FormatDate.dateFromISOUTCToTimezoned(rawPurchaseItemDetailsData.purchase_end, timezone) : null;
        this.planned_contract_signature_date = rawPurchaseItemDetailsData ? new Date(rawPurchaseItemDetailsData.planned_contract_date).toISOString() : null;
        this.planned_fulfillment_date = rawPurchaseItemDetailsData && rawPurchaseItemDetailsData.planned_end_date ? FormatDate.dateFromISOUTCToTimezoned(rawPurchaseItemDetailsData.planned_end_date, timezone) : null;
        this.unilateral_contract_termination_info = this.valueOrNull(rawPurchaseItemDetailsData, 'additional_info');
        this.additional_contact_info = this.valueOrNull(rawPurchaseItemDetailsData, 'additional_contact_info');
        this.delivery_addresses = rawPurchaseItemDetailsData ? this.deliveryAddresses(rawPurchaseItemDetailsData.delivery) : [];
        this.delivery_schedule = this.valueOrNull(rawPurchaseItemDetailsData, 'order_plan');

        this.customer = rawPurchaseItemDetailsData ? new OrganizationVO(rawPurchaseItemDetailsData.organization) : ({} as OrganizationVO);
        this.supplier = rawPurchaseItemDetailsData ? new OrganizationVO(rawPurchaseItemDetailsData.organizationFinal, true) : ({} as OrganizationVO);
        this.attachments = rawPurchaseItemDetailsData ? this.attachmentsList(rawPurchaseItemDetailsData.documents) : [];
        this.deliverables = rawPurchaseItemDetailsData ? this.deliverablesList(rawPurchaseItemDetailsData.items) : [];
        this.kbklimits = rawPurchaseItemDetailsData?.limits ? KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(rawPurchaseItemDetailsData.limits) : new KBKLimitsSpecificationItemsEditableCollection();

        const rawProposals = this.valueOrNull(rawPurchaseItemDetailsData, 'offers');
        this.proposals = rawProposals ? ProposalItemVO.fromArray(rawProposals) : [];

        // REFACTOR: This should be refactored away with the API response payload rewrite.
        const supplierRequirementsAdapted = JSON.parse(JSON.stringify(rawPurchaseItemDetailsData?.finalOffer?.supplier_requirements || {}));
        supplierRequirementsAdapted.supplier_requirements = {
            additional: supplierRequirementsAdapted.additional
        };
        this.accepted_proposal = rawPurchaseItemDetailsData?.finalOffer?.supplier_requirements
            ? {
                  supplier_requirements: this.supplierRequirements(supplierRequirementsAdapted)
              }
            : null;

        this.contract = rawPurchaseItemDetailsData?.contract ? new ContractItemDetailsVO(rawPurchaseItemDetailsData.contract) : {};
        this.supplier_requirements = this.supplierRequirements(rawPurchaseItemDetailsData);
        this.without_limits = !!(rawPurchaseItemDetailsData && rawPurchaseItemDetailsData.without_limits);
        this.customer_contract_number = this.valueOrNull(rawPurchaseItemDetailsData, 'customer_contract_number');
        this.with_advance = this.valueOrNull(rawPurchaseItemDetailsData, 'with_advance');
        this.advance_percentage = this.valueOrNull(rawPurchaseItemDetailsData, 'advance_percentage');
        this.protocol_date = this.valueOrNull(rawPurchaseItemDetailsData, 'protocol_date');
    }

    get isPurchaseSummarized(): boolean {
        return [PurchaseStatuses.Summarized, PurchaseStatuses.Contractactive].includes(this.status_id as PurchaseStatuses);
    }

    get hasProtocol(): boolean {
        return [2, 3, 4, 5, 6, 10].includes(this.status_id as PurchaseStatuses);
    }

    get isContractActive(): boolean {
        return this.status_id === PurchaseStatuses.Contractactive;
    }

    get bestProposal(): ProposalItemVO | undefined {
        return this.proposals.find((proposal: ProposalItemVO) => {
            return proposal.is_best;
        });
    }

    public static factory(data: IRawPurchaseDetailsDataItem | IRawOutATMOPurchaseDetailsDataItem): PurchaseItemDetailsVO {
        return data.is_external ? PurchaseItemDetailsVO.factoryOutATMOPurchase(data as IRawOutATMOPurchaseDetailsDataItem) : new PurchaseItemDetailsVO(data as IRawPurchaseDetailsDataItem);
    }

    private valueOrNull(dataObject: IRawPurchaseDetailsDataItem | undefined, propertyOrPath: string): any | null {
        if (!dataObject) {
            return null;
        }
        const propertyValue = getByPath(dataObject, propertyOrPath as string);
        return propertyValue ? propertyValue : null;
    }

    private deliveryAddresses(data?: IDeliveryAddress[]): string[] {
        return data
            ? data.map((item) => {
                  return item.address;
              })
            : [];
    }

    private attachmentsList(data?: IRawFileAttachmentData[]): FileAttachmentVO[] {
        return data
            ? data.map((rawAttachmentData: IRawFileAttachmentData) => {
                  return new FileAttachmentVO(rawAttachmentData);
              })
            : [];
    }

    private deliverablesList(rawDeliverablesListData: IRawNormalizedDeliverableItem[]): DeliverableItemVO[] {
        return rawDeliverablesListData.map((rawDeliverableItemData: IRawNormalizedDeliverableItem) => {
            return new DeliverableItemVO(rawDeliverableItemData);
        });
    }

    private static factoryOutATMOPurchase(data: IRawOutATMOPurchaseDetailsDataItem): PurchaseItemDetailsVO {
        const interimData = {
            id: data.id,
            is_external: data.is_external,
            is_private_provider: data.is_private_provider,
            notice_reg_number: data.reg_number,
            purchase_name: data.purchase_name,
            status_id: data.status_id,
            order_type: data.order_type,
            start_price: data.start_price,
            planned_contract_date: data.planned_contract_date,
            planned_end_date: data.planned_end_date,
            organization: data.organization,
            organizationFinal: data.organizationFinal,
            items: data.items,
            contract: {
                id: null,
                contract_status: null,
                updated_at: null,
                customer: null,
                provider: null,
                contract_files: data.main_file ? [data.main_file] : [],
                contract_confirmation_files: data.files,
                finalOffer: undefined,
                contract_disagreement_files: [],
                annexes: [],
                fulfillment_attachments: []
            },
            supplier_required_documents: []
        };

        return new PurchaseItemDetailsVO(interimData as unknown as IRawPurchaseDetailsDataItem);
    }

    private supplierRequirements(source?: IRawPurchaseDetailsDataItem): SupplierRequirementsVO {
        const pojo: SRTSelfPOJO | undefined = source
            ? {
                  is_only_smb: source?.only_smp ?? false,
                  supplier_reqs_sonko: source?.supplier_reqs_sonko ?? false,
                  a31s1ps3_5ps7_11: source?.supplier_reqs_b,
                  a31s1p1: source?.supplier_reqs_a,
                  a31s1_1: source?.supplier_reqs_c,
                  a31s1p1_details: {
                      description: source?.supplier_reqs_a_text as string,
                      supporting_documents: source?.supplier_required_documents.map((document: IRawSupplierRequirementsDocument) => {
                          return {
                              id: document.id,
                              description: document.name ?? document.document_name,
                              attachment: document.storedDocument ? FileAttachmentVO.fromPOJO({ file_id: document.storedDocument.id, name: document.storedDocument.name, storage: 's3' }) : {}
                          } as SRDA31s1p1SupportingDocumentVO;
                      })
                  } as SupplierRequirementDetailsA31s1p1VO,
                  rating: source?.supplier_reqs_rating,
                  additional: new SupplierRequirementAdditionalVO(source?.supplier_requirements?.additional as unknown as TRawAdditional)
              }
            : void 0;

        return new SupplierRequirementsVO(pojo);
    }
}
