<template>
    <div>
        <b-modal :visible="show" title="Спецификация" centered hide-footer no-close-on-backdrop no-close-on-esc @close="cancelSpecModal" @cancel="cancelSpecModal">
            <b-row class="py-3">
                <b-col cols="3" class="fs-14 grey-color d-flex align-items-center">
                    <p class="m-0">ТРУ из каталога</p>
                </b-col>
                <b-col cols="9">
                    <b-btn @click="showKtruSelectModal" v-if="!selectedKtruItem">Выбрать ТРУ</b-btn>
                    <b-btn @click="clearSelectedKtru" v-if="selectedKtruItem">Сбросить</b-btn>
                </b-col>
            </b-row>
            <div v-if="selectedKtruItem">
                <b-row>
                    <b-col md="3" class="fs-14 grey-color">Группа ТРУ:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ selectedKtruItem.ktru_group_name }}</b-col>
                </b-row>
                <b-row>
                    <b-col md="3" class="fs-14 grey-color">Рег.номер:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ selectedKtruItem.reg_number }}</b-col>
                </b-row>
                <b-row>
                    <b-col md="3" class="fs-14 grey-color">Наименование:</b-col>
                    <b-col md="9" class="fs-14 grey-color">{{ selectedKtruItem.name }}</b-col>
                </b-row>
            </div>
            <b-row class="py-3">
                <b-col cols="3" class="fs-14 grey-color d-flex align-items-center">
                    <p class="m-0">Наименование товара/работы/услуги</p>
                </b-col>
                <b-col cols="9">
                    <b-textarea rows="5" v-model="spec.name" :disabled="!!selectedKtruItem" :state="$v.spec.name.$invalid ? !$v.spec.name.$invalid : null" aria-describedby="formdata_name_liveFeedback"></b-textarea>
                    <b-form-invalid-feedback id="formdata_name_liveFeedback" :state="!$v.spec.name.$invalid">Поле не заполнено, минимум 3 символа</b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="py-3">
                <b-col cols="3" class="fs-14 grey-color d-flex align-items-center">
                    <p class="m-0">ОКПД2</p>
                </b-col>
                <b-col cols="9" class="create-purchase-select">
                    <multiselect
                        v-model.lazy="spec.okpd"
                        placeholder="Начните вводить для поиска"
                        deselect-label="Отменить"
                        selected-label="Выбрано"
                        select-label="Выбрать"
                        track-by="id"
                        label="breadcrumb"
                        :options="okpds"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="isOkpdsLoading"
                        :disabled="!!selectedKtruItem"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="3"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        :state="$v.spec.okpd.$invalid ? !$v.spec.okpd.$invalid : null"
                        :class="$v.spec.okpd.$invalid ? 'is-invalid' : ''"
                        @search-change="asyncFindOkpds"
                        aria-describedby="formdata_okpd_liveFeedback">
                        <span slot="noOptions">Нет данных.</span>
                        <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                    </multiselect>
                    <b-form-invalid-feedback id="formdata_okpd_liveFeedback" :state="!$v.spec.okpd.$invalid">Выберите ОКПД2</b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="py-3">
                <b-col cols="3" class="fs-14 grey-color d-flex align-items-center">
                    <p class="m-0">Единица измерения</p>
                </b-col>
                <b-col cols="9" class="create-purchase-select">
                    <multiselect
                        v-model="spec.okei"
                        placeholder="Начните вводить для поиска"
                        deselect-label="Отменить"
                        selected-label="Выбрано"
                        select-label="Выбрать"
                        track-by="id"
                        label="breadcrumb"
                        :options="okeis"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="isOkeisLoading"
                        :disabled="!!selectedKtruItem"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="3"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="true"
                        :state="$v.spec.okei.$invalid ? !$v.spec.okei.$invalid : null"
                        :class="$v.spec.okei.$invalid ? 'is-invalid' : ''"
                        @search-change="asyncFindOkeis"
                        aria-describedby="formdata_okpd_liveFeedback">
                        <span slot="noOptions">Нет данных.</span>
                        <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                    </multiselect>
                    <b-form-invalid-feedback id="formdata_okpd_liveFeedback" :state="!$v.spec.okei.$invalid">Выберите единицу измерения</b-form-invalid-feedback>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-button @click="cancelSpecModal" class="text-uppercase mr-2" variant="custom-outline-secondary">Отменить</b-button>
                    <b-button @click="addSpec" class="text-uppercase" variant="custom-green" :disabled="$v.spec.$invalid">Добавить</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <ktru-select-modal :show="ktruSelectModalShown" @cancel="cancelKtruSelectModal" @select="selectKtruItem"></ktru-select-modal>
    </div>
</template>

<script>
import KtruSelectModal from '@/components/common/KtruSelectModal';
import Directories from '@/services/api/Directories';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'AddSpecModal',
    props: ['show'],
    mixins: [validationMixin],
    components: {
        KtruSelectModal
    },
    data() {
        return {
            isOkpdsLoading: false,
            isOkeisLoading: false,
            ktruSelectModalShown: false,
            selectedKtruItem: null,
            selectedKtruItemId: null,
            okpds: [],
            okeis: [],
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 0,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                okpd: null
            }
        };
    },
    validations: {
        spec: {
            name: { required, minLength: minLength(3) },
            okei: { required },
            okpd: { required }
        }
    },
    created() {
        this.$_debounceTimer = null;
    },
    methods: {
        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },
        asyncFindOkpds(query) {
            this.debounce(() => {
                this.isOkpdsLoading = true;
                Directories.findOkpds(query).then((response) => {
                    this.okpds = response.data;
                    this.isOkpdsLoading = false;
                });
            }, 750);
        },
        asyncFindOkeis(query) {
            this.debounce(() => {
                this.isOkeisLoading = true;
                Directories.findOkeis(query).then((response) => {
                    this.okeis = response.data;
                    this.isOkeisLoading = false;
                });
            }, 750);
        },
        cancelKtruSelectModal() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.ktruSelectModalShown = false;
        },
        showKtruSelectModal() {
            this.ktruSelectModalShown = true;
        },
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.clearSelectedKtru();
            this.$emit('cancel');
        },
        addSpec() {
            this.$emit('add', this.spec);
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.cancelKtruSelectModal();
        },
        selectKtruItem(selectedItem) {
            this.selectedKtruItem = selectedItem;
            this.selectedKtruItemId = this.selectedKtruItem.id;
            this.spec.name = this.selectedKtruItem.name;
            this.spec.okei = this.selectedKtruItem.okei;
            this.spec.okpd = this.selectedKtruItem.okpd;
            this.spec.ktru = this.selectedKtruItem;
            this.spec.ktru_item_id = this.selectedKtruItem.id;
            this.ktruSelectModalShown = false;
        },
        clearSelectedKtru() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
        }
    }
};
</script>
