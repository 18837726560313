import apiFz223 from './fz223';

export const $api = {
    // legacy: {
    //   directories: {
    //     paymentTypes: new LegacyDirectoryPaymentTypes(),
    //     paymentTerms: new LegacyDirectoryPaymentTerms(),
    //     purchaseCategories: new LegacyDirectoryPurchaseCategories(),
    //   },
    //   organizations: new LegacyOrganizations(),
    // },
    fz223: apiFz223
};
