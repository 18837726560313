<template>
    <div id="atmo-proposals" class="atmo-hash-jump-point atmo-content-group">
        <h3>Предложения участников закупочной сессии</h3>

        <p v-if="!$props.pivc.hasPoroposals && !$props.pivc.isProposalsInfoHidden">Нет поданных предложений</p>
        <p v-if="$props.pivc.isProposalsInfoHidden">Наступил период скрытия предложений</p>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator" v-if="$props.pivc.purchaseitem.protocol_date">
            <li>
                <dt>Дата и время формирования итогового протокола:</dt>
                <dd>{{ $props.pivc.purchaseitem.protocol_date | formatdate('withSeconds') }}</dd>
            </li>
        </ul>

        <div class="table-responsive">
            <table v-if="$props.pivc.hasPoroposals" class="atmo-content-table atmo-proposals">
                <thead>
                    <tr>
                        <th class="atmo-place">Место</th>
                        <th class="atmo-rating">Рейтинг</th>
                        <th class="atmo-submit-date">Дата и время подачи</th>
                        <th class="atmo-price-total">Стоимость, руб.</th>
                        <!-- <th v-if="$props.pivc.isPurchaseSummarized" class="atmo-quantity">Количество</th> -->
                        <!-- <th v-if="$props.pivc.isPurchaseSummarized" class="atmo-units">Ед.изм</th> -->
                        <!-- <th v-if="$props.pivc.isPurchaseSummarized" class="atmo-price-unit">Цена за ед., руб.</th> -->
                        <th class="atmo-supplier">Поставщик</th>
                        <th v-if="($props.pivc.isPurchaseSummarized || $props.pivc.hasContract) && $props.pivc.isUserEngagedCustomer" class="atmo-attachments">Документы</th>
                        <th v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract" class="atmo-signature">Эл. подпись</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(proposal, index) in this.$props.proposals" v-bind:key="index">
                        <td v-text="index + 1" class="atmo-place"></td>
                        <td class="atmo-rating">
                            <span v-if="index === 0">
                                <span v-if="$props.pivc.isProposalsInfoHidden">Ожидает расчета</span>
                                <span v-else>Лучшее предложение<span v-if="proposal.is_current_supplier_proposal"> (моё)</span></span>
                            </span>
                            <span v-else><span v-if="proposal.is_current_supplier_proposal">Мое предложение</span></span>
                            <p v-if="proposal.is_declined"><b>Предложение отклонено.</b><br />Причина отклонения: <span v-text="proposal.decline_reason"></span></p>
                        </td>
                        <td class="atmo-submit-date">{{ proposal.submitted_at | formatdate('withSeconds') }}</td>
                        <td class="atmo-price-total">{{ proposal.total_price | formatnumber }}</td>
                        <!-- <td v-if="$props.pivc.isPurchaseSummarized" class="atmo-quantity">5</td> -->
                        <!-- <td v-if="$props.pivc.isPurchaseSummarized" class="atmo-units">6</td> -->
                        <!-- <td v-if="$props.pivc.isPurchaseSummarized" class="atmo-price-unit">7</td> -->
                        <td class="atmo-supplier">
                            <span v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract || $props.pivc.hasPurchaseProtocol">
                                <router-link v-bind:to="{ name: 'ProviderShow', params: { id: proposal.supplier.id } }" target="_blank" class="atmo-button-link">{{ proposal.supplier.title_brief }}</router-link>
                            </span>
                            <span v-else>Поставщик {{ index + 1 }}</span>
                        </td>
                        <td v-if="($props.pivc.isPurchaseSummarized || $props.pivc.hasContract) && $props.pivc.isUserEngagedCustomer" class="atmo-attachments">
                            <ul v-if="proposal.hasAttachments()">
                                <li v-for="(attachment, index) in proposal.attachments" v-bind:key="index">
                                    <button v-on:click="$props.pivc.downloadFileAttachment(attachment)" class="atmo-button-link" v-text="attachment.name"></button>
                                </li>
                            </ul>
                            <span v-else>Нет</span>
                        </td>
                        <td v-if="$props.pivc.isPurchaseSummarized || $props.pivc.hasContract" class="atmo-signature">
                            <button v-on:click.stop="$props.pivc.invokeSignaturePopup([proposal.signature])" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подписи">
                                <i class="atmo-icon-certificate"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <proposal-price-chart v-slot:default="ppc" v-if="this.$props.proposals.length > 1" v-bind:proposals="this.$props.proposals">
            <section class="atmo-chart">
                <h5>Диаграмма цен предложений</h5>
                <span v-text="ppc.item"></span>
                <div class="ct-chart ct-double-octave"></div>
            </section>
        </proposal-price-chart>
    </div>
</template>

<style lang="scss" src="../styles/chart/atmo_chartist-settings.scss"></style>
<style lang="scss" src="../styles/chart/chartist.scss"></style>
<style lang="less" src="../styles/chart/chart-settings.less"></style>

<script>
import ProposalsPriceChart from '@core/js/viewmodels/purchases/item/view/chart/ProposalsPriceChart.viewmodel';

export default {
    props: {
        pivc: { required: true, type: Object },
        proposals: { required: true, type: Array },
        purchaseitem: { required: true, type: Object }
    },
    components: {
        'proposal-price-chart': ProposalsPriceChart
    }
};
</script>
