'use strict';

import { Route } from 'vue-router';

// REFACTOR: Try to replace with URLSearchParams;
// https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams

import queryStringParser, { ParsedQuery } from 'query-string';

import ICommonRequest from '@core/js/ddriven/application/http/requests/common/interfaces/ICommonRequest';
import { isObject } from 'lodash';

export default class CommonRequest implements ICommonRequest {
    [index: string]: unknown;

    filter: Record<string, unknown>;
    // status: null | number; // DEPRECATED;
    page: null | number;

    constructor() {
        this.filter = {};
        this.page = null;
    }

    public static actualOrDefault(propertyName: string, defaultValue: unknown, dataObject?: unknown) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const propertyValue = dataObject && dataObject[propertyName] !== undefined ? dataObject[propertyName] : null;
        return dataObject && propertyValue ? propertyValue : defaultValue;
    }

    public static initializeFilter(filter: Record<string, unknown>, filterData?: Record<string, unknown>) {
        Object.keys(filter).forEach((key: string) => {
            filter[key] = this.actualOrDefault(key, null, filterData);
        });
    }

    public static normalizeToNumber(value: string): null | number {
        return value !== null ? parseInt(value, 10) : null;
    }

    public static fromQueryObject(queryObject: Route['query'], requestConstructor: unknown): ICommonRequest {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tempRequest = new requestConstructor();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const filterObject: Record<string, unknown> = queryObject.filter && queryObject.filter.length > 0 ? JSON.parse(queryObject.filter) : {};

        const filter = Object.keys(tempRequest.filter).reduce((accumulator: Record<string, unknown>, key: string) => {
            accumulator[key] = filterObject[key] ? filterObject[key] : null;
            return accumulator;
        }, {} as Record<string, unknown>);

        const commonRequestData: ICommonRequest = ['filter', 'status', 'page'].reduce((accumulator: ICommonRequest, key: string) => {
            const value = key === 'filter' ? filter : queryObject[key];
            accumulator[key] = value && value !== '' ? value : null;
            return accumulator;
        }, {} as ICommonRequest);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return new requestConstructor(commonRequestData);
    }

    public toQueryString(): string {
        const filterQueryItems: string = Object.keys(this.filter).reduce(this.objectValuesFilterReducer.bind(this.filter), []).join(',');
        const otherQueryItems: string = ['status', 'page'].reduce(this.objectValuesReducer.bind(this), []).join('&');

        const queryItems: string[] = [];

        if (filterQueryItems.length > 0) {
            queryItems.push(`filter={${filterQueryItems}}`);
        }

        if (otherQueryItems.length > 0) {
            queryItems.push(otherQueryItems);
        }

        return queryItems.join('&');
    }

    public toPlainObject(): ParsedQuery<string> {
        const queryString = this.toQueryString();
        return queryStringParser.parse(queryString);
    }

    public plainObjectViaStringify(): Record<string, unknown> {
        return JSON.parse(JSON.stringify(this));
    }

    protected objectValuesReducer(accumulator: string[], key: string) {
        const value: unknown = this[key];
        if (value) {
            accumulator.push(`${key}=${value}`);
        }
        return accumulator;
    }

    protected objectValuesFilterReducer(accumulator: string[], key: string) {
        const value: unknown = this[key];

        if (value) {
            accumulator.push(`"${key}":` + (typeof value === 'string' ? `"${value}"` : typeof value === 'object' ? JSON.stringify(value) : `${value}`));
        }
        return accumulator;
    }
}
