'use strict';

import GSCDetailsVO, { IRawGSCDetails, IRawAnnexGSCDetails } from './GSCDetails.valueobject';
import DeliverableItemRequirementVO, { IRawDeliverableItemRequirement } from './DeliverableItemRequirement.valueobject';
import roundToTwoDecimals from '@/rearchitected/lib/js/src/misc/roundToTwoDecimals';

export interface IRawDeliverableItem {
    id: number | null;
    count: number | null;
    name: string;
    okpd: {
        id: number | null;
        code: string;
        name: string | null;
    } | null;
    okei: {
        id: number | null;
        local_symbol: string;
    };
    ktru: IRawGSCDetails | null;
}

export interface IRawAnnexDeliverableItem {
    id: number;
    amount: number;
    name: string;
    okpd_code: string;
    okei_id: number;
    okei_name: string;
    ktru_item: IRawAnnexGSCDetails;
    price: number;
    total_cost: number;
}

export interface IRawContractDeliverableItem {
    count: number;
    name: string;
    price: number;
    okpd: {
        id: number;
        code: string;
        name: string;
    };
    local_symbol: string;
    ktru: IRawGSCDetails;
}

export interface IRawGSCDictionaryItem {
    id: number | null;
    name: string;
    okpd: {
        id: number;
        code: string;
        name: string;
    };
    okei: {
        id: number;
        local_symbol: string;
    };
    reg_number: string;
    ktru_group_name: string;
    type: string;
    description: string;
    specs: IRawDeliverableItemRequirement[];
}

export interface IRawNormalizedDeliverableItem extends IRawDeliverableItem {
    okei_id: number | null;
    price: number | null;
    total_cost: number | null;

    // NB: Have to normalize the API response due to its deficient non-normalized response data formats.
    local_symbol?: string;
}

export default class DeliverableItemVO {
    id: number | null;
    quantity: number | null;
    price_per_unit: number | null;
    price_total: number | null;
    title: string;
    okpd_id: number | null;
    okpd_code: string | null;
    okpd_title: string | null;
    okei_id: number | null;
    okei_symbolic_code: string;
    gsc_details: GSCDetailsVO | null;
    requirements: DeliverableItemRequirementVO[] | null;

    constructor(data: IRawNormalizedDeliverableItem) {
        this.id = data.id;
        this.quantity = data.count;
        this.price_per_unit = data.price ?? null;
        this.price_total = data.total_cost ?? (data.price && data.count ? roundToTwoDecimals(data.price * data.count) : null);
        this.title = data.name;
        this.okpd_id = data.okpd?.id || null;
        this.okpd_code = data.okpd?.code || null;
        this.okpd_title = data.okpd?.name || null;
        this.okei_id = data.okei?.id || null;

        // NB: Have to normalize the API response due to its deficient non-normalized response data formats.
        this.okei_symbolic_code = data.okei?.local_symbol ?? data.local_symbol;
        this.gsc_details = data.ktru ? new GSCDetailsVO(data.ktru) : null;
        this.requirements = data.ktru && data.ktru.specs ? this.fromPurchaseItemDetailsArray(data.ktru.specs) : null;
    }

    public static fromAnnexItem(data: IRawAnnexDeliverableItem): DeliverableItemVO {
        const tempData: IRawNormalizedDeliverableItem = {
            id: data.id,
            okei_id: null,
            count: data.amount,
            price: data.price,
            total_cost: data.total_cost,
            name: data.name,
            okpd: {
                id: null,
                code: data.okpd_code,
                name: null
            },
            okei: {
                id: null,
                local_symbol: data.okei_name
            },
            ktru: data.ktru_item
                ? {
                      id: data.ktru_item.id,
                      okei_id: data.okei_id,
                      reg_number: data.ktru_item.reg_number,
                      ktru_group_name: null,
                      name: null,
                      type: null,
                      description: null,
                      specs: data.ktru_item.specs
                  }
                : null
        };
        return new DeliverableItemVO(tempData);
    }

    public static fromContractItem(data: IRawContractDeliverableItem): DeliverableItemVO {
        const tempData: IRawNormalizedDeliverableItem = {
            id: data.ktru && data.ktru.id,
            okei_id: data.ktru && data.ktru.okei_id,
            count: data.count,
            price: data.price,
            total_cost: roundToTwoDecimals(data.count * data.price),
            name: data.name,
            okpd: {
                id: data.okpd && data.okpd.id,
                code: data.okpd && data.okpd.code,
                name: data.okpd && data.okpd.name
            },
            okei: {
                id: data.ktru && data.ktru.okei_id,
                local_symbol: data.local_symbol
            },
            ktru: {
                id: data.ktru && data.ktru.id,
                okei_id: data.ktru && data.ktru.okei_id,
                reg_number: data.ktru && data.ktru.reg_number,
                ktru_group_name: data.ktru && data.ktru.ktru_group_name,
                name: data.ktru && data.ktru.name,
                type: data.ktru && data.ktru.type,
                description: data.ktru && data.ktru.description,
                specs: data.ktru && data.ktru.specs
            }
        };
        return new DeliverableItemVO(tempData);
    }

    public static fromGSCDictionaryItem(data: IRawGSCDictionaryItem): DeliverableItemVO {
        const adaptedData: IRawNormalizedDeliverableItem = {
            id: data.id,
            okei_id: null,
            count: null,
            price: null,
            total_cost: null,
            name: data.name,
            okpd: {
                id: data.okpd.id,
                code: data.okpd.code,
                name: data.okpd.name
            },
            okei: {
                id: data.okei.id,
                local_symbol: data.okei.local_symbol
            },
            ktru: {
                id: data.id as number,
                okei_id: data.okei.id,
                reg_number: data.reg_number,
                ktru_group_name: data.ktru_group_name,
                name: data.name,
                type: data.type,
                description: data.description,
                specs: data.specs
            }
        };
        return new DeliverableItemVO(adaptedData);
    }

    private fromPurchaseItemDetailsArray(dataArray: IRawDeliverableItemRequirement[]): DeliverableItemRequirementVO[] {
        return dataArray.map((data: IRawDeliverableItemRequirement) => {
            return new DeliverableItemRequirementVO(data);
        });
    }
}
