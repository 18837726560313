export default [
    {
        path: '/fz223/purchases',
        component: () => import(/* webpackChunkName: "fz223" */ '@/modules/fz223/views/fz223-purchases-list'),
        name: 'Fz223PurchasesList'
    },
    {
        path: '/fz223/purchases/create',
        component: () => import(/* webpackChunkName: "fz223" */ '@/modules/fz223/views/fz223-purchase-create'),
        name: 'Fz223PurchaseCreate'
    },
    {
        path: '/fz223/purchases/create/:id',
        component: () => import(/* webpackChunkName: "fz223" */ '@/modules/fz223/views/fz223-purchase-create'),
        name: 'Fz223PurchaseDraftEdit'
    },
    {
        path: '/fz223/purchases/:id',
        component: () => import(/* webpackChunkName: "fz223" */ '@/modules/fz223/views/fz223-purchase-show'),
        name: 'Fz223PurchaseShow',
        children: [
            {
                path: 'order_info',
                name: 'Fz223PurchaseOrderInfo',
                component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchase-info/fz223-purchase-order-info')
            }
            // {
            //   path: 'proposal_dynamics',
            //   name: 'Fz223PurchaseProposalDynamics',
            //   component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchaseInfo/PurchaseProposalDynamics'),
            // },
            // {
            //   path: 'protocol',
            //   name: 'Fz223PurchaseProtocol',
            //   component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchaseInfo/PurchaseFinalProtocol'),
            // },
            // {
            //   path: 'contract',
            //   name: 'Fz223PurchaseContract',
            //   component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchaseInfo/PurchaseContract'),
            // },
            // {
            //   path: 'contract_execution',
            //   name: 'Fz223PurchaseContractExecution',
            //   component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchaseInfo/ContractExecution'),
            // },
            // {
            //   path: 'execution',
            //   name: 'Fz223PurchaseExecutionDocuments',
            //   component: () => import(/* webpackChunkName: "main" */ '@/modules/fz223/components/purchases/purchaseInfo/PurchaseExecutionDocuments'),
            // },
        ]
    }
];
