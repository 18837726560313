var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "td",
    { staticClass: "atmo-ctc-details-dropdown" },
    [
      this.hasRequirements
        ? _c("on-off", {
            attrs: { options: { onEsc: false, onBody: false } },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (onoff) {
                    return [
                      _c("div", { staticClass: "atmo-component-wrapper" }, [
                        _c(
                          "button",
                          {
                            staticClass: "atmo-button-icon",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return onoff.toggle.apply(null, arguments)
                              },
                            },
                          },
                          [
                            !onoff.ison
                              ? _c(
                                  "span",
                                  {
                                    attrs: { title: "Показать характеристики" },
                                  },
                                  [_c("i", { staticClass: "atmo-icon-show" })]
                                )
                              : _c(
                                  "span",
                                  { attrs: { title: "Скрыть характеристики" } },
                                  [_c("i", { staticClass: "atmo-icon-hide" })]
                                ),
                          ]
                        ),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: onoff.ison,
                                expression: "onoff.ison",
                              },
                            ],
                            staticClass: "atmo-list",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("li", [
                              _c("dt", [_vm._v("Описание")]),
                              _vm._v(" : "),
                              _c("dd", {
                                domProps: {
                                  textContent: _vm._s(_vm.description),
                                },
                              }),
                            ]),
                            _vm._l(
                              _vm.requirements,
                              function (requirement, index) {
                                return _c("li", { key: index }, [
                                  _c("dt", {
                                    domProps: {
                                      textContent: _vm._s(requirement.title),
                                    },
                                  }),
                                  _vm._v(" : "),
                                  _c("dd", {
                                    domProps: {
                                      textContent: _vm._s(
                                        requirement.description
                                      ),
                                    },
                                  }),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              2614631453
            ),
          })
        : _c("span", [_vm._v("—")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }