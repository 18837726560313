<template>
    <div class="atmo-content-group">
        <h3 id="atmo-supply-terms">Условия поставки</h3>

        <ul class="atmo-content-block-2-columns atmo-no-item-separator">
            <li class="atmo-align-top">
                <dt class="atmo-help">
                    Адреса для доставки товаров/выполнения работ/оказания услуг:
                    <i class="atmo-icon-question-mark"></i>
                    <span class="atmo-help__text">Укажите новый адрес или выберите ранее сохраненный адрес из Адресной книги.</span>
                </dt>
                <dd>
                    <address-list-input-contoller v-slot:default="alic" v-bind:addhandler="this.$props.addhandler" v-bind:removehandler="this.$props.removehandler" v-bind:useraddressbook="this.$props.useraddressbook" v-bind:addresseslist="$props.inputs.delivery_addresses">
                        <div class="atmo-component-wrapper">
                            <AddressesList.view v-bind:controller="alic" />
                            <AddressAutocomplete.view v-bind:controller="alic" />
                            <AddressBook.view v-bind:controller="alic" />
                        </div>
                    </address-list-input-contoller>
                </dd>
            </li>
            <li>
                <dt>График поставки товаров (выполнения работ, оказания услуг):</dt>
                <dd class="atmo-form__field atmo-inverse">
                    <input type="text" v-model="$props.inputs.delivery_schedule" />
                </dd>
            </li>
        </ul>
    </div>
</template>

<style lang="less" src="../styles/index.less"></style>

<script>
import AddressListInputController from '@core/js/viewmodels/purchases/item/update/address/AddressListInputController.viewmodel';

import AddressesListView from './address/AddressesList.view.vue';
import AddressAutocompleteView from './address/AddressAutocomplete.view.vue';
import AddressBookView from './address/AddressBook.view.vue';

export default {
    props: {
        inputs: { required: true, type: Object },
        errors: { required: true, type: Object },
        useraddressbook: { required: true, type: Array },
        addhandler: { required: true, type: Function },
        removehandler: { required: true, type: Function }
    },
    components: {
        'address-list-input-contoller': AddressListInputController,

        'AddressesList.view': AddressesListView,
        'AddressAutocomplete.view': AddressAutocompleteView,
        'AddressBook.view': AddressBookView
    }
};
</script>
