'use strict';

import fl44 from './fl44/index';
import fl223 from './fl223/index';

const GroupsStoreModule = {
    namespaced: true,

    modules: {
        fl44,
        fl223
    }
};

export default GroupsStoreModule;
