import axios from 'axios';

export default {
    //FRONT METHODS
    getSectionsList() {
        return axios.get('/information_sections').then((response) => {
            return response.data;
        });
    },
    getSection(id) {
        return axios.get('/information_sections/' + id).then((response) => {
            return response.data;
        });
    },
    downloadFile(id, originalName, name, extention) {
        axios({
            url: '/information_sections/download/' + id,
            method: 'GET',
            responseType: 'blob' // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', originalName || name + '.' + extention);
            document.body.appendChild(link);
            link.click();
        });
    }
};
