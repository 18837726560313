'use strict';

import ApplicationServiceLocator from '@/rearchitected/core/js/ddriven/application/services/ApplicationServiceLocator';
import State, { ActionContext } from 'vuex';

const actions = {
    forPurchaseEditable: async function (context: ActionContext<typeof State, typeof State>): Promise<void> {
        const responseDeliverablesGroups = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadDeliverablesGroups();
        const responseUserAddressBook = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadUserAddressBook();
        const responsePurchaseTypes = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadPurchaseTypes();

        context.commit('updatePurchaseEditableDictionaries', {
            deliverablesGroupsData: responseDeliverablesGroups.data.data,
            userAddressBookData: responseUserAddressBook.data.data,
            purchaseTypesData: responsePurchaseTypes.data.data
        });
    },

    loadPurchaseTypes: async function (context: ActionContext<typeof State, typeof State>) {
        const responsePurchaseTypes = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadPurchaseTypes();

        context.commit('setPurchaseTypes', responsePurchaseTypes.data.data);
    },

    loadDeliverablesGroups: async function (context: ActionContext<typeof State, typeof State>) {
        const responseDeliverablesGroups = await (context.rootState as any).rearchitected.utils.atmoapi.dictionaries.loadDeliverablesGroups();

        context.commit('setDeliverablesGroups', responseDeliverablesGroups.data.data);
    },

    loadMunicipalities: async function (context: ActionContext<typeof State, typeof State>) {
        const responseMunicipaities = await ApplicationServiceLocator.get('api').dictionaries.loadMunicipalities();

        context.commit('setMunicipalities', responseMunicipaities.data.data);
    }
};

export default actions;
