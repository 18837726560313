'use strict';

import { RouteConfig, RouteRecord } from 'vue-router';
import purchases from './purchases';
import contracts from './contracts';

const routes: Array<RouteConfig> = [...purchases, ...contracts];

export function findByRoutePath(path: string, routes: RouteConfig[]): undefined | RouteConfig {
    return routes.find((route: RouteConfig) => {
        return route.path === path;
    });
}

export function replaceByRoutePath(srcPath: string, destPath: string, destRoutes: RouteConfig[]) {
    const srcRoute = findByRoutePath(srcPath, routes);

    return destRoutes.map((destRoute: RouteConfig) => {
        return destRoute.path === destPath && srcRoute ? srcRoute : destRoute;
    });
}

export function replaceWithManyByRoutePath(srcPath: string, destPath: string, destRoutes: RouteConfig[]): RouteConfig[] {
    const srcRoutes = routes.filter((routeConfig: RouteConfig) => {
        return routeConfig.path.indexOf(srcPath) !== -1;
    });

    const replaceAtPosition = destRoutes.findIndex((routeConfig: RouteConfig) => {
        return routeConfig.path === destPath;
    });

    // replaceAtPosition < 0 ? destRoutes : destRoutes.splice(replaceAtPosition, 1, ...srcRoutes);
    return replaceAtPosition < 0 ? destRoutes : [...destRoutes.slice(0, replaceAtPosition), ...srcRoutes, ...destRoutes.slice(replaceAtPosition + 1)];
}

export default routes;
