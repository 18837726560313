'use strict';

import CommonRequest from '@/rearchitected/core/js/ddriven/application/http/requests/common/CommonRequest';
import ICommonRequest from '@core/js/ddriven/application/http/requests/common/interfaces/ICommonRequest';
import PurchaseRequestFilter, { IPurchaseRequestFilter } from './PurchaseRequestFilter.valueobject';

export interface IPurchaseRequest {
    [index: string]: unknown;

    filter: string | undefined | IPurchaseRequestFilter;
    status: null | number;
    page: null | number;
}

export interface IQueryObject extends IPurchaseRequest {
    filter: string | undefined;
}

export default class PurchaseRequest extends CommonRequest implements ICommonRequest {
    [index: string]: unknown;

    filter: IPurchaseRequestFilter;
    status: null | number;
    page: null | number;

    constructor(purchaseRequestData?: IPurchaseRequest) {
        super();

        this.status = null;

        /**
         * REFACTOR: Normalize string number inputs from API to numbers.
         */
        this.filter = new PurchaseRequestFilter();

        Object.keys(this.filter).forEach((key: string) => {
            this.filter[key] = PurchaseRequest.actualOrDefault(key, null, purchaseRequestData?.filter);
        });

        /**
         * Normalize the string inputs from API to numbers.
         */
        const status = PurchaseRequest.actualOrDefault('status', null, purchaseRequestData);
        const page = PurchaseRequest.actualOrDefault('page', null, purchaseRequestData);

        this.status = status !== null ? parseInt(status, 10) : null;
        this.page = page !== null ? parseInt(page, 10) : null;
    }

    private static filtered(purchaseRequestData?: IPurchaseRequest): PurchaseRequest {
        return new PurchaseRequest(purchaseRequestData);
    }
}
