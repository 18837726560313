<template>
    <b-container fluid>
        <b-modal
            ref="login-modal"
            @show="onShow"
            @close.prevent="onClose"
            v-model="showModal"
            size="lg"
            centered
            :hide-header-close="hideHeaderClose"
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
            :header-class="hideHeaderClose ? 'pl-0 pb-0' : 'pb-0'"
            body-class="pt-0"
            title-class="fs-28 pl-4"
            title="Вход в личный кабинет">
            <div class="d-block text-center">
                <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                    <b-form-group class="fs-18 py-0 fw-600" label="Выберите вариант авторизации" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group class="fw-400" v-model="selected" :aria-describedby="ariaDescribedby">
                            <b-form-radio value="certificate">ЭЦП</b-form-radio>
                            <b-form-radio value="login">Логин/пароль</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-overlay>
                <hr class="my-0" />
            </div>
            <LoginWithCertificate v-if="selected === 'certificate'" @close="onClose" @loadStart="onLoadStart" @loadEnd="onLoadEnd" @showClose="hideHeaderClose = false" @hideClose="hideHeaderClose = true" />
            <LoginWithPassword v-else-if="selected === 'login'" @close="onClose" @loadStart="onLoadStart" @loadEnd="onLoadEnd" @hideClose="hideHeaderClose = true" />
            <LoginInstruction v-else @close="onClose" />
        </b-modal>
    </b-container>
</template>

<script>
import LoginWithCertificate from '@/components/auth/login-with-certificate.vue';
import LoginWithPassword from '@/components/auth/login-with-password.vue';
import LoginInstruction from '@/components/auth/login-instruction.vue';

export default {
    name: 'Login',
    components: { LoginInstruction, LoginWithPassword, LoginWithCertificate },
    props: {
        showModal: {
            default: false
        }
    },
    data() {
        return {
            selected: '',
            isLoading: false,
            hideHeaderClose: false
        };
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        onShow() {
            this.selected = '';
            this.hideHeaderClose = false;
        },
        onLoadStart() {
            this.isLoading = true;
        },
        onLoadEnd() {
            this.isLoading = false;
        }
    }
};
</script>

<style>
.login-title {
    font-size: 26px !important;
}
</style>
