import axios from 'axios';

export default {
    requestEmailConfirmation(email) {
        return axios.post('/auth/request_email_confirmation', { email: email });
    },
    checkEmailConfirmation(email, code, username = null) {
        return axios.post('/auth/check_email_confirmation', {
            username: username,
            email: email,
            code: code
        });
    },
    requestPhoneConfirmation(phone) {
        return axios.post('/auth/request_phone_confirmation', { phone: phone });
    },
    checkPhoneConfirmation(phone, code) {
        return axios.post('/auth/check_phone_confirmation', {
            phone: phone,
            code: code
        });
    }
};
