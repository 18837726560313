var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-content-group table-responsive",
      attrs: { id: "atmo-deliverables" },
    },
    [
      _c("table", { staticClass: "atmo-content-table" }, [
        _vm._m(0),
        _c(
          "tbody",
          [
            _vm._l(this.$props.deliverables, function (deliverable, index) {
              return _c(
                "tr",
                { key: index },
                [
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(
                        deliverable.gsc_details.registration_number
                      ),
                    },
                  }),
                  _c("td", {
                    domProps: { textContent: _vm._s(deliverable.okpd_code) },
                  }),
                  _c("td", {
                    domProps: { textContent: _vm._s(deliverable.title) },
                  }),
                  _c("DeliverableRequirements.view", {
                    attrs: {
                      requirements: deliverable.requirements,
                      description: deliverable.gsc_details
                        ? deliverable.gsc_details.description
                        : null,
                    },
                  }),
                  _c("td", {
                    domProps: {
                      textContent: _vm._s(deliverable.okei_symbolic_code),
                    },
                  }),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "atmo-button-icon",
                        attrs: { title: "Добавить спецификацию в закупку" },
                        on: {
                          click: function ($event) {
                            return _vm.$props.controller.selected(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "atmo-icon-add-to-list" })]
                    ),
                  ]),
                ],
                1
              )
            }),
            this.isEmpty
              ? _c("tr", { staticClass: "atmo-centered" }, [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _c("span", { staticClass: "atmo-error" }, [
                      _vm._v("Позиции КТРУ по вашему запросу не найдены."),
                    ]),
                    _c(
                      "span",
                      [
                        _vm._v(" Вы можете "),
                        _c(
                          "router-link",
                          {
                            staticClass: "atmo-button-link",
                            attrs: {
                              to: { name: "KtruRequestsCreate" },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "сформировать запрос на добавление позиции КТРУ"
                            ),
                          ]
                        ),
                        _vm._v("."),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Рег.номер ТРУ")]),
        _c("th", [_vm._v("Код ОКПД2")]),
        _c("th", [_vm._v("Наименование товара (работ, услуг)")]),
        _c("th", [_vm._v("Характеристики ТРУ")]),
        _c("th", [_vm._v("Ед.изм")]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }