var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        description: _vm.description,
        label: _vm.label + ":",
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _vm.withValidation
        ? [
            _c(
              "b-input-group",
              [
                _c("b-form-input", {
                  staticClass: "fs-14",
                  attrs: {
                    id: _vm.id,
                    state: _vm.state,
                    disabled:
                      _vm.disabled || (_vm.emailConfirmed && !_vm.isEdit),
                    type: "email",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit("input")
                    },
                  },
                  model: {
                    value: _vm.text,
                    callback: function ($$v) {
                      _vm.text = $$v
                    },
                    expression: "text",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.emailConfirmed
                      ? _c(
                          "b-button",
                          {
                            staticClass: "text-uppercase fs-14",
                            staticStyle: { height: "35px" },
                            attrs: {
                              variant: "custom-danger",
                              disabled: _vm.v !== null && !_vm.state,
                            },
                            on: { click: _vm.sendEmailConfirmationRequest },
                          },
                          [_vm._v("Подтвердить")]
                        )
                      : _c(
                          "b-button",
                          {
                            staticClass: "text-uppercase fs-14",
                            staticStyle: { height: "35px" },
                            attrs: { variant: "custom-green", disabled: "" },
                          },
                          [_vm._v("Подтвержден")]
                        ),
                  ],
                  1
                ),
              ],
              1
            ),
            !_vm.state
              ? [
                  _c(
                    "b-form-invalid-feedback",
                    { attrs: { "force-show": "" } },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  ),
                ]
              : _vm._e(),
            _c("success-confirm-modal", {
              attrs: { "success-confirm": _vm.successConfirm },
              on: { hide: _vm.hideSuccessConfirm },
            }),
            _c(
              "b-modal",
              {
                ref: "email-confirmation-modal",
                attrs: {
                  title:
                    "Введите код подтверждения, отправленный на указанный адрес электронной почты",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                  "hide-header-close": "",
                  centered: "",
                  "hide-footer": "",
                },
                model: {
                  value: _vm.showEmailConfirmationModal,
                  callback: function ($$v) {
                    _vm.showEmailConfirmationModal = $$v
                  },
                  expression: "showEmailConfirmationModal",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-block mb-4" },
                  [
                    _c("the-mask", {
                      staticClass: "form-control",
                      attrs: { placeholder: "Введите код", mask: "######" },
                      model: {
                        value: _vm.emailConfirmationCode,
                        callback: function ($$v) {
                          _vm.emailConfirmationCode = $$v
                        },
                        expression: "emailConfirmationCode",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-2 text-uppercase",
                        attrs: { variant: "custom-outline-secondary" },
                        on: { click: _vm.cancelEmailConfirmation },
                      },
                      [_vm._v("Отмена ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase",
                        attrs: { variant: "custom-green" },
                        on: { click: _vm.checkEmail },
                      },
                      [_vm._v(" Подтвердить ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : [
            _c("b-form-input", {
              staticClass: "fs-14",
              attrs: { id: _vm.id, state: _vm.state, disabled: _vm.disabled },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            }),
            !_vm.state
              ? [
                  _c("b-form-invalid-feedback", [
                    _vm._v(_vm._s(_vm.errorMessage)),
                  ]),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }