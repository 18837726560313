var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("h2", { staticClass: "grey-color font-weight-bold my-3" }, [
                _vm._v("Спецификация"),
              ]),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  !_vm.noAddSpec
                    ? _c(
                        "b-button",
                        {
                          staticStyle: {
                            color: "var(--green-color) !important",
                          },
                          attrs: { variant: "custom-unstyle-grey" },
                          on: {
                            click: function ($event) {
                              _vm.showSpec = true
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "atmo-icon-attachment" }),
                          _vm._v("  "),
                          _vm._v(" Добавить позицию спецификации "),
                        ]
                      )
                    : _vm._e(),
                  _vm.withAddDelivery
                    ? _c(
                        "b-button",
                        {
                          staticStyle: {
                            color: "var(--green-color) !important",
                          },
                          attrs: {
                            variant: "custom-unstyle-grey",
                            disabled: _vm.hasDeliverySpec,
                          },
                          on: { click: _vm.addDelivery },
                        },
                        [
                          _c("img", {
                            staticClass: "mr-2",
                            attrs: {
                              src: "/images/delivery.svg",
                              alt: "delivery",
                            },
                          }),
                          _vm._v(" Добавить доставку "),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                class: _vm.v.$invalid || _vm.specErrors ? "is-invalid" : "",
              },
              [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { width: "1" } }),
                      _c("th", { attrs: { width: "150px" } }, [
                        _vm._v("Рег.номер ТРУ"),
                      ]),
                      _c("th", [_vm._v("Наименование товара/работы/услуги")]),
                      _c("th", { attrs: { width: "100px" } }, [
                        _vm._v("Код ОКПД2"),
                      ]),
                      _c("th", { attrs: { width: "300px" } }, [
                        _vm._v("Описание/характеристики"),
                      ]),
                      _c("th", { staticStyle: { "min-width": "140px" } }, [
                        _vm._v("Количество"),
                      ]),
                      _c("th", { attrs: { width: "100px", nowrap: "" } }, [
                        _vm._v("Ед.изм"),
                      ]),
                      _vm.withPrice
                        ? _c("th", { staticStyle: { "min-width": "140px" } }, [
                            _vm._v("Цена за ед., руб."),
                          ])
                        : _vm._e(),
                      _vm.withPrice
                        ? _c("th", { staticStyle: { "min-width": "140px" } }, [
                            _vm._v("Стоимость, руб."),
                          ])
                        : _vm._e(),
                      _c("th", { attrs: { width: "1" } }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.specs, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(item.number)),
                          ]),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(
                              _vm._s(item.ktru ? item.ktru.reg_number : "—")
                            ),
                          ]),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(item.okpd ? item.okpd.code : "—")),
                          ]),
                          _c(
                            "td",
                            { staticClass: "align-text-top" },
                            [
                              item.ktru
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle",
                                            value: "collapse-" + index,
                                            expression: "'collapse-' + index",
                                          },
                                        ],
                                        attrs: {
                                          size: "sm",
                                          variant: "custom-green",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.visible[index]
                                              ? "Скрыть"
                                              : "Показать"
                                          ) + " описание/характеристики"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        staticClass: "mt-2",
                                        attrs: { id: "collapse-" + index },
                                        model: {
                                          value: _vm.visible[index],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.visible, index, $$v)
                                          },
                                          expression: "visible[index]",
                                        },
                                      },
                                      [
                                        item.ktru.description
                                          ? _c("p", [
                                              _c("b", [_vm._v("Описание:")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.ktru.description)
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.ktru.specs &&
                                        item.ktru.specs.length > 0
                                          ? _c("p", [
                                              _c("b", [
                                                _vm._v("Характеристики:"),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        item.ktru.specs &&
                                        item.ktru.specs.length > 0
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                item.ktru.specs,
                                                function (spec, specIndex) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key:
                                                        index +
                                                        "_spec_" +
                                                        specIndex,
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(spec.Name)
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " - " +
                                                          _vm._s(spec.Value) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                : [_vm._v("—")],
                            ],
                            2
                          ),
                          _vm.withCountEdit
                            ? _c(
                                "td",
                                {
                                  staticClass: "align-text-top",
                                  staticStyle: { "text-align": "right" },
                                },
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "stop-number-mousewheel",
                                        rawName: "v-stop-number-mousewheel",
                                      },
                                    ],
                                    staticClass: "fs-14",
                                    class:
                                      _vm.specs[index].count > 0
                                        ? ""
                                        : "is-invalid",
                                    staticStyle: {
                                      "padding-top": "0",
                                      "padding-bottom": "0",
                                      height: "auto",
                                    },
                                    attrs: {
                                      type: "number",
                                      step: "any",
                                      min: "1",
                                      disabled: _vm.isDeliverySpec(item),
                                    },
                                    model: {
                                      value: _vm.specs[index].count,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.specs[index],
                                          "count",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "specs[index].count",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "td",
                                {
                                  staticClass: "align-text-top",
                                  staticStyle: { "text-align": "right" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.specs[index].count) + " "
                                  ),
                                ]
                              ),
                          _c("td", { staticClass: "align-text-top" }, [
                            _vm._v(_vm._s(item.okei.local_symbol)),
                          ]),
                          _vm.withPrice
                            ? _c(
                                "td",
                                { staticClass: "align-text-top" },
                                [
                                  _c("currency-input", {
                                    staticClass: "form-control fs-14",
                                    class:
                                      _vm.specs[index].price > 0
                                        ? ""
                                        : "is-invalid",
                                    staticStyle: {
                                      "padding-top": "0",
                                      "padding-bottom": "0",
                                      height: "auto",
                                    },
                                    attrs: { currency: "RUB", locale: "ru" },
                                    model: {
                                      value: _vm.specs[index].price,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.specs[index], "price", $$v)
                                      },
                                      expression: "specs[index].price",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.withPrice
                            ? _c(
                                "td",
                                {
                                  staticClass: "align-text-top",
                                  staticStyle: { "text-align": "right" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatPrice(
                                          Math.round(
                                            _vm.specs[index].price *
                                              _vm.specs[index].count *
                                              100
                                          ) / 100
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "td",
                            {
                              staticClass: "align-text-top",
                              attrs: { nowrap: "" },
                            },
                            [
                              !_vm.noDeleteSpec || _vm.isDeliverySpec(item)
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          background: "transparent",
                                        },
                                        attrs: { variant: "light", size: "sm" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeSpec(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/images/trash_icon.svg",
                                            alt: "trash_icon",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ])
                      }),
                      _vm.specs.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              {
                                attrs: {
                                  colspan: _vm.withPrice ? 10 : 8,
                                  align: "center",
                                },
                              },
                              [_vm._v("Не добавлено позиций спецификации")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm.specErrors
              ? _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.specErrorMessage)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm.withPrice
        ? _c(
            "b-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "w-100",
                  attrs: {
                    label: "Общая стоимость, руб.",
                    "label-cols-sm": "4",
                    "label-cols-lg": "3",
                    "label-class":
                      "fs-14 grey-color fw-700 d-flex align-items-baseline",
                  },
                },
                [
                  _c("currency-input", {
                    directives: [
                      {
                        name: "stop-number-mousewheel",
                        rawName: "v-stop-number-mousewheel",
                      },
                    ],
                    staticClass: "form-control fs-14",
                    class: !_vm.specErrors ? "is-valid" : "is-invalid",
                    attrs: { currency: "RUB", locale: "ru", disabled: "" },
                    model: {
                      value: _vm.roundedSpecSum,
                      callback: function ($$v) {
                        _vm.roundedSpecSum = $$v
                      },
                      expression: "roundedSpecSum",
                    },
                  }),
                  _vm.specErrors
                    ? [
                        _c("b-form-invalid-feedback", [
                          _vm._v(_vm._s(_vm.specErrorMessage)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("purchase-specification-modal", {
        attrs: { show: _vm.showSpec },
        on: { cancel: _vm.cancelSpecModal, add: _vm.addSpec },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }