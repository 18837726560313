var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "atmo-content-table atmo-deliverables" }, [
      _vm._m(0),
      _vm._m(1),
      _c(
        "tbody",
        [
          _vm._l(this.$props.deliverables, function (deliverable, index) {
            return _c("fragment", { key: deliverable.display_id }, [
              _c("tr", { class: _vm.visible[index] ? "border-bottom-0" : "" }, [
                _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
                _c("td", [
                  deliverable.gsc_details && !deliverable.gsc_details.isEmpty()
                    ? _c(
                        "span",
                        [
                          _c("GSCPopover.view", {
                            attrs: { deliverable: deliverable },
                          }),
                        ],
                        1
                      )
                    : _c("span", [_vm._v("—")]),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle",
                            value: "collapse-" + index,
                            expression: "'collapse-' + index",
                          },
                        ],
                        staticClass: "atmo-button-icon",
                      },
                      [
                        !_vm.visible[index]
                          ? _c(
                              "span",
                              { attrs: { title: "Показать характеристики" } },
                              [_c("i", { staticClass: "atmo-icon-show" })]
                            )
                          : _c(
                              "span",
                              { attrs: { title: "Скрыть характеристики" } },
                              [_c("i", { staticClass: "atmo-icon-hide" })]
                            ),
                      ]
                    ),
                    _c("b-collapse", {
                      staticClass: "d-none",
                      attrs: { id: "collapse-" + index },
                      model: {
                        value: _vm.visible[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.visible, index, $$v)
                        },
                        expression: "visible[index]",
                      },
                    }),
                  ],
                  1
                ),
                _c("td", {
                  domProps: { textContent: _vm._s(deliverable.title) },
                }),
                _c("td", [
                  deliverable.okpd_code
                    ? _c("span", {
                        domProps: {
                          textContent: _vm._s(deliverable.okpd_code),
                        },
                      })
                    : _c("span", [_vm._v("—")]),
                ]),
                deliverable.isEditable
                  ? _c("td", { staticClass: "atmo-ctc-quantity" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "atmo-form__field atmo-inverse atmo-small",
                          class: {
                            "atmo-has-errors":
                              deliverable.validator.errors.quantity,
                          },
                        },
                        [
                          _c("formatted-input-number", {
                            attrs: { decimals: 5, padright: false },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (fim) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "atmo-component-wrapper",
                                        },
                                        [
                                          !fim.isInputFocused
                                            ? _c("button", {
                                                staticClass:
                                                  "atmo-button--small atmo-input",
                                                class: {
                                                  "atmo-invalid":
                                                    !fim.isInputValid,
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    fim.formatted
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return fim.focus.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                  focus: fim.onTriggerFocus,
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: fim.isInputFocused,
                                                  expression:
                                                    "fim.isInputFocused",
                                                },
                                              ],
                                              staticClass:
                                                "atmo-form__field atmo-inverse atmo-small",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value: deliverable.quantity,
                                                    expression:
                                                      "deliverable.quantity",
                                                    modifiers: { number: true },
                                                  },
                                                  {
                                                    name: "stop-number-mousewheel",
                                                    rawName:
                                                      "v-stop-number-mousewheel",
                                                  },
                                                ],
                                                attrs: {
                                                  required: "",
                                                  type: "number",
                                                  step: "0.00001",
                                                  min: "0.00001",
                                                },
                                                domProps: {
                                                  value: deliverable.quantity,
                                                },
                                                on: {
                                                  blur: [
                                                    fim.processBlurEvent,
                                                    function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  ],
                                                  keydown:
                                                    fim.processKeydownEvent,
                                                  input: [
                                                    function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        deliverable,
                                                        "quantity",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    fim.processInputEvent,
                                                  ],
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ])
                  : _c("td", {
                      staticClass: "atmo-ctc-quantity",
                      domProps: { textContent: _vm._s(deliverable.quantity) },
                    }),
                _c("td", {
                  staticClass: "atmo-ctc-okpd-symbolic-code",
                  domProps: {
                    textContent: _vm._s(deliverable.okei_symbolic_code),
                  },
                }),
                _c("td", { staticClass: "atmo-ctc-price-per-unit" }, [
                  _c(
                    "span",
                    {
                      staticClass: "atmo-form__field atmo-inverse atmo-small",
                      class: {
                        "atmo-has-errors":
                          deliverable.validator.errors.price_per_unit,
                      },
                    },
                    [
                      _c("currency-input", {
                        attrs: {
                          "allow-negative": false,
                          precision: 2,
                          currency: "RUB",
                          locale: "ru",
                          type: "text",
                          id: "purchase-startingPrice",
                          placeholder: "0",
                          autocomplete: "off",
                        },
                        model: {
                          value: deliverable.price_per_unit,
                          callback: function ($$v) {
                            _vm.$set(deliverable, "price_per_unit", $$v)
                          },
                          expression: "deliverable.price_per_unit",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("td", { staticClass: "atmo-ctc-price-total" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatnumber")(deliverable.price_total))
                  ),
                ]),
                _c("td", [
                  !deliverable.isDuplicate
                    ? _c(
                        "button",
                        {
                          staticClass: "atmo-button-icon",
                          attrs: {
                            disabled:
                              deliverable.is_delivery &&
                              _vm.$props.controller.has_delivery_duplicate,
                            title: "Дублировать спецификацию",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$props.controller.insertDeliverable(
                                deliverable
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-duplicate" })]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "atmo-button-icon atmo-dimmed",
                          attrs: { title: "Удалить" },
                          on: {
                            click: function ($event) {
                              return _vm.$props.controller.removeDeliverableDuplicate(
                                deliverable
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "atmo-icon-trash" })]
                      ),
                ]),
              ]),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible[index],
                      expression: "visible[index]",
                    },
                  ],
                },
                [
                  _c(
                    "td",
                    { attrs: { colspan: "10" } },
                    [
                      _c("DeliverableDetails.view", {
                        attrs: {
                          requirements: deliverable.requirements,
                          description: deliverable.gsc_details
                            ? deliverable.gsc_details.description
                            : null,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          }),
          this.isempty
            ? _c("tr", [
                _c("td", { attrs: { colspan: "8" } }, [
                  _vm._v("Не добавлено спецификаций"),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("caption", [_c("h5", [_vm._v("Измените спецификациию")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Рег.номер ТРУ")]),
        _c("th", [
          _c("abbr", { attrs: { title: "Характеристики ТРУ" } }, [
            _vm._v("Хар-ки ТРУ"),
          ]),
        ]),
        _c("th", [_vm._v("Наименование ТРУ")]),
        _c("th", [_vm._v("Код ОКПД2")]),
        _c("th", { staticClass: "atmo-ctc-quantity" }, [_vm._v("Количество")]),
        _c("th", { staticClass: "atmo-ctc-okpd-symbolic-code" }, [
          _vm._v("Ед.изм"),
        ]),
        _c("th", { staticClass: "atmo-ctc-price-per-unit" }, [
          _vm._v("Цена за ед., руб."),
        ]),
        _c("th", { staticClass: "atmo-ctc-price-total" }, [
          _vm._v("Стоимость, руб."),
        ]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }