var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("entity-list-page-controller", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (elpc) {
          return [
            _c(
              "div",
              { staticClass: "atmo-page-entities-list" },
              [
                _c("section", { staticClass: "atmo-page-banner-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "atmo-container-fixed" },
                    [
                      _c("h2", [
                        _vm._v("Реестр договоров "),
                        elpc.group === "fl44"
                          ? _c("span", [_vm._v("44-ФЗ")])
                          : _vm._e(),
                        elpc.group === "fl223"
                          ? _c("span", [_vm._v("223-ФЗ")])
                          : _vm._e(),
                      ]),
                      _c("ContractFiltersView", { attrs: { elpc: elpc } }),
                    ],
                    1
                  ),
                ]),
                _c("EntityStatusMenuControllerView", {
                  attrs: {
                    elpc: elpc,
                    route: elpc.storeprefix,
                    statuses: elpc.dictionaries.statusesmenu,
                    currentstatus: elpc.currentstatus,
                  },
                  on: { "status:update": elpc.processRequestUpdate },
                }),
                _c("StandardPaginationView", {
                  attrs: {
                    pagination: elpc.pagination,
                    pageupdatehandler: elpc.processRequestUpdate,
                  },
                }),
                _c(
                  "ul",
                  { staticClass: "atmo-container-fixed" },
                  [
                    _vm._l(elpc.list, function (contractitem) {
                      return _c(
                        "li",
                        {
                          key: contractitem.id,
                          staticClass: "atmo-entity-item",
                        },
                        [
                          _c("contract-item", {
                            attrs: { elpc: elpc, contractitem: contractitem },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ci) {
                                    return [
                                      _c("ContractItemView", {
                                        attrs: { ci: ci },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      )
                    }),
                    elpc.isListEmpty
                      ? _c("li", [
                          _c("figure", { staticClass: "atmo-notfound-image" }, [
                            _c("img", {
                              attrs: {
                                src: "/images/common/other/empty-box.svg",
                                alt: "Список пуст",
                              },
                            }),
                            _c("figcaption", [
                              _vm._v("По вашему запросу ничего не найдено"),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _c("StandardPaginationView", {
                  attrs: {
                    pagination: elpc.pagination,
                    pageupdatehandler: elpc.processRequestUpdate,
                  },
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }