'use strict';

import isEmpty from 'lodash.isempty';
import { Component, Prop, Provide, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import DownloadConfigFactory, { Strategy } from '@core/js/ddriven/domain/services/downloads/DownloadConfigFactory';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import IOnOffOnToggleEventPayload, { IOnOffToggleEvent } from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import X509SignatureVO from '@core/js/ddriven/domain/model/common/X509Signature.valueobject';
import AnnexItemVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItem.valueobject';
import { AllowedConfigTypes as DownloadAllowedConfigTypes, ConfigFactorySettings as DownloadConfigFactorySettings } from '@core/js/ddriven/domain/services/downloads/DownloadConfig.valueobject';
import { AnnexStrategyPayload } from '@core/js/ddriven/domain/services/downloads/strategies/AnnexStrategy.valueobject';
import PurchaseItemDetailsVO from '@core/js/ddriven/domain/model/purchases/view/item/PurchaseItemDetails.valueobject';
import ContractItemDetailsVO from '@core/js/ddriven/domain/model/contracts/ContractItemDetails.valueobject';
import SignContractPopupController from '@core/js/viewmodels/purchases/item/view/contract/sign/SignContractPopupController.viewmodel';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import { Command, IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import StoreContractRequestVO from '@core/js/ddriven/application/http/requests/contracts/StoreContractRequest.valueobject';
import SignContractAnnexPopupController, { ISignContractAnnexPopupPayload } from './widgets/contract/annexes/sign/SignContractAnnexPopupController.viewmodel';
import ProposalItemVO from '@core/js/ddriven/domain/model/purchases/view/item/ProposalItem.valueobject';
import DeclinePoposalPopupController, { IData as DPPCIData } from './widgets/protocol/DeclinePoposalPopupController';
import DeclineProposalRequest from '@core/js/ddriven/application/http/requests/purchases/DeclineProposalRequest.valueobject';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import OrganizationVO from '@core/js/ddriven/domain/model/users/Organization.valueobject';
import actWithLoadingSpinner from '@lib/js/src/misc/actWithLoadingSpinner';
import { PurchaseStatuses } from '@core/js/ddriven/domain/model/common/PurchaseStatuses.enum';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import ATMOAPIResponseNormalizedVO from '@core/js/ddriven/ports/adapters/http/outgoing/atmo/ATMOAPIResponseNormalized.valueobject';
import Config from '@core/js/ddriven/application/config/Config';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';
import SupplierRequirementsVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierRequirements.valueobject';

interface IData {
    supplier_accounting: SupplierAccountingVO;
    customer_contract_number: string | null;
    attachments: {
        contract: FileAttachmentVO | null;
    };
}

interface ISignContractPopupPayload extends IOnOffOnToggleEventPayload {
    data: {
        user: UserVO;
        purchaseitem: PurchaseItemDetailsVO;
        downloadFileAttachment: Function;
        supplier_accounting: SupplierAccountingVO;
        customer_contract_number: string | null;
    };
}

type TDeclinePopupResponse = boolean | IDeclineProposalPopupData;

interface IDeclineProposalPopupData {
    inputs: DPPCIData['inputs'];
    declinereasons: StandardDictionaryItem[];
}

@Component
export default class PurchaseItemViewController extends BaseViewModel {
    private static HASH_JUMP_POINT = 255;
    private static proposalDeclinedEventId = 'proposal:declined';

    constructor() {
        super();
        this.name = 'PurchaseItemViewController';
    }

    @Provide() pivc = () => {
        return this;
    };

    @Prop({ required: true, type: String }) readonly group!: string;
    @Prop({ required: true }) readonly id!: string;

    created() {
        this.$root.$emit('public:onoff:toggle', { id: 'global-waiting-spinner', ison: true });
    }

    data(): IData {
        return {
            supplier_accounting: new SupplierAccountingVO(),
            customer_contract_number: null,
            attachments: {
                contract: null
            }
        };
    }

    /**
     * Computed
     */
    get features(): Record<string, boolean> | boolean {
        return Config.get('theme.features');
    }

    get user(): UserVO {
        return this.$store.getters[`rearchitected/users/user`];
    }

    get purchaseitem(): PurchaseItemDetailsVO {
        return this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/item`];
    }

    get pricebreakdown() {
        return this.getPriceBreakdown();
    }

    get status() {
        const statuses = this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/statuses`];
        const found = statuses.find((status: ExtendedDictionaryItem) => {
            // NB: Select an artificial purchase status non-existent on backend. Need a backend refactor to introduce the status.
            return this.purchaseitem.is_summarizing ? status.id === 99 : status.id === this.purchaseitem.status_id;
        });
        return found ?? {};
    }

    get acceptedProposal(): ProposalItemVO {
        return this.purchaseitem.proposals[0] ?? null;
    }

    get contractAttachments() {
        return {
            main: this.hasContract ? (this.purchaseitem.contract as ContractItemDetailsVO).attachments.main : [],
            confirmation: this.hasContract ? (this.purchaseitem.contract as ContractItemDetailsVO).attachments.confirmation : []
        };
    }

    get contractStatuses(): ExtendedDictionaryItem[] {
        return this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/contractstatuses`].filter((status: ExtendedDictionaryItem) => {
            return status.code !== 'contractfailed';
        });
    }

    get pendingAnnex(): AnnexItemVO | null {
        return this.hasContract ? (this.purchaseitem.contract as ContractItemDetailsVO).pendingAnnex() : null;
    }

    get isSupplier(): boolean {
        return !!this.user?.is_supplier;
    }

    get isUserEngagedSupplier(): boolean {
        return this.user && this.purchaseitem.supplier.id === this.user.organization.id;
    }

    get isUserEngagedCustomer(): boolean {
        return this.user && this.purchaseitem.customer.id === this.user.organization.id;
    }

    get isPurchaseAcceptingProposals(): boolean {
        return this.purchaseitem.status_id === PurchaseStatuses.Accepting;
    }

    get isPurchaseSummarized(): boolean {
        return this.purchaseitem.isPurchaseSummarized;
    }

    get isPurchaseSummarizing(): boolean {
        return this.purchaseitem.is_summarizing;
    }

    get isPurchaseOutATMO(): boolean {
        return this.purchaseitem.is_out_atmo;
    }

    get hasPurchaseProtocol(): boolean {
        return this.purchaseitem.hasProtocol;
    }

    get hasPoroposals(): boolean {
        return this.purchaseitem.proposals.length > 0;
    }

    get hasSupplier(): boolean {
        return this.purchaseitem.supplier.id !== null;
    }

    get hasContract(): boolean {
        return !isEmpty(this.purchaseitem.contract);
    }

    get hasDisputes(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).hasDisputes();
    }

    get isContractSignedByBoth(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).isSignedByBoth();
    }

    get isContractSignedByCustomer(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).isSignedByCustomer();
    }

    get isContractSignedBySupplier(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).isSignedBySupplier();
    }

    get isContractProposed(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).isProposed();
    }

    get isContractDisputed(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).isDisputed();
    }

    get hasContractAnnexes(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).annexes.length > 0;
    }

    get hasContractPendingAnnexes(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).hasPendingAnnexes();
    }

    get isProposalsInfoHidden(): boolean {
        return this.purchaseitem.is_proposals_info_hidden;
    }

    get canSupplierSubmitDisputes(): boolean {
        return this.hasContract && (this.purchaseitem.contract as ContractItemDetailsVO).can_provider_send_dispute;
    }

    get mustSupplierSignContract(): boolean {
        return this.isPurchaseSummarized && this.isUserEngagedSupplier && !this.isContractSignedBySupplier && (this.purchaseitem.contract as ContractItemDetailsVO).acceptance_status_id === 1;
    }

    get mustCustomerignContract(): boolean {
        return this.isPurchaseSummarized && this.isUserEngagedCustomer && !this.isContractSignedByCustomer && this.isContractSignedBySupplier && (this.purchaseitem.contract as ContractItemDetailsVO).acceptance_status_id === 2;
    }

    get gotContractAttached(): boolean {
        return this.$data.attachments.contract !== null;
    }

    get isContractActive(): boolean {
        return this.hasContract && (this.purchaseitem as PurchaseItemDetailsVO).isContractActive;
    }

    get hasContractErrors(): boolean {
        return this.hasContract && this.isUserEngagedCustomer && (((this.purchaseitem.contract as ContractItemDetailsVO).has_limits_errors && !this.purchaseitem.without_limits) || (this.$data.supplier_accounting.hasErrors() && !this.purchaseitem.without_limits));
    }

    get areContractLimitsEditable(): boolean {
        /**
         * У контракта:
         * 1) нет ни одного ДС;
         * 2) есть только одно ДС и оно не подписано заказчиком;
         */
        return !this.hasContractAnnexes || (this.hasContractPendingAnnexes && (this.purchaseitem.contract as ContractItemDetailsVO).annexes.length === 1 && !(this.purchaseitem.contract as ContractItemDetailsVO).annexes[0].isSignedBySupplier());
    }

    /**
     * Watch
     */
    @Watch('purchaseitem')
    onPurchaseitemChanged(newValue: PurchaseItemDetailsVO, oldValue: PurchaseItemDetailsVO | null): void {
        this.$root.$emit('public:onoff:toggle', { id: 'global-waiting-spinner', ison: false });
        this.$data.supplier_accounting = (newValue.contract as ContractItemDetailsVO).supplier_accounting;
        this.scrollToHash(newValue, oldValue);
    }

    /**
     * Methods
     */
    public async downloadPurchaseInfo() {
        const config = DownloadConfigFactory.fromPurchaseItemDetails(this.purchaseitem.id as number, this.purchaseitem.registration_number as string);
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    public async downloadProtocol() {
        const config = DownloadConfigFactory.forFinalProtocol(this.purchaseitem.id as number, this.purchaseitem.registration_number as string);
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    public async downloadContract() {
        const config = DownloadConfigFactory.forContract(this.purchaseitem.id as number, this.purchaseitem.registration_number as string);
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    public async downloadOrganizationReport(supplier: OrganizationVO, evt: Event) {
        const config = DownloadConfigFactory.forOrganizationReport(supplier);
        this.download(config, evt.target as HTMLElement);
    }

    public downloadFileAttachment(attachment: FileAttachmentVO, evt?: Event) {
        const config = DownloadConfigFactory.fromFileAttachment(attachment);
        this.download(config, evt?.target as HTMLElement);
    }

    public downloadAnnex(annex: AnnexItemVO, num: number) {
        const settings: DownloadConfigFactorySettings = {
            type: DownloadAllowedConfigTypes.annex,
            payload: {
                contract_id: (this.purchaseitem.contract as ContractItemDetailsVO).id,
                annex_id: annex.id,
                purchase_registration_number: this.purchaseitem.registration_number,
                annex_number: num
            } as AnnexStrategyPayload
        };
        const config = DownloadConfigFactory.forAnyDownload(settings);
        ApplicationServiceLocator.get('download').fileByBrowser(config);
    }

    public submitOrUpdateProposal() {
        ApplicationServiceLocator.get('supplier').checkBeforeProposal(this.purchaseitem.id!);
    }

    public invokeSignaturePopup(signatures: X509SignatureVO[]) {
        this.$root.$emit('public:onoff:toggle', { id: 'signature-popup', content: signatures } as IOnOffToggleEvent);
    }

    public supplierAccountingUpdated(supplierAccounting: SupplierAccountingVO) {
        this.$data.supplier_accounting = supplierAccounting;
    }

    public invokeSignContractPopup(): void {
        const payload = this.prepareSignConractPopupPayload();
        this.$root.$emit('public:onoff:toggle', payload);
    }

    public invokeSignContractAnnexPopup(): void {
        const payload = this.prepareSignConractAnnexPopupPayload();
        this.$root.$emit('public:onoff:toggle', payload);
    }

    public async invokeProtocolSummarizationConfirmationPopup() {
        const isConfirmed = await new Promise((resolve) => {
            this.$root.$emit('public:onoff:toggle', { id: 'protocol-summarization-confirmation-popup', ison: true, data: { respond: resolve } } as IOnOffOnToggleEventPayload);
        });
        if (!isConfirmed) {
            return;
        }

        const response = await ApplicationServiceLocator.get('api').entities.summarizePurchaseProtocol(this.purchaseitem.id!);
        response.isSuccess &&
            setTimeout(() => {
                window.location.reload();
            }, 500);
    }

    public async invokeDeclineProposalPopup(proposal: ProposalItemVO, purchaseSupplierRequirements: SupplierRequirementsVO, evt: Event) {
        const response: IDeclineProposalPopupData = await new Promise((resolve) => {
            this.$root.$emit('public:onoff:toggle', {
                id: DeclinePoposalPopupController.popupId,
                ison: true,
                data: { respond: resolve, content: { proposal: proposal, purchaseSupplierRequirements: purchaseSupplierRequirements } }
            } as IOnOffOnToggleEventPayload);
        });
        if (!response) {
            return;
        }

        const apiResponse: ATMOAPIResponseNormalizedVO = await actWithLoadingSpinner(evt, async () => {
            return await ApplicationServiceLocator.get('api').entities.declineProposal(proposal.id as number, new DeclineProposalRequest(response.inputs as DPPCIData['inputs']));
        });

        if (!apiResponse.isSuccess) {
            return;
        }

        proposal.decline(response.inputs.declinereasonid as number, response.declinereasons);
        this.$root.$emit(PurchaseItemViewController.proposalDeclinedEventId);
    }

    public handleContractAttachment(event: IFileAttachmentVMEvent): void {
        this.$data.attachments.contract = event.command === Command.Add ? event.attachment : null;
    }

    public async storeContract() {
        const request = new StoreContractRequestVO(this.purchaseitem.id as number, this.purchaseitem.supplier.id as number, this.$data.attachments.contract);

        const response = await ApplicationServiceLocator.get('api').entities.storePurchaseContract(request);
        response.isSuccess &&
            setTimeout(() => {
                window.location.reload();
            }, 500);
    }

    /**
     * Prototype methods
     */
    /**
     * Scroll to URL hash if any.
     */
    private scrollToHash(newValue: PurchaseItemDetailsVO, oldValue: PurchaseItemDetailsVO | null): void {
        if (oldValue === null || !newValue.id) {
            return;
        }
        this.$nextTick(() => {
            let el: HTMLElement | null;
            if (this.$route.hash && (el = document.querySelector(this.$route.hash))) {
                const y = el.getBoundingClientRect().top + window.pageYOffset - PurchaseItemViewController.HASH_JUMP_POINT;
                window.scrollTo({ top: y });
            }
        });
    }

    private async download(config: Strategy, clickedElement?: HTMLElement) {
        clickedElement && clickedElement.setAttribute('disabled', '');
        await ApplicationServiceLocator.get('download').fileByBrowser(config);
        clickedElement && clickedElement.removeAttribute('disabled');
    }

    private getPriceBreakdown() {
        if (this.purchaseitem.proposals.length < 1) {
            return {};
        }
        const finalProposal = this.purchaseitem.proposals[0];
        const startingPrice = this.purchaseitem.starting_price;
        const finalPrice = finalProposal.total_price;
        const discount = startingPrice! - finalPrice;
        return {
            starting_price: startingPrice,
            final_price: finalPrice,
            discount: discount,
            discount_percent: (discount / startingPrice!) * 100
        };
    }

    private prepareSignConractPopupPayload(): ISignContractPopupPayload {
        const payload: ISignContractPopupPayload = {
            id: SignContractPopupController.popupId,
            ison: true,
            data: {
                user: this.user,
                purchaseitem: this.purchaseitem,
                supplier_accounting: this.$data.supplier_accounting,
                customer_contract_number: this.$data.customer_contract_number,
                downloadFileAttachment: this.downloadFileAttachment
            }
        };
        return payload;
    }

    private prepareSignConractAnnexPopupPayload(): ISignContractAnnexPopupPayload {
        const annex = (this.purchaseitem.contract as ContractItemDetailsVO).annexes.find((annex: AnnexItemVO) => {
            return annex.status_id !== 3;
        });
        return {
            id: SignContractAnnexPopupController.popupId,
            ison: true,
            data: {
                user: this.user,
                customer: this.purchaseitem.customer,
                supplier: this.purchaseitem.supplier,
                annex: annex!,
                contractId: (this.purchaseitem.contract as ContractItemDetailsVO).id,
                downloadFileAttachment: this.downloadFileAttachment
            }
        };
    }
}
