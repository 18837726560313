<template>
    <div class="atmo-component-wrapper">
        <ul v-bind:class="ci.getStatusClass('atmo-entity-status-')" class="atmo-entity-item-title atmo-icon-custom">
            <li>
                <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view`" target="_blank">
                    <span v-if="ci.contractitem.purchase_is_outside_atmo">Внесистемная закупка №&nbsp;</span>
                    <span v-else>Договор №&nbsp;</span>
                    <span v-text="ci.contractitem.purchase_registration_number"></span>
                    <template v-if="ci.contractitem.purchase_object">&nbsp;На закупку "{{ ci.contractitem.purchase_object }}"</template>
                </a>
            </li>
            <li><span v-text="ci.status.title" class="text-uppercase"></span></li>
        </ul>

        <!-- NB: Keep it here for ready-made markup -->
        <!-- <ul class="atmo-entity-item-summary">
            <li><dt>НМЦК:</dt> <dd><span>{{ ci.contractitem.purchase_starting_price | formatnumber  }}</span>&nbsp;руб.</dd></li>
            <li v-if="ci.hasAllSummaryItemsVisible"><dt>Предложений:</dt> <dd><span v-text="ci.contractitem.purchase_proposals_count"></span></dd></li>
            <li v-if="ci.hasAllSummaryItemsVisible">
                <dt>Лучшее:&nbsp;</dt>
                <dd v-if="!ci.contractitem.is_best_proposal_hidden">
                    <span v-if="ci.contractitem.best_proposal_price > 0">{{ ci.contractitem.best_proposal_price | formatnumber }}&nbsp;руб.</span>
                    <span v-else>-</span>
                </dd>
                <dd v-else>Скрыто</dd>
            </li>
            <li v-if="!ci.contractitem.is_best_proposal_hidden && ci.hasAllSummaryItemsVisible">
                <dt>Снижение:</dt>
                <dd v-if="ci.contractitem.best_proposal_discount_percent > 0"><span v-text="ci.contractitem.best_proposal_discount_percent"></span>%</dd>
                <dd v-else>0</dd>
            </li>
        </ul>  -->

        <ul class="atmo-entity-item-details">
            <li class="atmo-column atmo-special-style">
                <ul>
                    <!-- <li v-if="ci.status.code === 'accepting'">
                        <section>
                            <span v-if="ci.countdown"><dt>До окончания приема предложений:</dt><dd v-text="ci.countdown"></dd></span>
                            <dt v-if="ci.countdown===false">Прием предложений завершен</dt>
                        </section>
                        <section v-if="ci.countdown && ci.user && ci.user.is_logged_in && ci.user.is_supplier">
                            <button v-if="ci.contractitem.has_proposal_from_loggedin_organization" v-on:click.stop="ci.updateProposal" class="atmo-button--small">Изменить предложение</button>
                            <button v-else v-on:click.stop="ci.submitProposal" class="atmo-button--small">Подать предложение</button>
                        </section>
                    </li>
                    <li v-if="ci.status.code === 'summarizing'">Прием предложений завершен</li>
                    <li v-if="ci.status.code === 'summarized'">
                        <dt>Подведены итоги:</dt><dd>{{ ci.contractitem.results_protocol_date | formatdate('removeSeconds') }}</dd>
                    </li> -->

                    <li v-if="ci.status.code !== 'omitted'">
                        <dt>Договор заключен:</dt>
                        <dd>{{ ci.contractitem.signature_date | formatdate('onlyDate') }}</dd>
                        <span v-if="ci.contractitem.purchase_is_outside_atmo && ci.contractitem.planned_completion_date">
                            <dt>Дата исполнения договора:</dt>
                            <dd>{{ ci.contractitem.planned_completion_date | formatdate('onlyDate') }}</dd>
                        </span>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li>
                        <dt>Предмет закупки:</dt>
                        <dd v-text="ci.contractitem.purchase_description"></dd>
                    </li>
                    <li>
                        <dt>Организация:</dt>
                        <dd><a v-bind:href="'/clients/customers/' + ci.contractitem.customer_organization_id" v-text="ci.contractitem.customer_organization_title"></a></dd>
                    </li>
                    <li v-if="ci.hasSupplier">
                        <dt>Поставщик:</dt>
                        <dd><a v-bind:href="'/clients/providers/' + ci.contractitem.supplier_organization_id" v-text="ci.contractitem.supplier_organization_title"></a></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column">
                <ul>
                    <li v-if="ci.hasCompletionDate">
                        <dt>Планируемая дата исполнения:</dt>
                        <dd>{{ ci.contractitem.planned_completion_date | formatdate('onlyDate') }}</dd>
                    </li>
                    <li>Количество позиций: <b v-text="ci.contractitem.purchase_items_count"></b></li>
                    <li>
                        <abbr title="Начальная максимальная цена контракта">НМЦК</abbr>: <b>{{ ci.contractitem.purchase_starting_price | formatnumber }} руб.</b>
                    </li>
                    <li v-if="ci.hasDeliveryAddresses">
                        <dt>Место доставки или указание на самовывоз:</dt>
                        <dd v-for="(addressobj, index) in ci.contractitem.delivery_addresses" v-text="addressobj.address" v-bind:key="index"></dd>
                    </li>
                </ul>
            </li>
            <li class="atmo-column atmo-special-style">
                <ul>
                    <li>
                        <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view`" target="_blank"
                            ><i class="atmo-icon-search"></i><span>Информация о закупке</span></a
                        >
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-proposals`" target="_blank"><i class="atmo-icon-dynamic"></i><span>Динамика предложений</span></a>
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Итоговый протокол</span></a>
                    </li>
                    <li v-if="ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/external_purchases/${ci.contractitem.purchase_id}#supplier-info`" target="_blank"><i class="atmo-icon-shield"></i><span>Поставщик</span></a>
                    </li>
                    <li>
                        <a v-bind:href="ci.contractitem.purchase_is_outside_atmo ? `/external_purchases/${ci.contractitem.purchase_id}#contract` : `/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-contract`" target="_blank"
                            ><i class="atmo-icon-contract"></i><span>Договор</span></a
                        >
                    </li>
                    <li v-if="!ci.contractitem.purchase_is_outside_atmo">
                        <a v-bind:href="`/purchases/grouped/${ci.elpc.group}/item/${ci.contractitem.purchase_id}/view#atmo-contract-fulfillment`" target="_blank"><i class="atmo-icon-fulfilled"></i><span>Исполнение договора</span></a>
                    </li>
                    <!-- <li><DealStageActionButton v-bind:buttondata="ci.buttondata"></DealStageActionButton></li> -->
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
// import DealStageActionButton from './DealStageActionButton.view.vue';

export default {
    props: {
        ci: { required: true, type: Object }
    },
    components: {
        // DealStageActionButton
    }
};
</script>
