var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            rounded: "",
            opacity: "0.6",
            "spinner-variant": "primary",
          },
        },
        [
          !_vm.isSended
            ? _c(
                "b-container",
                { staticClass: "w-50" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        label: "Сброс пароля",
                        "label-class": "fs-18 grey-color",
                      },
                    },
                    [
                      _c("form-row-edit-text", {
                        staticClass: "mb-0",
                        attrs: {
                          label: "Логин",
                          v: _vm.$v.username,
                          "error-text": _vm.loginErrorText,
                        },
                        on: { input: _vm.onInput },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-container",
                { staticClass: "grey-color fs-20 fw-600 text-center my-5" },
                [
                  _c("p", [
                    _vm._v(
                      "На Ваш Email отправлено письмо с инструкциями по сбросу пароля."
                    ),
                  ]),
                ]
              ),
          _c("footer", { staticClass: "modal-footer pb-0 px-0" }, [
            _c(
              "div",
              { staticClass: "w-100 text-left" },
              [
                !_vm.isSended
                  ? _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase mr-2",
                        attrs: { variant: "custom-outline-secondary" },
                        on: { click: _vm.onClose },
                      },
                      [_vm._v("Отмена")]
                    )
                  : _vm._e(),
                !_vm.isSended
                  ? _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase",
                        attrs: {
                          disabled: !_vm.state,
                          variant: "custom-green",
                        },
                        on: { click: _vm.sendRequest },
                      },
                      [_vm._v("Продолжить")]
                    )
                  : _vm._e(),
                _vm.isSended
                  ? _c(
                      "b-button",
                      {
                        staticClass: "text-uppercase mr-2",
                        attrs: { variant: "custom-outline-secondary" },
                        on: { click: _vm.onDone },
                      },
                      [_vm._v("Закрыть")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }