<template>
    <purchase-item-update-controller v-slot:default="piuc" v-bind:group="this.$props.group" v-bind:id="this.$props.id">
        <div class="atmo-page">
            <!-- <section class="atmo-fast-access-menu">
                <ul class="atmo-container-fixed">
                    <li><a href="#atmo-supply-terms">Условия поставки</a></li>
                    <li><a href="#atmo-deliverables">Спецификации</a></li>
                </ul>
            </section> -->

            <section class="atmo-fast-access-menu">
                <ul class="atmo-container-fixed">
                    <li>
                        <a v-on:click="piuc.setTab('terms')" v-bind:class="{ 'atmo-active': piuc.visibleTab === 'terms' }">Условия закупки и поставки</a>
                        <span v-if="piuc.tabbedErrorCounts.terms" v-text="piuc.tabbedErrorCounts.terms" class="atmo-badge" title="Количество ошибочных полей"></span>
                    </li>
                    <li>
                        <a v-on:click="piuc.setTab('deliverables')" v-bind:class="{ 'atmo-active': piuc.visibleTab === 'deliverables' }">Спецификация и документы</a>
                        <span v-if="piuc.tabbedErrorCounts.deliverables" v-text="piuc.tabbedErrorCounts.deliverables" class="atmo-badge" title="Количество ошибочных полей"></span>
                    </li>
                </ul>
            </section>

            <header>
                <InfoBanner.view v-bind:user="piuc.user" />
            </header>

            <main class="atmo-container-fixed atmo-page-content">
                <div class="atmo-buttons">
                    <a href="https://app.hinted.me/simulations/7a63d719-ee38-4b27-9c11-92d26293d354/view" rel="noopener" target="_blank" class="atmo-button atmo-accented"><i class="atmo-icon-play"></i>&nbsp;Инструкция по созданию извещения</a>
                </div>

                <section v-show="piuc.visibleTab === 'terms'" class="atmo-tab">
                    <CustomerInfo.view v-bind:user="piuc.user" v-bind:inputs.sync="piuc.inputs" />

                    <PurchaseTerms.view
                        v-bind:controller="piuc"
                        v-bind:inputs.sync="piuc.inputs"
                        v-bind:errors="piuc.errors"
                        v-bind:dictionaries="piuc.dictionaries"
                        v-bind:deliverablesgroupsselecthandler="piuc.deliverablesGroupsSelectHandler"
                        v-bind:deliverablesgroupsremovehandler="piuc.deliverablesGroupsRemoveHandler" />

                    <SupplierRequirements.view v-bind:inputs.sync="piuc.inputs" />

                    <SupplyTerms.view v-bind:inputs.sync="piuc.inputs" v-bind:errors="piuc.errors" v-bind:addhandler="piuc.addToAddressesList" v-bind:removehandler="piuc.removeFromAddressesList" v-bind:useraddressbook="piuc.dictionaries.useraddressbook" />

                    <ul class="atmo-buttons">
                        <li><router-link v-bind:to="{ name: 'CustomerMainCabinet' }" class="atmo-button-inverse atmo-dimmed">Отменить</router-link></li>
                        <li><button v-on:click="piuc.setTab('deliverables')" class="atmo-button">Далее</button></li>
                        <li><button v-on:click="piuc.saveDraft" class="atmo-button atmo-accented">Сохранить черновик</button></li>
                    </ul>
                </section>

                <section v-show="piuc.visibleTab === 'deliverables'" class="atmo-tab">
                    <!-- // REFACTOR: The motivation to wrap a controller around a view instead of putting it into a view
                        is not remembered now. Probably that came from times when the deliverables list was only viewable.
                        Then deliverables editable list was introduced that required a controller.
                        At the same time I decided to keep the view inside controller for better in the future.
                        It looks like the flexibility still is not required.
                        Therefore it seems better to refactor this block to wrap the controller into the view. -->
                    <deliverables-editable-list-controller v-slot:default="dsc" v-bind:initiallist="piuc.inputs.specification.deliverables">
                        <DeliverablesEditableList.view v-bind:controller="dsc" v-bind:deliverables="dsc.list">
                            <template v-slot:errors>
                                <p v-if="dsc.islistempty" class="atmo-error">Заполните спецификацию</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_quantity_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_price_per_unit_empty" class="atmo-error">У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".</p>
                                <p v-if="!dsc.islistempty && dsc.errors.is_specification_total_price_invalid" class="atmo-error">Общая стоимость спецификации превышает 600,000 руб.</p>
                            </template>
                        </DeliverablesEditableList.view>
                    </deliverables-editable-list-controller>

                    <!-- // NB: See refactor note above. Here I implement a controller within view which went out quite good. -->
                    <!-- // NB: However in case of theming it can be appicable to wrap view into viewmodel so that view is a dependency, not a viewmodel. -->
                    <KBKLimitsEditableList.view
                        v-if="piuc.features.kbk_limits && !piuc.withoutLimits"
                        v-bind:initiallist="piuc.inputs.specification.kbkLimits"
                        v-bind:totalamount="piuc.inputs.specification.total_amount"
                        v-bind:limitfilterpresets="{ year: piuc.inputs.limit_year, purchase_type_id: piuc.inputs.type_id, purchase_id: piuc.inputs.id }">
                        <template v-slot:info>
                            <p>
                                <i class="atmo-warning atmo-icon-warning"></i>&nbsp;Внимание! Перед добавление лимитов проверьте правильность значений полей "Год ПГ" и "Тип закупки" в разделе "Условия закупки". При изменении любого из этих полей все добавленные в таблицу лимиты по КБК будут удалены.
                            </p>
                        </template>
                    </KBKLimitsEditableList.view>

                    <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:initiallist="piuc.inputs.contract_draft_attachment" v-bind:isrequired="true" v-bind:origin="'contract'" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="piuc.fileAttachmentEventHandler">
                        <EditableFileAttachmentsList.view v-bind:controller="efalc">
                            <h3>Проект ГК</h3>
                            <template v-slot:controls v-if="piuc.inputs.deliverable_group_id === 16">
                                <li>
                                    <template v-if="piuc.hasErrorsWithoutContractDraftAttachment">
                                        <button id="popover-contract-template" class="atmo-button">ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ</button>
                                        <b-popover :target="'popover-contract-template'" triggers="hover">
                                            <p class="fs-12 my-1 py-1">Для автоматического формирования проекта контракта необходимо заполнить все обязательные поля, за исключением документа в поле "Проект ГК".</p>
                                        </b-popover>
                                    </template>
                                    <template v-else-if="isContractTemplateFormVisible || !piuc.errors.contract_draft_attachment">
                                        <button id="popover-contract-template" class="atmo-button-inverse" disabled>ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ</button>
                                    </template>
                                    <template v-else>
                                        <button id="popover-contract-template" class="atmo-button" @click="showContractTemplateForm(piuc)">ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ</button>
                                    </template>
                                </li>
                            </template>
                        </EditableFileAttachmentsList.view>
                    </editable-file-attachments-list-controller>

                    <div class="atmo-content-group" v-if="isContractTemplateFormVisible">
                        <h3>Условия по контракту</h3>
                        <form-row-edit-text label="Контракт на поставку" v-model="contract_template_params.deliverable_name" :v="$v.contract_template_params.deliverable_name" />
                        <form-row-edit-text label="Должность и ФИО подписанта контракта (в родительном падеже)" v-model="contract_template_params.signer_position_and_name" :v="$v.contract_template_params.signer_position_and_name" />
                        <form-row-edit-text label="Действует на основании (в родительном падеже)" v-model="contract_template_params.signer_acts_on_basis" :v="$v.contract_template_params.signer_acts_on_basis" />
                        <form-row-edit-text label="Источник финансирования" v-model="contract_template_params.source_of_funding" :v="$v.contract_template_params.source_of_funding" />
                        <form-row-edit-number label="Количество дней на выплату аванса от даты заключения контракта" v-if="piuc.inputs.with_advance" v-model.number="contract_template_params.days_for_advance_payment" :v="$v.contract_template_params.days_for_advance_payment" />
                        <form-row-edit-date label="Максимальный срок поставки товаров" v-model="contract_template_params.max_delivery_date" :max-date="piuc.inputs.planned_contract_fulfillment_date" :v="$v.contract_template_params.max_delivery_date" />
                        <form-row-edit-text label="Адрес доставки товаров" v-model="contract_template_params.delivery_address" :v="$v.contract_template_params.delivery_address" />
                        <form-row-edit-checkbox :label-cols-lg="11" :label-cols-sm="11" label="Необходимо ли осуществить монтаж и наладку Товара в соответствии со Спецификацией" v-model="contract_template_params.installation_needed" :v="$v.contract_template_params.installation_needed" />
                        <form-row-edit-checkbox
                            :label-cols-lg="11"
                            :label-cols-sm="11"
                            label="Необходимо ли провести обучение лиц, осуществляющих использование и обслуживание Товара в соответствии со Спецификацией"
                            v-model="contract_template_params.training_needed"
                            :v="$v.contract_template_params.training_needed" />
                        <form-row-edit-number label="Количество дней, за которое поставщик должен уведомить о поставке товара" v-model.number="contract_template_params.delivery_notification_days" :v="$v.contract_template_params.delivery_notification_days" />
                        <form-row-edit-number
                            label="Количество дней, в течение которых стороны должны уведомить друг друга о наступлении обстоятельств непреодолимой силы"
                            v-model.number="contract_template_params.force_majeure_notification_days"
                            :v="$v.contract_template_params.force_majeure_notification_days" />
                        <form-row-edit-text label="Требования к гарантии качества Товара" v-model="contract_template_params.quality_assurance" :v="$v.contract_template_params.quality_assurance" />

                        <p>
                            <span>Укажите банковские реквизиты Вашей организации для заполнения шаблона договора.</span>
                        </p>
                        <b-form-row>
                            <form-row-edit-text-compact v-model="contract_template_params.customer_bank_name" :v="$v.contract_template_params.customer_bank_name" label="Наименование органа, в котором открыт лицевой счет" />
                            <form-row-edit-text-compact v-model="contract_template_params.customer_bank_bik" :v="$v.contract_template_params.customer_bank_bik" label="БИК банка" />
                            <form-row-edit-text-compact v-model="contract_template_params.customer_bank_rs" :v="$v.contract_template_params.customer_bank_rs" label="Банковские реквизиты счета" />
                            <form-row-edit-text-compact v-model="contract_template_params.customer_bank_ls" :v="$v.contract_template_params.customer_bank_ls" label="Лицевой счет" />
                        </b-form-row>

                        <ul class="atmo-buttons">
                            <li><button class="atmo-button" :disabled="$v.contract_template_params.$invalid" @click="saveContractTemplateForm(piuc)">Сохранить</button></li>
                            <li><button class="atmo-button-inverse atmo-dimmed" @click="clearContractTemplateForm(piuc)">Очистить</button></li>
                        </ul>
                    </div>

                    <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:initiallist="piuc.inputs.notice_supplementary_attachments" v-bind:origin="'notice'" v-on:fileattachment:changed="piuc.fileAttachmentEventHandler">
                        <EditableFileAttachmentsList.view v-bind:controller="efalc"><h3>Дополнительные документы</h3></EditableFileAttachmentsList.view>
                    </editable-file-attachments-list-controller>

                    <ul class="atmo-buttons">
                        <li><button v-on:click="piuc.setTab('terms')" class="atmo-button-inverse atmo-dimmed">Назад</button></li>
                        <li><button v-on:click="piuc.saveDraft" class="atmo-button atmo-accented">Сохранить черновик</button></li>
                        <li><button v-bind:disabled="piuc.hasErrors || !$store.getters.getCanSign" v-on:click="piuc.invokeSavePurchasePopup" class="atmo-button">Подписать и сохранить</button></li>
                        <span v-if="!$store.getters.getCanSign" class="text-initialcase text-danger pl-2 pt-1">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </ul>
                </section>
            </main>
        </div>
    </purchase-item-update-controller>
</template>

<script>
import PurchaseItemUpdateController from '@core/js/viewmodels/purchases/item/update/PurchaseItemUpdateController.viewmodel';
import DeliverablesEditableListController from '@core/js/viewmodels/purchases/item/update/deliverables/DeliverablesEditableListController.viewmodel';
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';

import InfoBannerView from './partials/InfoBanner.view.vue';
import CustomerInfoView from './partials/CustomerInfo.view.vue';
import PurchaseTermsView from './partials/PurchaseTerms.view.vue';
import SupplierRequirementsView from './partials/supplier-requirements/SupplierRequirements.view.vue';
import SupplyTermsView from './partials/SupplyTerms.view.vue';
import DeliverablesEditableListView from 'themes/views/pages/purchases/item/common/partials/deliverables/update/DeliverablesEditableList.view.vue';
import KBKLimitsEditableListView from 'themes/views/pages/purchases/item/common/partials/kbk-limits/update/KBKLimitsEditableList.view.vue';
import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';
import setFastAccessMenuTop from '@lib/js/src/misc/setFastAccessMenuTop.ts';
import FormRowEditNumber from '@/components/common/form-rows/form-row-edit-number.vue';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import FormRowEditTextCompact from '@/components/common/form-rows/form-row-edit-text-compact.vue';
import { cloneDeep } from 'lodash';

export default {
    metaInfo: {
        title: 'Создание закупки'
    },
    validations() {
        return {
            contract_template_params: {
                deliverable_name: { required },
                signer_position_and_name: { required },
                signer_acts_on_basis: { required },
                source_of_funding: { required },
                days_for_advance_payment: {},
                max_delivery_date: { required },
                delivery_address: { required },
                installation_needed: {},
                training_needed: {},
                delivery_notification_days: { required },
                force_majeure_notification_days: { required },
                quality_assurance: {},
                customer_bank_name: { required },
                customer_bank_bik: { required, minLength: minLength(9), maxLength: maxLength(9) },
                customer_bank_rs: { required, minLength: minLength(20), maxLength: maxLength(20) },
                customer_bank_ls: { required, minLength: minLength(20), maxLength: maxLength(20) }
            }
        };
    },
    components: {
        FormRowEditTextCompact,
        FormRowEditNumber,
        'purchase-item-update-controller': PurchaseItemUpdateController,
        'deliverables-editable-list-controller': DeliverablesEditableListController,
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,

        'InfoBanner.view': InfoBannerView,
        'CustomerInfo.view': CustomerInfoView,
        'PurchaseTerms.view': PurchaseTermsView,
        'SupplierRequirements.view': SupplierRequirementsView,
        'SupplyTerms.view': SupplyTermsView,
        'DeliverablesEditableList.view': DeliverablesEditableListView,
        'KBKLimitsEditableList.view': KBKLimitsEditableListView,
        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView
    },
    props: {
        group: { required: true, type: String },
        id: { default: '' }
    },
    data() {
        return {
            isContractTemplateFormVisible: false,
            contract_template_params: {
                deliverable_name: '',
                signer_position_and_name: '',
                signer_acts_on_basis: '',
                source_of_funding: '',
                days_for_advance_payment: 0,
                max_delivery_date: null,
                delivery_address: '',
                installation_needed: false,
                training_needed: false,
                delivery_notification_days: 0,
                force_majeure_notification_days: 0,
                quality_assurance: '',
                customer_bank_name: '',
                customer_bank_bik: '',
                customer_bank_rs: '',
                customer_bank_ls: ''
            }
        };
    },
    mounted() {
        setFastAccessMenuTop();
    },
    methods: {
        addFakeContractFile(piuc) {
            piuc.inputs.contract_draft_attachment = [
                {
                    file_id: '44444444-4444-4444-4444-444444444444',
                    name: '44444444.pdf',
                    attachment_record_id: null,
                    is_archived: false,
                    created_at: '2022-02-08T06:55:10.000000Z',
                    storage: 's3'
                }
            ];
        },
        async showContractTemplateForm(piuc) {
            await piuc.saveDraft();
            this.contract_template_params = cloneDeep(piuc.$data.inputs.contract_template_params);
            this.isContractTemplateFormVisible = true;
        },
        clearContractTemplateForm(piuc) {
            this.contract_template_params.signer_position_and_name = '';
            this.contract_template_params.signer_acts_on_basis = '';
            this.contract_template_params.source_of_funding = '';
            this.contract_template_params.days_for_advance_payment = 0;
            this.contract_template_params.max_delivery_date = null;
            this.contract_template_params.delivery_address = '';
            this.contract_template_params.installation_needed = false;
            this.contract_template_params.training_needed = false;
            this.contract_template_params.delivery_notification_days = 0;
            this.contract_template_params.foce_majeur_notification_days = 0;
            this.contract_template_params.quality_assurance = '';
            this.contract_template_params.customer_bank_name = '';
            this.contract_template_params.customer_bank_bik = '';
            this.contract_template_params.customer_bank_rs = '';
            this.contract_template_params.customer_bank_ls = '';

            piuc.$data.inputs.contract_template_params = cloneDeep(this.contract_template_params);
            piuc.$data.inputs.with_contract_template = false;

            this.isContractTemplateFormVisible = false;
        },
        async saveContractTemplateForm(piuc) {
            piuc.$data.inputs.contract_template_params = cloneDeep(this.contract_template_params);
            piuc.$data.inputs.with_contract_template = true;
            await piuc.saveDraft();
            const contractTemplate = await this.$api.purchases.getContractTemplate(this.$props.id);
            if (!contractTemplate) {
                return;
            }
            piuc.inputs.contract_draft_attachment = [
                {
                    file_id: contractTemplate.id,
                    name: contractTemplate.name
                }
            ];

            this.isContractTemplateFormVisible = false;
        }
    }
};
</script>

<style scoped>
.text-initialcase {
    text-transform: initial;
}
</style>
