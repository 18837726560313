<template>
    <div class="atmo-content-group">
        <h5>
            <slot></slot>
        </h5>

        <p>
            <span>Укажите банковские реквизиты поставщика для заключения договора, которые будут переданы в РИС после заключения.</span>
        </p>

        <b-form-row>
            <frc-select v-model="bank.bank_type" :options="bankTypes" :v="$v.bank.bank_type" label="Вид обслуживающей организации" @input="onInput" />
            <b-col :lg="6" :sm="12">
                <b-form-group :state="$v.bank.bik.$invalid" label="БИК банка:" label-class="fs-14 grey-color fw-700" label-for="supplier-accounting-bik">
                    <VueSuggestions id="supplier-accounting-bik" :class="$v.bank.bik.$invalid ? 'is-invalid' : 'is-valid'" :model="bank.bik" :options="suggestionOptions" :placeholder="'Начните вводить'" class="fs-14 form-control"> </VueSuggestions>
                    <template v-if="errorsBik">
                        <b-form-invalid-feedback>Поле обязательно для заполнения</b-form-invalid-feedback>
                    </template>
                </b-form-group>
            </b-col>
            <form-row-edit-text-compact v-model="bank.bank_name" :v="$v.bank.bank_name" disabled="disabled" label="Название банка" />
            <form-row-edit-text-compact v-model="bank.bank_city" :v="$v.bank.bank_city" disabled label="Город банка" />
            <form-row-edit-text-compact v-model="bank.ks" :v="$v.bank.ks" label="Кор. счет" @input="onInput" />
            <form-row-edit-text-compact v-model="bank.rs" :v="$v.bank.rs" label="Расчетный счет" @input="onInput" />
            <form-row-edit-text-compact v-model="bank.ls" :v="$v.bank.ls" label="Лицевой счет" v-show="bank.bank_type !== 'bank'" @input="onInput" />
        </b-form-row>
    </div>
</template>

<script>
import VueSuggestions from 'vue-suggestions';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';
import isEmpty from 'lodash.isempty';
import cloneDeep from 'lodash.clonedeep';
import FrcSelect from '@/components/common/form-rows/frc-select.vue';
import FormRowEditTextCompact from '@/components/common/form-rows/form-row-edit-text-compact.vue';
import { maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators';

export default {
    props: {
        initialData: { required: true, type: SupplierAccountingVO }
    },
    components: { FormRowEditTextCompact, FrcSelect, VueSuggestions },
    data() {
        return {
            bankTypes: [
                { id: 'bank', name: 'Банк' },
                { id: 'finOrg', name: 'Финансовый орган' },
                { id: 'fedKazn', name: 'Орган ФК' }
            ],
            suggestionOptions: {
                token: process.env.VUE_APP_DADATA_TOKEN,
                type: ['BANK'],
                scrollOnFocus: false,
                triggerSelectOnBlur: true,
                triggerSelectOnEnter: false,
                addon: 'none',
                onSelect: this.onSelect,
                onInvalidateSelection: this.onInvalidateSelection,
                onSelectNothing: this.onSelectNothing,
                noCache: true,
                formatSelected: this.formatSelected
            },
            bank: {
                bank_type: null,
                bik: null,
                bank_name: null,
                bank_city: null,
                ks: null,
                rs: null,
                ls: null
            }
        };
    },
    validations() {
        return {
            bank: {
                bank_type: { required },
                bik: { required, minLength: minLength(9), maxLength: maxLength(9) },
                bank_name: { required },
                bank_city: { required },
                ks: { required: required, minLength: minLength(20), maxLength: maxLength(20) },
                rs: { required: required, minLength: minLength(20), maxLength: maxLength(20) },
                ls: { required: requiredIf(() => this.bank.bank_type !== 'bank') }
            }
        };
    },
    computed: {
        errorsBik() {
            return isEmpty(this.bank.bik);
        },
        errorsKs() {
            return isEmpty(this.bank.ks);
        },
        errorsRs() {
            return isEmpty(this.bank.rs);
        }
    },
    created() {
        this.bank = cloneDeep(this.initialData);
    },
    methods: {
        onSelect(suggestion) {
            const isTreasury = suggestion.data.opf.type === 'TREASURY';
            let ks = suggestion.data.correspondent_account;
            if (isTreasury) {
                if (suggestion.data.treasury_accounts && suggestion.data.treasury_accounts.length > 0) {
                    ks = suggestion.data.treasury_accounts[0];
                }
            }
            this.bank = new SupplierAccountingVO({
                bank_type: this.bank.bank_type,
                bik: suggestion.data.bic,
                bank_name: suggestion.data.name.payment,
                bank_city: suggestion.data.payment_city,
                ks: ks,
                rs: this.bank.rs,
                ls: this.bank.ls
            });
            this.onInput();
        },
        onBankInvalidateSelection() {
            const bankType = this.bank.bank_type;
            this.bank = new SupplierAccountingVO();
            this.bank.bank_type = bankType;
        },
        onBankSelectNothing() {
            this.bank.bik = this.initialData.bik;
        },
        formatSelected(suggestion) {
            return (suggestion.value = suggestion.data.bic);
        },
        onInput() {
            this.$emit('select', new SupplierAccountingVO(this.bank));
        }
    }
};
</script>
