'use strict';

import FileAttachmentVO from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import AnnexItemEditableVO from '@core/js/ddriven/domain/model/contracts/annex/AnnexItemEditable.valueobject';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import AnnexDeliverableItemEditablesCollection from '../../../../domain/model/common/deliverable/update/annex/AnnexDeliverableItemEditables.collection';
import AnnexDeliverableItemEditableVO from '../../../../domain/model/common/deliverable/update/annex/AnnexDeliverableItemEditable.valueobject';

export interface IParameters {
    annex: AnnexItemEditableVO | null;
    attachments: {
        dispute: FileAttachmentVO;
        annex: FileAttachmentVO | null;
    };
    comment: string;
    xml: string;
    thumbprint: string;
}

interface IFile {
    id: string;
}

interface IItem {
    id: number;
    purchase_item_id: number;
    okei_id: number;
    ktru_item_id: number;
    okpd_id: number;
    amount: number;
    price: number;
    total_cost: number;
    is_delivery_item: boolean;
    is_manually_added: boolean;
    is_deleted: boolean;
}

export default class StoreContractAnnexDisputeRequestVO extends BaseValueObject {
    dispute_file: IFile;
    new_supplementary_agreement: IFile | null;
    dispute_message: string;
    new_specifications: IItem[] | null;
    xml: string;
    thumbprint: string;

    constructor(data: IParameters) {
        super();

        this.dispute_file = {
            id: data.attachments.dispute.file_id as string
        };
        this.new_supplementary_agreement = data.attachments.annex ? { id: data.attachments.annex.file_id as string } : null;
        this.dispute_message = data.comment;
        this.new_specifications = !data.annex ? null : this.transformDeliverables(data.annex.deliverables);
        this.xml = data.xml;
        this.thumbprint = data.thumbprint;
    }

    private transformDeliverables(collection: AnnexDeliverableItemEditablesCollection): IItem[] {
        return collection.unfilteredList.map((deliverable: AnnexDeliverableItemEditableVO) => {
            return {
                id: deliverable.another_id as number,
                purchase_item_id: deliverable.id as number,
                okei_id: deliverable.okei_id,
                ktru_item_id: deliverable.gsc_details.id,
                okpd_id: deliverable.okpd_id,
                amount: deliverable.quantity,
                price: deliverable.price_per_unit,
                total_cost: deliverable.price_total,
                is_delivery_item: deliverable.is_delivery,
                is_manually_added: deliverable.isDuplicate,
                is_deleted: deliverable.isDeleted
            };
        });
    }
}
