'use strict';

import purchases from './purchases/index';
import contracts from './contracts/index';

const GroupFL44StoreModule = {
    namespaced: true,

    modules: {
        purchases,
        contracts
    }
};

export default GroupFL44StoreModule;
