import axios from 'axios';
import FileDownload from 'js-file-download';

export default {
    getContracts(filters) {
        return axios
            .get('/contracts', {
                params: filters
            })
            .then((response) => {
                return response.data;
            });
    },
    sendFileContract(data) {
        return axios.post('/purchases/contracts', data).then((response) => {
            return response.data;
        });
    },
    sendDisagreementFile(data) {
        return axios.post('/purchases/contracts/sendDisagreementFile', data).then((response) => {
            return response.data;
        });
    },
    getFileXml(payload) {
        return axios.post('/xml/contract_file_form', { contract_file: payload });
    },
    getContractXml(payload) {
        return axios.post('/xml/contract_form', { contract_form: payload });
    },
    getPurchaseContractXML(purchaseId, payload = null) {
        return axios.post(`/purchases/${purchaseId}/contract/contract_xml`, { contract_form: payload });
    },
    signContract(payload) {
        return axios.post('/purchases/contracts/sign', payload).then((response) => {
            return response.data;
        });
    },
    getContractExecutionDocuments(contractId) {
        return axios.get(`/contracts/${contractId}/execution_documents`);
    },
    addExecutionDocument(contractId, formData) {
        return axios.post(`/contracts/${contractId}/execution_documents`, formData);
    },
    deleteExecutionDocument(contractId, contractExecutionDocumentId) {
        return axios.delete(`/contracts/${contractId}/execution_documents/${contractExecutionDocumentId}`);
    },
    getContractAvailableStatuses(contractId) {
        return axios.get(`/contracts/${contractId}/available_statuses`);
    },
    changeContractStatus(contractId, statusId) {
        return axios.post(`/contracts/${contractId}/change_status`, { status_id: statusId });
    },
    getContractSupplementaryAgreements(contractId) {
        return axios.get(`/contracts/${contractId}/supplementary_agreements`);
    },
    getNewSupplementaryAgreementItemsForContract(contractId) {
        return axios.get(`/contracts/${contractId}/supplementary_agreement_items`);
    },
    storeSupplementaryAgreementAndSendToProvider(contractId, newSupplementaryAgreement) {
        const sendData = {
            document: newSupplementaryAgreement.document,
            supplementary_agreement_date: newSupplementaryAgreement.supplementary_agreement_date,
            items: newSupplementaryAgreement.items.map((item) => {
                return {
                    purchase_item_id: item['purchase_item_id'],
                    okei_id: item['okei_id'],
                    ktru_item_id: item['ktru_item_id'],
                    limit_id: item.limit.id,
                    okpd_id: item['okpd_id'],
                    amount: item['amount'],
                    price: item['price'],
                    total_cost: Math.round(item['amount'] * item['price'] * 100) / 100,
                    is_delivery_item: item['is_delivery_item'],
                    is_manually_added: item['is_manually_added'],
                    is_deleted: item['is_deleted']
                };
            })
        };
        return axios.post(`/contracts/${contractId}/supplementary_agreements`, sendData);
    },
    sendSupplementaryAgreementDisputeFile(contractId, supplementaryAgreementId, data) {
        return axios.post(`/contracts/${contractId}/supplementary_agreements/${supplementaryAgreementId}/send_disagreement_file`, data);
    },
    sendSupplementaryAgreement(contractId, supplementaryAgreementId, data) {
        return axios.post(`/contracts/${contractId}/supplementary_agreements/${supplementaryAgreementId}/sign`, data);
    },
    getSupplementaryAgreementDisputeXML(payload) {
        return axios.post('/xml/supplementary_agreement_dispute', payload);
    },
    getSupplementaryAgreementXML(payload) {
        return axios.post('/xml/supplementary_agreement', payload);
    },
    getContractDeliveryItem(contractId) {
        return axios.get(`/contracts/${contractId}/delivery_item`);
    },
    downloadSupplementaryAgreementPDF(contractId, supplementaryAgreementId, fileName) {
        return axios.get(`/contracts/${contractId}/supplementary_agreements/${supplementaryAgreementId}/pdf`, { responseType: 'blob' }).then((response) => {
            FileDownload(response.data, fileName);
        });
    }
};
