var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", {
        domProps: {
          textContent: _vm._s(_vm.deliverable.gsc_details.registration_number),
        },
      }),
      _vm._v(" "),
      _c("i", {
        staticClass: "atmo-icon-question-mark",
        attrs: { id: _vm.id },
      }),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.id,
            triggers: "hover",
            container: "body",
            placement: "top",
            boundary: "window",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm._v(
                    " Рег. номер ТРУ: " +
                      _vm._s(_vm.deliverable.gsc_details.registration_number) +
                      " "
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("ul", { staticClass: "fs-12" }, [
            _c("li", [
              _c("strong", [_vm._v("Группа ТРУ: ")]),
              _vm._v(_vm._s(_vm.deliverable.gsc_details.group)),
            ]),
            _c("li", [
              _c("strong", [_vm._v("Наименование: ")]),
              _vm._v(_vm._s(_vm.deliverable.gsc_details.title)),
            ]),
            _c("li", [
              _c("strong", [_vm._v("Тип: ")]),
              _vm._v(_vm._s(_vm.deliverable.gsc_details.type)),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }