'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';

@Component
export default class AddressListInputController extends BaseViewModel {
    constructor() {
        super();
        this.name = 'AddressListInputController';
    }

    @Prop({ required: true, type: Array }) readonly useraddressbook?: ExtendedDictionaryItem[];
    @Prop({ required: true, type: Function }) readonly addhandler?: Record<string, unknown>;
    @Prop({ required: true, type: Function }) readonly removehandler?: Record<string, unknown>;
    @Prop({ required: true, type: Array }) readonly addresseslist?: string[];

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$props.addresseslist.length < 1;
    }

    /**
     * Methods
     */
    add(payload: IViewmodelEventPayload): void {
        this.$props.addhandler(payload);
    }

    remove(index: number): void {
        this.$props.removehandler(index);
    }
}
