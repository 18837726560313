<template>
    <div>
        <span v-text="deliverable.gsc_details.registration_number"></span>&nbsp;<i class="atmo-icon-question-mark" :id="id"></i>
        <b-popover :target="id" triggers="hover" container="body" placement="top" boundary="window">
            <template #title> Рег. номер ТРУ:&nbsp;{{ deliverable.gsc_details.registration_number }} </template>
            <ul class="fs-12">
                <li><strong>Группа ТРУ:&nbsp;</strong>{{ deliverable.gsc_details.group }}</li>
                <li><strong>Наименование:&nbsp;</strong>{{ deliverable.gsc_details.title }}</li>
                <li><strong>Тип:&nbsp;</strong>{{ deliverable.gsc_details.type }}</li>
            </ul>
        </b-popover>
    </div>
</template>

<script>
export default {
    props: {
        deliverable: { required: true, type: Object }
    },
    computed: {
        id() {
            return 'gscpopover_' + this._uid;
        }
    }
};
</script>
