var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "atmo-address-book atmo-dropdown-select" },
    [
      _c("dropdown-select", {
        attrs: { list: this.$props.controller.$props.useraddressbook },
        on: { "dropdown:select:selected": this.$props.controller.add },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ds) {
              return [
                _c("on-off", {
                  attrs: { options: { onBody: "intelligent" } },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (onoff) {
                          return [
                            _c("div", { staticClass: "atmo-dropdown-select" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "atmo-button",
                                  attrs: {
                                    disabled: _vm.isEmpty,
                                    type: "button",
                                  },
                                  on: { click: onoff.toggle },
                                },
                                [
                                  _c("span", [_vm._v("Адресная книга ")]),
                                  _vm.isEmpty
                                    ? _c("span", [_vm._v("(пусто)")])
                                    : _vm._e(),
                                  !onoff.ison
                                    ? _c("i", {
                                        staticClass: "atmo-icon-angle-down",
                                      })
                                    : _c("i", {
                                        staticClass: "atmo-icon-angle-up",
                                      }),
                                ]
                              ),
                              _c(
                                "ul",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: onoff.ison,
                                      expression: "onoff.ison",
                                    },
                                  ],
                                  staticClass: "atmo-dropdown",
                                  on: { click: onoff.off },
                                },
                                _vm._l(ds.list, function (item) {
                                  return _c(
                                    "li",
                                    {
                                      key: item.id,
                                      on: {
                                        click: function ($event) {
                                          return ds.select(item.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(item.title),
                                        },
                                      }),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }