<template>
    <b-form-group :label="label + ':'" :state="state" class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <date-time-picker
            :id="id"
            :class="stateClass"
            v-model="date"
            :label="placeholder"
            :only-date="true"
            formatted="DD.MM.YYYY"
            format="YYYY-MM-DD"
            buttonNowTranslation="Сегодня"
            autoClose
            :min-date="minDate"
            :max-date="maxDate"
            :no-label="!!value"
            :disabled="disabled"
            :no-button-now="withoutNow"></date-time-picker>
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowEditDate',
    props: {
        value: {
            type: String,
            default: ''
        },
        v: {
            type: Object,
            default: null
        },
        label: {
            type: String,
            required: true
        },
        minDate: {
            default: null
        },
        maxDate: {
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Выберите дату'
        },
        withoutNow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            id: null
        };
    },
    mounted() {
        this.id = this._uid;
    },
    computed: {
        date: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        },
        stateClass() {
            if (!this.v) {
                return '';
            } else {
                return this.v.$invalid ? 'is-invalid' : 'is-valid';
            }
        },
        errorMessage() {
            if (this.v) {
                if (!this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            }
            return '';
        }
    }
};
</script>
