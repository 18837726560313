'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import FormatDate from '@lib/js/src/misc/FormatDate';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';

export interface IContractRawDataItem {
    id: number; // Purchase ID
    contract: {
        id: number; // Contract ID
    };
    status_id: number;
    contract_date: string | null;
    planned_contract_date: string;
    planned_end_date: string | null;
    delivery: Record<string, unknown>[];
    reg_number: string;
    purchase_name: string;
    purchase_object: string | null;
    is_external: boolean;
    start_price: number;
    count_offers: number;
    items_count: number;
    min_offer: string;
    discount_percentage: number;
    customer_id: number;
    customer_name: string;
    provider_id: number;
    provider_name: string;
}

/**
 * REFACTOR: Extract to separate file if there will be enough reason to
 * (e.g. more complex address data structure).
 */
export interface IDeliveryAddress {
    address: string;
}

export default class ContractItem extends BaseValueObject {
    id: number | null;
    status_id: number;
    signature_date: string;
    planned_completion_date: string | null;
    delivery_addresses: IDeliveryAddress[];
    purchase_id: number;
    purchase_registration_number: string;
    purchase_description: string;
    purchase_object: string | null;
    purchase_is_outside_atmo: boolean;
    purchase_starting_price: number;
    purchase_proposals_count: number;
    purchase_items_count: number;
    best_proposal_price: number;
    best_proposal_discount_percent: number;
    customer_organization_id: number;
    customer_organization_title: string;
    supplier_organization_id: number;
    supplier_organization_title: string;

    private _timezone: string;

    constructor(contractRawData: IContractRawDataItem) {
        super();

        this.defineHiddenProperty('_timezone');

        this._timezone = Config.get('timezone.customer') as string;

        this.id = contractRawData.contract ? contractRawData.contract.id : null;
        this.status_id = contractRawData.status_id;
        this.signature_date = this.signatureDate(contractRawData.contract_date, contractRawData.planned_contract_date);
        this.planned_completion_date = this.plannedCompletionDate(contractRawData.planned_end_date);
        this.delivery_addresses = this.delivery(contractRawData.delivery);
        this.purchase_id = contractRawData.id;
        this.purchase_registration_number = contractRawData.reg_number;
        this.purchase_description = contractRawData.purchase_name;
        this.purchase_object = contractRawData.purchase_object;
        this.purchase_is_outside_atmo = contractRawData.is_external;
        this.purchase_starting_price = contractRawData.start_price;
        this.purchase_proposals_count = contractRawData.count_offers;
        this.purchase_items_count = contractRawData.items_count;
        this.best_proposal_price = parseFloat(contractRawData.min_offer);
        this.best_proposal_discount_percent = contractRawData.discount_percentage;
        this.customer_organization_id = contractRawData.customer_id;
        this.customer_organization_title = contractRawData.customer_name;
        this.supplier_organization_id = contractRawData.provider_id;
        this.supplier_organization_title = contractRawData.provider_name;
    }

    private signatureDate(contractDate: string | null, plannedContractDate: string): string {
        return contractDate ? FormatDate.dateFromISOUTCToTimezoned(contractDate, this._timezone) : new Date(plannedContractDate).toISOString();
    }

    plannedCompletionDate(plannedEndDate: string | null): string | null {
        return plannedEndDate ? new Date(plannedEndDate).toISOString() : null;
    }

    private delivery(deliveryData: Record<string, unknown>[]): IDeliveryAddress[] {
        return deliveryData.map((item) => {
            return { address: item.address } as IDeliveryAddress;
        });
    }
}
