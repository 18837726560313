'use strict';

import OrganizationReliabilityReportVMDService from '@core/js/ddriven/domain/services/viewmodel-data/organization-reliability-report/OrganizationReliabilityReportVMD.service';
import { IParsedCertificate } from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';

export default class SignOrganizationReliabilityReportDownloadRequestVO extends BaseValueObject {
    public inn: string;
    public email: string;
    public send_to_email: boolean;
    public thumbprint: string;
    public xml: string;
    public certificate: IParsedCertificate;

    constructor(vmdservice: OrganizationReliabilityReportVMDService, certificate: IParsedCertificate, xml: string) {
        super();

        this.inn = vmdservice.editable.inn!;
        this.email = vmdservice.editable.email!;
        this.send_to_email = vmdservice.editable.has_to_email_report;
        this.thumbprint = certificate.thumbprint;
        this.certificate = certificate;
        this.xml = xml;
    }
}
