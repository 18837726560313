<template>
    <div class="atmo-content-group">
        <h5>
            <slot></slot>
        </h5>

        <div class="table-responsive">
            <table class="atmo-content-table atmo-attachments-main">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Документ</th>
                        <th v-if="!pivc.isPurchaseOutATMO">Подписи</th>
                        <th class="atmo-ct-date">Дата и время направления документов</th>
                        <th v-if="!pivc.isPurchaseOutATMO">В архиве</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(attachment, index) in this.$props.attachments" v-bind:key="index">
                        <td v-text="index + 1"></td>
                        <td>
                            <button class="atmo-button-link" v-on:click="$props.pivc.downloadFileAttachment(attachment)" v-text="attachment.name"></button>
                        </td>
                        <td v-if="!pivc.isPurchaseOutATMO">
                            <button v-if="$props.pivc.isContractSignedByBoth && !attachment.is_archived" class="atmo-button-icon" title="Нажмите, чтобы просмотреть подписи" v-on:click.stop="$props.pivc.invokeSignaturePopup([$props.signatures.supplier, $props.signatures.customer])">
                                <i class="atmo-icon-certificate"></i>
                            </button>
                            <div v-if="(!$props.pivc.isContractSignedBySupplier || !$props.pivc.isContractSignedByCustomer) && !attachment.is_archived && !$props.pivc.isContractDisputed">
                                Ожидает подписи
                                <span v-if="!$props.pivc.isContractSignedBySupplier">поставщика, </span>
                                <span v-if="!$props.pivc.isContractSignedByCustomer">заказчика</span>
                            </div>
                            <div v-if="!attachment.is_archived && $props.pivc.isContractDisputed">
                                <span v-text="$props.pivc.purchaseitem.contract.acceptance_status_title"></span>
                            </div>
                        </td>
                        <td class="atmo-ct-date">{{ attachment.created_at | formatdate('withSeconds') }}</td>
                        <td v-if="!pivc.isPurchaseOutATMO"><span v-if="attachment.is_archived">Да</span></td>
                    </tr>
                    <tr v-if="$props.attachments < 1">
                        <td class="atmo-centered" colspan="5">Нет документов</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <template v-if="pivc.hasContract && pivc.isUserEngagedCustomer && !pivc.isContractSignedByCustomer && pivc.isContractSignedBySupplier">
            <ul class="atmo-content-block-2-columns atmo-no-item-separator">
                <li>
                    <dt class="atmo-help">
                        Номер договора в системе учета заказчика:
                        <i class="atmo-icon-question-mark"></i>
                        <span class="atmo-help__text">Укажите номер договора в случае если он отличается от номера договора в ИС</span>
                    </dt>
                    <dd class="atmo-form__field atmo-inverse">
                        <input v-model="pivc.$data.customer_contract_number" type="text" />
                    </dd>
                </li>
            </ul>
        </template>

        <slot name="controls"></slot>
    </div>
</template>

<script>
export default {
    props: {
        pivc: { required: true, type: Object },
        signatures: { required: true, type: Object },
        attachments: { required: true, type: Array }
    }
};
</script>
