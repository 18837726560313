<template>
    <div class="d-block text-center">
        <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
            <LoginError v-if="isLoginError" />
            <b-form-group v-else-if="certificateErrorCode === 0" label="Выберите сертификат" label-class="fs-18 grey-color">
                <multiselect
                    v-model="selectCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    label="text"
                    placeholder="Выберите сертификат"
                    track-by="value"
                    @input="onCertificateChange">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.text }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.valid_from) }} по {{ getDateFormat(props.option.valid_to) }}
                    </template>
                    <template slot="option" slot-scope="props">
                        <strong>Субъект:</strong> {{ props.option.text }}<br />
                        <br />
                        <strong>Срок действия:</strong> с {{ getDateFormat(props.option.valid_from) }} по {{ getDateFormat(props.option.valid_to) }}
                    </template>
                </multiselect>
                <b-form-invalid-feedback :state="state">{{ errorMessage }}</b-form-invalid-feedback>
            </b-form-group>
            <CryptoproError v-else-if="certificateErrorCode === 90097" />
            <b-container v-else class="text-danger fs-18 fw-600 text-center my-5">
                <p>{{ certificateErrorMessage }}</p>
            </b-container>
            <footer v-if="!isLoginError" class="modal-footer pb-0 px-0">
                <div class="w-100 text-left">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
                    <b-button :disabled="!state" class="text-uppercase" variant="custom-green" @click="login">Продолжить</b-button>
                </div>
            </footer>
        </b-overlay>
    </div>
</template>

<script>
import CadesMixins from '@/mixins/CadesMixins';
import axios from 'axios';
import LoginError from '@/components/auth/login-error.vue';
import CryptoproError from '@/components/auth/cryptopro-error.vue';

export default {
    name: 'login-with-certificate',
    components: { CryptoproError, LoginError },
    mixins: [CadesMixins],
    data() {
        return {
            isLoading: false, // modal выбора сертификатов
            isLoginError: false,
            selectCertificate: null,
            certificateErrorMessage: null,
            certificateErrorCode: 0
        };
    },
    mounted() {
        if (this.hasCertificates() === false) {
            try {
                this.$emit('loadStart');
                this.isLoading = true;
                this.loadCertificates();
            } catch (error) {
                this.certificateErrorMessage = error.message;
                this.certificateErrorCode = error.code;
            } finally {
                this.$emit('loadEnd');
                this.isLoading = false;
            }
        }
        this.$on('certificates_loaded', () => {
            this.$emit('loadEnd');
            this.isLoading = false;
        });
        this.$emit('hideClose');
    },
    methods: {
        onCancel() {
            this.isLoginError = true;
            this.$emit('showClose');
        },
        onCertificateChange(value) {
            if (value) {
                //this.$set(this.certIndex, value.value)
                this.certIndex = value.value;
                this.certBackError = false;
            }
        },
        async login() {
            this.$emit('loadStart');
            this.isLoading = true;
            const parsedCerts = this.$store.state.parsedCertificates;
            const currentParsedCert = parsedCerts[this.certIndex];
            // выдергиваем email из subject сертификата
            const email = this.parseSubject(currentParsedCert.subject, 'E=');
            const thumbprint = currentParsedCert.thumbprint;
            const valid_to = currentParsedCert.valid_to;
            const subject = currentParsedCert.subject;
            const login_type = 'certificate';
            await axios
                .post('/auth/login', { login_type, email, thumbprint, valid_to, subject })
                .then((response) => {
                    // устанавливаем выбранный сертификат в храниилище
                    this.$store.commit('set_current_certificate', {
                        index: this.certIndex,
                        thumbprint: thumbprint
                    });
                    this.$store
                        .dispatch('login', {
                            token: response.data.access_token,
                            user: response.data.user,
                            organization: response.data.organization
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                    this.$emit('close');
                })
                .catch((error) => {
                    this.certErrorText = error.response.data.message;
                    this.certBackError = true;
                })
                .finally(() => {
                    this.$emit('loadEnd');
                    this.isLoading = false;
                });
        },
        // отмена выбора сертификатов
        cancelCertificate() {
            this.$emit('close');
            /*this.$refs['login-modal'].hide();
            this.showForm = false;
            this.$bvToast.toast('Работа на сайте возможна только при наличии ЭЦП', {
                title: 'Ошибка',
                variant: 'danger'
            });
            this.$router.push('/auth/error').catch(() => {});*/
        }
    },
    computed: {
        state() {
            return this.$v !== null ? !this.$v.certIndex.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (!this.$v.certIndex.required) return 'Сертификат не выбран';
                if (!this.$v.certIndex.certBackError) return this.certErrorText;
            }
            return '';
        }
    }
};
</script>
