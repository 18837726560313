var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-content-group" }, [
    _c("h3", { attrs: { id: "atmo-supply-terms" } }, [
      _vm._v("Условия поставки"),
    ]),
    _c(
      "ul",
      { staticClass: "atmo-content-block-2-columns atmo-no-item-separator" },
      [
        _c("li", { staticClass: "atmo-align-top" }, [
          _vm._m(0),
          _c(
            "dd",
            [
              _c("address-list-input-contoller", {
                attrs: {
                  addhandler: this.$props.addhandler,
                  removehandler: this.$props.removehandler,
                  useraddressbook: this.$props.useraddressbook,
                  addresseslist: _vm.$props.inputs.delivery_addresses,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (alic) {
                      return [
                        _c(
                          "div",
                          { staticClass: "atmo-component-wrapper" },
                          [
                            _c("AddressesList.view", {
                              attrs: { controller: alic },
                            }),
                            _c("AddressAutocomplete.view", {
                              attrs: { controller: alic },
                            }),
                            _c("AddressBook.view", {
                              attrs: { controller: alic },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("dt", [
            _vm._v(
              "График поставки товаров (выполнения работ, оказания услуг):"
            ),
          ]),
          _c("dd", { staticClass: "atmo-form__field atmo-inverse" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.$props.inputs.delivery_schedule,
                  expression: "$props.inputs.delivery_schedule",
                },
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.$props.inputs.delivery_schedule },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.$props.inputs,
                    "delivery_schedule",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", { staticClass: "atmo-help" }, [
      _vm._v(" Адреса для доставки товаров/выполнения работ/оказания услуг: "),
      _c("i", { staticClass: "atmo-icon-question-mark" }),
      _c("span", { staticClass: "atmo-help__text" }, [
        _vm._v(
          "Укажите новый адрес или выберите ранее сохраненный адрес из Адресной книги."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }