var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "atmo-content-table atmo-addresses" }, [
      _vm._m(0),
      _c(
        "tbody",
        [
          _vm._l(
            _vm.$props.controller.$props.addresseslist,
            function (address, index) {
              return _c("tr", { key: index }, [
                _c("td", { domProps: { textContent: _vm._s(index + 1) } }),
                _c("td", { domProps: { textContent: _vm._s(address) } }),
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "atmo-button-icon",
                      attrs: { title: "Удалить адрес" },
                      on: {
                        click: function ($event) {
                          return _vm.$props.controller.remove(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "atmo-icon-trash" })]
                  ),
                ]),
              ])
            }
          ),
          _vm.$props.controller.islistempty
            ? _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v("Не добавлено адресов"),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("№")]),
        _c("th", [_vm._v("Адрес")]),
        _c("th", [_vm._v(" ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }