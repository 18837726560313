<template>
    <!--  <div>-->
    <b-form-group :label="title + ':'" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline" :label-cols-lg="labelColsLg" :label-cols-sm="labelColsSm" style="word-break: break-all">
        <template v-if="!!document">
            <a class="fs-14 col-form-label d-inline-block" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
            <b-button :disabled="disabled" class="float-right" size="sm" style="background: transparent" variant="light" @click="removeFile(0, true)">
                <img alt="trash_icon" src="/images/trash_icon.svg" />
            </b-button>
        </template>
        <template v-else>
            <p :class="!state ? 'text-danger' : ''" class="col-form-label d-inline-block" style="font-size: 80%">Прикрепите документ</p>
            <file-uploader :is-disabled="!!document || disabled" :is-main="true" class="d-inline-block float-right" @set_file="addFile">Загрузить</file-uploader>
        </template>
    </b-form-group>
    <!--  </div>-->
</template>

<script>
export default {
    name: 'FormRowSingleLineDocumentUpload',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Object
        },
        v: {
            type: Object,
            required: true
        },
        required: {
            type: Boolean,
            default: true
        },
        headerClass: {
            type: String,
            default: 'fs-32'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelColsLg: {
            default: 6
        },
        labelColsSm: {
            default: 4
        }
    },
    methods: {
        addFile(file) {
            this.document = file;
        },
        removeFile() {
            this.document = null;
        }
    },
    computed: {
        document: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        state() {
            return this.v !== null ? !this.v.$invalid : null;
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    vertical-align: middle;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

a {
    color: var(--green-color) !important;
}
</style>
