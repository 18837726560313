<template>
    <div id="atmo-deliverables" class="atmo-content-group">
        <h3>Спецификация</h3>

        <h5>Список позиций спецификации</h5>

        <slot name="info"></slot>

        <div class="table-responsive">
            <table v-bind:class="{ 'atmo-empty': this.isempty }" class="atmo-content-table atmo-deliverables-editable">
                <thead>
                    <tr>
                        <th class="atmo-ctc-place">№</th>
                        <th>Рег.номер ТРУ</th>
                        <th><abbr title="Характеристики ТРУ">Хар-ки ТРУ</abbr></th>
                        <th>Наименование ТРУ</th>
                        <th>Код ОКПД2</th>
                        <th class="atmo-ctc-quantity">Количество</th>
                        <th class="atmo-ctc-okpd-symbolic-code">Ед.изм</th>
                        <th class="atmo-ctc-price-per-unit">Цена за ед., руб.</th>
                        <th class="atmo-ctc-price-total">Стоимость, руб.</th>
                        <th class="atmo-ctc-command-remove" v-if="$props.islisteditable">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <fragment v-for="(deliverable, index) in this.$props.deliverables" v-bind:key="deliverable.id + index + 1">
                        <tr :class="visible[index] ? 'border-bottom-0' : ''">
                            <td v-text="index + 1" class="atmo-ctc-place"></td>
                            <td>
                                <span v-if="deliverable.gsc_details && !deliverable.gsc_details.isEmpty()">
                                    <GSCPopover.view v-bind:deliverable="deliverable"></GSCPopover.view>
                                </span>
                                <span v-else>—</span>
                            </td>

                            <td>
                                <button v-b-toggle="'collapse-' + index" class="atmo-button-icon">
                                    <span v-if="!visible[index]" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                                    <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                                </button>
                                <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="d-none" />
                            </td>

                            <td v-text="deliverable.title"></td>
                            <td>
                                <span v-if="deliverable.okpd_code" v-text="deliverable.okpd_code"></span>
                                <span v-else>—</span>
                            </td>

                            <td class="atmo-ctc-quantity">
                                <formatted-input-number v-slot:default="fim" v-bind:decimals="5" v-bind:padright="false">
                                    <div class="atmo-component-wrapper">
                                        <button v-if="!fim.isInputFocused" v-on:click.stop="fim.focus" v-on:focus="fim.onTriggerFocus" v-bind:class="{ 'atmo-invalid': !fim.isInputValid }" class="atmo-button--small atmo-input" v-text="fim.formatted"></button>

                                        <span v-show="fim.isInputFocused" class="atmo-form__field atmo-inverse atmo-small">
                                            <input
                                                v-model.number="deliverable.quantity"
                                                v-bind:disabled="!$props.isquantityeditable"
                                                v-on:blur="fim.processBlurEvent"
                                                v-on:keydown="fim.processKeydownEvent"
                                                v-on:input="fim.processInputEvent"
                                                v-stop-number-mousewheel
                                                required
                                                type="number"
                                                step="0.00001"
                                                min="0.00001" />
                                        </span>
                                    </div>
                                </formatted-input-number>
                            </td>

                            <td v-text="deliverable.okei_symbolic_code" class="atmo-ctc-okpd-symbolic-code"></td>

                            <td class="atmo-ctc-price-per-unit">
                                <formatted-input-number v-slot:default="fim">
                                    <div class="atmo-component-wrapper">
                                        <button v-if="!fim.isInputFocused" v-on:click.stop="fim.focus" v-on:focus="fim.onTriggerFocus" v-bind:class="{ 'atmo-invalid': !fim.isInputValid }" class="atmo-button--small atmo-input" v-text="fim.formatted"></button>

                                        <span v-show="fim.isInputFocused" class="atmo-form__field atmo-inverse atmo-small">
                                            <input
                                                v-model.number="deliverable.price_per_unit"
                                                v-on:blur="fim.processBlurEvent"
                                                v-on:keydown="fim.processKeydownEvent"
                                                v-on:input="fim.processInputEvent"
                                                v-on:keyup.enter="fim.processBlurEvent"
                                                v-stop-number-mousewheel
                                                required
                                                type="number"
                                                step="0.01"
                                                min="0.01" />
                                        </span>
                                    </div>
                                </formatted-input-number>
                            </td>

                            <td class="atmo-ctc-price-total">{{ deliverable.price_total | formatnumber }}</td>
                            <td class="atmo-ctc-command-remove" v-if="$props.islisteditable">
                                <button v-bind:disabled="!$props.islisteditable" v-on:click="$props.controller.removeItem(index)" class="atmo-button-icon" title="Удалить позицию из спецификации">
                                    <i class="atmo-icon-trash"></i>
                                </button>
                            </td>
                        </tr>
                        <tr v-show="visible[index]">
                            <td colspan="10">
                                <DeliverableDetails.view v-bind:description="deliverable.gsc_details ? deliverable.gsc_details.description : null" v-bind:requirements="deliverable.requirements" />
                            </td>
                        </tr>
                    </fragment>

                    <tr v-if="!this.isempty" class="atmo-totals">
                        <td colspan="8">Стоимость спецификации итого, руб.:</td>
                        <td>{{ $props.controller.specificationTotalPrice | formatnumber }}</td>
                    </tr>

                    <tr v-if="this.isempty">
                        <td colspan="13" class="atmo-center">Спецификация пуста</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <slot name="errors"></slot>

        <button v-if="$props.islisteditable" v-bind:disabled="!$props.islisteditable" v-on:click.stop="$props.controller.invokeDeliverablesSelectorPopup" class="atmo-button">Добавить позицию спецификации</button>
    </div>
</template>

<script>
import GSCPopoverView from '../../GSCPopover.view.vue';
import FormattedInputNumber from '@lib/js/src/vue/components/FormattedInputNumber.viewmodel.ts';
import DeliverableDetailsView from '../view/DeliverableDetails.view.vue';

export default {
    computed: {
        isempty: function () {
            return this.$props.deliverables.length < 1;
        }
    },
    props: {
        controller: { required: true, type: Object },
        deliverables: { required: true, type: Array },
        islisteditable: { default: true, type: Boolean },
        isquantityeditable: { default: true, type: Boolean },
        max_price: { default: 600000.0, type: Number }
    },
    components: {
        'DeliverableDetails.view': DeliverableDetailsView,
        'GSCPopover.view': GSCPopoverView,
        'formatted-input-number': FormattedInputNumber
    },
    data() {
        return {
            visible: []
        };
    }
};
</script>
