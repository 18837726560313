<template>
    <div>
        <b-modal :title="title" :visible="visible" centered hide-footer hide-header-close no-close-on-backdrop no-close-on-esc size="lg" @hidden="hidden">
            <b-form-group :description="emailFieldDescription">
                <b-input-group>
                    <b-form-input :state="!$v.form.email.$invalid" :value="form.email" class="fs-14" disabled placeholder="Email пользователя" type="email" />
                    <b-input-group-append>
                        <b-button :disabled="emailFieldDisabled" class="text-uppercase fs-14" style="height: 35px" variant="custom-green" @click="sendEmailConfirmationRequest">Подтвердить</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <success-confirm-modal :success-confirm="successConfirm" @hide="hideSuccessConfirm" />
            <b-form-group v-if="showEmailConfirmationInput">
                <b-input-group>
                    <the-mask v-model="form.emailConfirmationCode" class="form-control" mask="######" placeholder="Введите код из сообщения" @input="verifyBackError = false"></the-mask>
                    <b-input-group-append>
                        <b-button class="text-uppercase" variant="custom-green" @click="checkEmail">Подтвердить</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <template v-if="!state">
                <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
            </template>
            <footer class="modal-footer pb-0 px-0">
                <div class="w-100 text-left">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="logout">Отмена</b-button>
                </div>
            </footer>
        </b-modal>
    </div>
</template>

<script>
import Confirmation from '@/services/api/Confirmation';
import { TheMask } from 'vue-the-mask';
import { email, required, minLength, helpers } from 'vuelidate/lib/validators';
import SuccessConfirmModal from '@/components/auth/parts/success-confirm-modal.vue';

const verifyBackError = (param) => helpers.withParams({ type: 'verifyBackError', value: param }, (value) => !helpers.req(value) || param === false);

export default {
    name: 'VerificationEmailModal',
    props: {
        visible: {
            type: Boolean
        },
        email: {
            type: String,
            default: ''
        }
    },
    components: {
        SuccessConfirmModal,
        TheMask
    },
    validations() {
        return {
            form: {
                email: {
                    required,
                    email
                },
                emailConfirmationCode: {
                    minLength: minLength(6)
                },
                verifyBackError: verifyBackError(this.verifyBackError)
            }
        };
    },
    data() {
        return {
            form: {
                username: this.$store.state.user.username,
                email: null,
                emailConfirmationCode: null
            },
            verifyBackError: false,
            verifyErrorText: '',
            showEmailConfirmationInput: false,
            emailConfirmed: false,
            successConfirm: false,
            emailFieldDescription: null,
            emailFieldDisabled: false
        };
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                window.location.assign('/');
            });
        },
        hidden() {
            this.$emit('hidden');
        },
        runRecallTimer() {
            let seconds = 60;
            const setTimerText = () => {
                this.emailFieldDescription = `Возможность повторной отправки через: ${seconds} сек.`;
            };

            setTimerText();

            let interval = setInterval(() => {
                seconds--;
                if (seconds < 0) {
                    this.emailFieldDescription = null;
                    this.emailFieldDisabled = false;
                    return clearInterval(interval);
                }
                setTimerText();
            }, 1000);
        },
        sendEmailConfirmationRequest() {
            this.$v.$touch();
            this.emailFieldDisabled = true;
            this.runRecallTimer();

            Confirmation.requestEmailConfirmation(this.form.email)
                .then(() => {
                    this.form.emailConfirmationCode = null;
                    this.showEmailConfirmationInput = true;
                })
                .catch((error) => {
                    this.verifyBackError = true;
                    this.verifyErrorText = error.response.data.message;
                });
        },
        checkEmail() {
            Confirmation.checkEmailConfirmation(this.form.email, this.form.emailConfirmationCode, this.form.username)
                .then(() => {
                    this.emailConfirmed = true;
                    this.successConfirm = true;
                })
                .catch((error) => {
                    this.verifyBackError = true;
                    this.verifyErrorText = error.response.data.message;
                });
        },
        hideSuccessConfirm() {
            this.successConfirm = false;
            this.hidden();
        }
    },
    computed: {
        title() {
            return 'Проверьте правильность указания Вашего адреса электронной почты и подтвердите его кодом из сообщения';
        },
        state() {
            return this.$v !== null ? !this.$v.$invalid : null;
        },
        errorMessage() {
            if (this.$v) {
                if (!this.$v.form.emailConfirmationCode.minLength) return `Минимальная длина поля - ${this.$v.form.emailConfirmationCode.$params.minLength.min} символа/ов`;
                if (!this.$v.verifyBackError) return this.verifyErrorText;
            }
            return '';
        }
    },
    mounted() {
        this.form.email = this.email;
    }
};
</script>

<style scoped></style>
