'use strict';

import Config from '@core/js/ddriven/application/config/Config';
import FormatDate from '@lib/js/src/misc/FormatDate';
import FileAttachmentVO, { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import X509SignatureVO, { IRawSupplementaryAgreementSignatureData } from '@core/js/ddriven/domain/model/common/X509Signature.valueobject';
import DeliverableItemVO, { IRawAnnexDeliverableItem } from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import { IRawDisputeData } from '@core/js/ddriven/domain/model/contracts/dispute/DisputeResolutionEvent.valueobject';
import DisputeResolutionRoundVO from '../dispute/DisputeResolutionRoundVO.valueobject';
import { Types as UserTypes } from '../../users/User.valueobject';
import KBKLimitsSpecificationItemsEditableCollection from '../../purchases/update/KBKLimitsSpecificationItemsEditable.collection';
import { IRawKBKLimit } from '../../purchases/update/KBKLimitSpecificationItemEditable.valueobject';
import roundToTwoDecimals from '@lib/js/src/misc/roundToTwoDecimals';
import SupplierAccountingVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SupplierAccounting.valueobject';

export enum AnnexStatuses {
    SentToSupplier = 1,
    SignedBySupplier = 2,
    SignedByBoth = 3,
    IsDisputed = 4
}

export interface IRawAnnexItemData {
    id: number;
    status_id: number;
    number: number | null;
    supplementary_agreement_date: string;
    is_signed: boolean;
    is_signed_by_customer: boolean;
    is_signed_by_provider: boolean;
    files: IRawFileAttachmentData[];
    customer_signature: IRawSupplementaryAgreementSignatureData;
    provider_signature: IRawSupplementaryAgreementSignatureData;
    items: IRawAnnexDeliverableItem[];
    limits: IRawKBKLimit[];
    disputes: IRawDisputeData[];
    supplier_accounting: SupplierAccountingVO;
}

export default class AnnexItemVO {
    id: number | null;
    status_id: AnnexStatuses | null;
    sequence_number: number | null;
    date: string | null;
    is_signed_by: {
        customer: boolean;
        supplier: boolean;
    };
    attachments: FileAttachmentVO[];
    signatures: {
        customer: X509SignatureVO;
        supplier: X509SignatureVO;
    };
    deliverables: DeliverableItemVO[];
    kbk_limits: KBKLimitsSpecificationItemsEditableCollection;

    disputes: DisputeResolutionRoundVO[];
    price_total: number | null;
    supplier_accounting: SupplierAccountingVO;

    constructor(data?: IRawAnnexItemData) {
        const timezone = Config.get('timezone.customer') as string;

        this.id = data ? data.id : null;
        this.status_id = data ? data.status_id : null;
        this.sequence_number = data ? data.number : null;
        this.date = data ? FormatDate.dateNormalizedTimezoned(data.supplementary_agreement_date, timezone) : null;
        this.is_signed_by = {
            customer: data ? data.is_signed_by_customer : false,
            supplier: data ? data.is_signed_by_provider : false
        };
        this.attachments = data ? FileAttachmentVO.fromArray(data.files) : [];
        this.signatures = {
            customer: data && data.is_signed ? X509SignatureVO.fromSupplementaryAgreement(UserTypes.customer, data.customer_signature) : X509SignatureVO.fromSupplementaryAgreement(UserTypes.customer),
            supplier: data && data.is_signed ? X509SignatureVO.fromSupplementaryAgreement(UserTypes.supplier, data.provider_signature) : X509SignatureVO.fromSupplementaryAgreement(UserTypes.supplier)
        };

        this.deliverables =
            data && data.items
                ? data.items.map((data: IRawAnnexDeliverableItem) => {
                      return DeliverableItemVO.fromAnnexItem(data);
                  })
                : [];

        this.kbk_limits = data?.limits ? KBKLimitsSpecificationItemsEditableCollection.fromAPIResponse(data.limits) : new KBKLimitsSpecificationItemsEditableCollection();
        this.disputes = data ? this.factoryDisputes(data.disputes) : [];
        this.price_total = this.priceTotal(this.deliverables);
        this.supplier_accounting = data ? SupplierAccountingVO.fromArray(data.supplier_accounting) : new SupplierAccountingVO();
    }

    public static fromArray(dataArray?: IRawAnnexItemData[]): AnnexItemVO[] {
        if (!dataArray || dataArray.length < 1) {
            return [];
        }
        return dataArray.map((data: IRawAnnexItemData) => {
            return new AnnexItemVO(data);
        });
    }

    get deliverables_total_amount() {
        return roundToTwoDecimals(
            this.deliverables.reduce((accumulator, deliverable: DeliverableItemVO) => {
                return (accumulator += deliverable.price_total ?? 0);
            }, 0)
        );
    }

    /**
     * True if the annex is not signed by both parties and is pending for
     * either customer or supplier or both confirmation.
     */
    public isPending(): boolean {
        return this.status_id !== AnnexStatuses.SignedByBoth;
    }

    public isSignedByBoth(): boolean {
        return this.is_signed_by.customer && this.is_signed_by.supplier;
    }

    public isSignedBySupplier(): boolean {
        return this.is_signed_by.supplier;
    }

    public isSignedByCustomer(): boolean {
        return this.is_signed_by.customer;
    }

    public isSignedByEither(): boolean {
        return this.is_signed_by.customer || this.is_signed_by.supplier;
    }

    public hasDisputes(): boolean {
        return this.disputes.length > 0;
    }

    public hasUnresolvedDisputes(): boolean {
        return this.disputes.some((dispute: DisputeResolutionRoundVO) => {
            return !dispute.isResolved();
        });
    }

    public actualAttachment(): FileAttachmentVO | null {
        return this.hasAttachments() ? this.attachments[this.attachments.length - 1] : null;
    }

    public hasAttachments(): boolean {
        return this.attachments.length > 0;
    }

    private priceTotal(deliverables: DeliverableItemVO[]): number | null {
        const amount =
            deliverables.length > 0
                ? (deliverables.reduce((accumulator, previousItem: DeliverableItemVO): any => {
                      return accumulator + previousItem.price_total!;
                  }, 0) as unknown as number)
                : null;
        return amount ? roundToTwoDecimals(amount) : null;
    }

    private factoryDisputes(dataArray: IRawDisputeData[]): DisputeResolutionRoundVO[] {
        return dataArray.map((data: IRawDisputeData) => {
            return DisputeResolutionRoundVO.fromAnnexItem(data);
        });
    }
}
