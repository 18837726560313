'use strict';

import Vue from 'vue';
import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';
import PurchaseItemEditableRequestVO, { TParamDictionaries } from '@core/js/ddriven/application/http/requests/purchases/PurchaseItemEditableRequest.valueobject';
import { IParsedCertificate } from '@core/js/viewmodels/common/popup/BaseSignPopupController.viewmodel';
import ToastDataVO, { TToastType } from '@core/js/ddriven/application/services/uitoast/ToastData.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

export default class PurchaseItemEditableService {
    public vue: Vue;

    constructor(vue: Vue) {
        this.vue = vue;
    }

    public async saveDraft(purchase: PurchaseItemEditableVO, dictionaries: TParamDictionaries): Promise<{ id: number }> {
        const request = new PurchaseItemEditableRequestVO(purchase, dictionaries);
        const response = await ApplicationServiceLocator.get('api').entities.savePurchaseDraft(request);

        response.isSuccess && ApplicationServiceLocator.get('uitoasts').show(new ToastDataVO({ type: TToastType.Success, message: 'Черновик извещения успешно сохранен' }));

        if (!response.isSuccess) {
            throw new Error('ATMO Exception: could not save the purchase draft.');
        }

        return response.original.response!.data;
    }

    public async savePurchase(purchase: PurchaseItemEditableVO, dictionaries: TParamDictionaries, xml: string, certificate: any) {
        const request = new PurchaseItemEditableRequestVO(purchase, dictionaries, xml, certificate);
        const response = await ApplicationServiceLocator.get('api').entities.savePurchase(request);

        response.isSuccess && ApplicationServiceLocator.get('uitoasts').show(new ToastDataVO({ type: TToastType.Success, message: 'Закупочная сессия сформирована' }));

        if (!response.isSuccess) {
            throw new Error('ATMO Exception: could not save the purchase.');
        }
    }
}
