'use strict';

import isEmpty from 'lodash.isempty';

export interface IRawSupplierAccounting {
    bank_type: string | null;
    bik: string | null;
    bank_name: string | null;
    bank_city: string | null;
    ks: string | null;
    rs: string | null;
    ls: string | null;
}

export default class SupplierAccountingVO {
    bank_type: string;
    bik: string;
    bank_name: string;
    bank_city: string;
    ks: string;
    rs: string;
    ls: string | null;

    constructor(data?: IRawSupplierAccounting) {
        this.bank_type = data?.bank_type ?? 'bank';
        this.bik = data?.bik ?? '';
        this.bank_name = data?.bank_name ?? '';
        this.bank_city = data?.bank_city ?? '';
        this.ks = data?.ks ?? '';
        this.rs = data?.rs ?? '';
        this.ls = data?.ls ?? null;
    }

    public static fromArray(data?: IRawSupplierAccounting): SupplierAccountingVO {
        if (!data) {
            return new SupplierAccountingVO();
        }
        return new SupplierAccountingVO(data);
    }

    public isValid(): boolean {
        return true;
    }

    public hasErrors(): boolean {
        return isEmpty(this.bank_type) || isEmpty(this.bik) || isEmpty(this.bank_name) || isEmpty(this.bank_city) || isEmpty(this.ks) || isEmpty(this.rs) || (this.bank_type !== 'bank' && isEmpty(this.ls));
    }

    public duplicate(): SupplierAccountingVO {
        return new SupplierAccountingVO(this);
    }
}
