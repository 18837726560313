<template>
    <purchase-filters-controller v-slot:default="pfc" v-bind:requestfilter="elpc.requestfilter" v-bind:resetfilters="elpc.routereset" v-bind:types="elpc.dictionaries.types" v-on:filters:update="elpc.processRequestUpdate">
        <div class="atmo-entity-filters">
            <form id="form-purchase-filters" class="atmo-form">
                <fieldset class="atmo-group atmo-mobile">
                    <div class="atmo-form__field">
                        <multiselect
                            id="purchase-deliverables-groups"
                            v-model="pfc.deliverablesgroups"
                            :options="elpc.dictionaries.deliverablesgroups"
                            :multiple="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="false"
                            :show-labels="false"
                            :placeholder="'Выберите предметы закупки'"
                            v-on:select="pfc.processDictionaryFilterUpdate($event, 'add', 'purchaseCategories')"
                            v-on:remove="pfc.processDictionaryFilterUpdate($event, 'remove', 'purchaseCategories')"
                            label="title"
                            track-by="id">
                            <span slot="noOptions">Нет данных.</span>
                            <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                        </multiselect>

                        <label for="purchase-deliverables-groups">Предметы закупки</label>
                    </div>

                    <!-- // DEPRECATED:; -->
                    <!-- <div class="atmo-form__field">
                        <input v-model="pfc.filters.purchaseName" type="text" id="purchase-purchaseName"
                            placeholder="Укажите предмет закупки" autocomplete="off">
                        <label for="purchase-purchaseName">Предмет закупки</label>
                    </div> -->

                    <div class="atmo-form__field">
                        <input v-model="pfc.filters.conditionname" type="text" id="purchase-conditionname" placeholder="Укажите наименование ТРУ" autocomplete="off" />
                        <label for="purchase-conditionname">Наименование ТРУ</label>
                    </div>
                </fieldset>

                <fieldset v-show="pfc.isFiltersVisible" class="atmo-group atmo-tablet">
                    <div class="atmo-form__field atmo-50">
                        <!-- <input v-model="pfc.filters.orderType" type="text" id="purchase-orderType"
                            placeholder="Укажите тип закупки" autocomplete="off"> -->

                        <dropdown-select v-slot:default="ds" v-bind:list="pfc.types" v-bind:currentid="pfc.filters.orderType" v-bind:initialid="pfc.filters.orderType" v-on:dropdown:select:selected="pfc.manageSelection">
                            <on-off v-slot:default="onoff">
                                <div class="atmo-dropdown-select">
                                    <button type="button" v-on:click.stop="onoff.toggle">
                                        <span v-if="!ds.hasSelected">Укажите тип закупки</span>
                                        <span v-if="ds.hasSelected" v-html="ds.text"></span>
                                        <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                                        <i v-else class="atmo-icon-angle-up"></i>
                                    </button>
                                    <ul v-show="onoff.ison" class="atmo-dropdown">
                                        <li v-on:click="ds.clear">Любой</li>
                                        <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                                            <span v-html="item.title"></span>
                                        </li>
                                    </ul>
                                </div>
                            </on-off>
                        </dropdown-select>

                        <label for="purchase-orderType">Тип закупки</label>
                    </div>

                    <div class="atmo-form__field">
                        <input v-model="pfc.filters.customer" type="text" id="purchase-customer" placeholder="Укажите наименование/ИНН заказчика" autocomplete="off" />
                        <label for="purchase-customer">Заказчик</label>
                    </div>

                    <div class="atmo-form__field">
                        <input v-model="pfc.filters.regNumber" type="text" id="purchase-regNumber" placeholder="Укажите номер закупки" autocomplete="off" />
                        <label for="purchase-regNumber">Регистрационный номер закупки</label>
                    </div>
                </fieldset>

                <fieldset v-show="pfc.isFiltersVisible" class="atmo-group atmo-tablet">
                    <div class="atmo-form__field">
                        <currency-input v-model="pfc.filters.priceStartFrom" currency="RUB" locale="ru" v-bind:allow-negative="false" v-bind:precision="0" type="text" id="purchase-priceStartFrom" placeholder="НМЦК от..." autocomplete="off"> </currency-input>
                        <label for="purchase-priceStartFrom">НМЦК от...</label>
                    </div>

                    <div class="atmo-form__field">
                        <currency-input v-model="pfc.filters.priceStartTo" currency="RUB" locale="ru" v-bind:allow-negative="false" v-bind:precision="0" type="text" id="purchase-priceStartTo" placeholder="НМЦК до..." autocomplete="off"> </currency-input>
                        <label for="purchase-priceStartTo">НМЦК до...</label>
                    </div>

                    <div class="atmo-form__field">
                        <!-- <input v-model="pfc.filters.orderDateStart"
                            type="text" id="purchase-orderDateStart"
                            placeholder="Укажите дату проведения с" autocomplete="off"> -->

                        <date-picker
                            v-model="pfc.filters.orderDateStart"
                            id="purchase-orderDateStart"
                            position="bottom"
                            label="Укажите дату проведения с"
                            button-now-translation="Сегодня"
                            format="YYYY-MM-DD"
                            formatted="DD.MM.YYYY"
                            v-bind:max-date="pfc.filters.orderDateFinish"
                            v-bind:no-label="!!pfc.filters.orderDateStart"
                            v-bind:auto-close="true"
                            v-bind:only-date="true">
                        </date-picker>
                        <label for="purchase-orderDateStart-input">Дата проведения с...</label>
                    </div>

                    <div class="atmo-form__field">
                        <!-- <input v-model="pfc.filters.orderDateFinish" type="text" id="purchase-orderDateFinish"
                            placeholder="Укажите дату проведения по" autocomplete="off"> -->

                        <date-picker
                            v-model="pfc.filters.orderDateFinish"
                            id="purchase-orderDateFinish"
                            position="bottom"
                            label="Укажите дату проведения по"
                            button-now-translation="Сегодня"
                            format="YYYY-MM-DD"
                            formatted="DD.MM.YYYY"
                            v-bind:min-date="pfc.filters.orderDateStart"
                            v-bind:no-label="!!pfc.filters.orderDateFinish"
                            v-bind:auto-close="true"
                            v-bind:only-date="true">
                        </date-picker>
                        <label for="purchase-orderDateFinish-input">Дата проведения по...</label>
                    </div>
                </fieldset>

                <fieldset v-show="pfc.isFiltersVisible" class="atmo-group atmo-tablet">
                    <div class="atmo-form__field">
                        <multiselect
                            id="purchase-municipalities"
                            v-model="pfc.municipalities"
                            :options="elpc.dictionaries.municipalities"
                            :multiple="true"
                            :allow-empty="true"
                            :close-on-select="true"
                            :clear-on-select="false"
                            :preserve-search="false"
                            :show-labels="false"
                            :placeholder="'Выберите муниципалитет'"
                            v-on:select="pfc.processDictionaryFilterUpdate($event, 'add', 'municipalities')"
                            v-on:remove="pfc.processDictionaryFilterUpdate($event, 'remove', 'municipalities')"
                            label="title"
                            track-by="id">
                            <span slot="noOptions">Нет данных.</span>
                            <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>
                        </multiselect>

                        <label for="purchase-municipalities">Муниципалитеты</label>
                    </div>
                </fieldset>
            </form>

            <ul class="atmo-buttons atmo-special atmo-mobile">
                <li title="Кнопка станет активна, если изменить поля фильтра. При активной кнопке можно просто нажать Enter для поиска.">
                    <button v-bind:disabled="!pfc.hasFiltersChanged || pfc.isFiltersEmpty" v-on:click="pfc.search" class="atmo-button-inverse">Найти</button>
                </li>
                <li>
                    <button v-on:click="pfc.toggleFilters" class="atmo-button-border-only">
                        <span v-if="!pfc.isFiltersVisible">Все фильтры</span>
                        <span v-if="pfc.isFiltersVisible">Меньше фильтров</span>
                    </button>
                </li>
                <li title="Кнопка станет активна, если фильтр не пустой">
                    <button v-bind:disabled="pfc.isFiltersEmpty" v-on:click="pfc.reset" class="atmo-button-border-only">Сбросить</button>
                </li>
                <li v-if="purchases_map && purchases_map_url">
                    <router-link v-bind:to="{ name: 'purchases.map' }" class="atmo-button-inverse">Карта закупок</router-link>
                    <span class="atmo-badge">Новое</span>
                </li>
            </ul>
        </div>
    </purchase-filters-controller>
</template>

<script>
import { CurrencyInput } from 'vue-currency-input';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import Config from '@core/js/ddriven/application/config/Config';
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import PurchaseFiltersController from '@core/js/viewmodels/purchases/list/view/PurchaseFiltersController.viewmodel.ts';

export default {
    props: {
        elpc: { required: true, type: Object }
    },
    components: {
        CurrencyInput,
        'date-picker': VueCtkDateTimePicker,
        'purchase-filters-controller': PurchaseFiltersController,
        'dropdown-select': DropdownSelect,
        'on-off': new OnOff()
    },
    computed: {
        purchases_map() {
            return !!Config.get('theme.features.purchases_map');
        },
        purchases_map_url() {
            return Config.get('theme.variables.links.other.purchases_map');
        }
    }
};
</script>
