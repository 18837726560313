<template>
    <div>
        <!-- // DEPRECATED: Heading already existst below;  -->
        <!-- <p class="fs-32 grey-color my-4">Спецификации</p> -->
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <h2 class="grey-color font-weight-bold my-3">Спецификация</h2>
                <div style="text-align: right">
                    <b-button @click="showSpec = true" variant="custom-unstyle-grey" style="color: var(--green-color) !important" v-if="!noAddSpec">
                        <i class="atmo-icon-attachment"></i>&ensp;
                        <!-- <img src="/images/clip_green.svg" alt="clip_green" class="mr-2" /> -->
                        Добавить позицию спецификации
                    </b-button>
                    <b-button @click="addDelivery" variant="custom-unstyle-grey" style="color: var(--green-color) !important" v-if="withAddDelivery" :disabled="hasDeliverySpec">
                        <img src="/images/delivery.svg" alt="delivery" class="mr-2" />
                        Добавить доставку
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div class="table-responsive" :class="v.$invalid || specErrors ? 'is-invalid' : ''">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="1"></th>
                                <th width="150px">Рег.номер ТРУ</th>
                                <th>Наименование товара/работы/услуги</th>
                                <th width="100px">Код ОКПД2</th>
                                <th width="300px">Описание/характеристики</th>
                                <th style="min-width: 140px">Количество</th>
                                <th width="100px" nowrap>Ед.изм</th>
                                <th v-if="withPrice" style="min-width: 140px">Цена за ед., руб.</th>
                                <th v-if="withPrice" style="min-width: 140px">Стоимость, руб.</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in specs" :key="index">
                                <td class="align-text-top">{{ item.number }}</td>
                                <td class="align-text-top">{{ item.ktru ? item.ktru.reg_number : '&mdash;' }}</td>
                                <td class="align-text-top">{{ item.name }}</td>
                                <td class="align-text-top">{{ item.okpd ? item.okpd.code : '&mdash;' }}</td>
                                <td class="align-text-top">
                                    <template v-if="item.ktru">
                                        <b-button v-b-toggle="'collapse-' + index" size="sm" variant="custom-green">{{ visible[index] ? 'Скрыть' : 'Показать' }} описание/характеристики</b-button>
                                        <b-collapse :id="'collapse-' + index" v-model="visible[index]" class="mt-2">
                                            <p v-if="item.ktru.description"><b>Описание:</b> {{ item.ktru.description }}</p>
                                            <p v-if="item.ktru.specs && item.ktru.specs.length > 0"><b>Характеристики:</b></p>
                                            <ul v-if="item.ktru.specs && item.ktru.specs.length > 0">
                                                <li v-for="(spec, specIndex) of item.ktru.specs" :key="index + '_spec_' + specIndex">
                                                    <b>{{ spec.Name }}</b> - {{ spec.Value }}
                                                </li>
                                            </ul>
                                        </b-collapse>
                                    </template>
                                    <template v-else>&mdash;</template>
                                </td>
                                <td style="text-align: right" class="align-text-top" v-if="withCountEdit">
                                    <b-input
                                        type="number"
                                        v-stop-number-mousewheel
                                        step="any"
                                        class="fs-14"
                                        style="padding-top: 0; padding-bottom: 0; height: auto"
                                        v-model.number="specs[index].count"
                                        min="1"
                                        :class="specs[index].count > 0 ? '' : 'is-invalid'"
                                        :disabled="isDeliverySpec(item)"></b-input>
                                </td>
                                <td style="text-align: right" class="align-text-top" v-else>
                                    {{ specs[index].count }}
                                </td>
                                <td class="align-text-top">{{ item.okei.local_symbol }}</td>
                                <td v-if="withPrice" class="align-text-top">
                                    <currency-input v-model="specs[index].price" class="form-control fs-14" currency="RUB" locale="ru" style="padding-top: 0; padding-bottom: 0; height: auto" :class="specs[index].price > 0 ? '' : 'is-invalid'" />
                                </td>
                                <td v-if="withPrice" style="text-align: right" class="align-text-top">
                                    {{ $formatPrice(Math.round(specs[index].price * specs[index].count * 100) / 100) }}
                                </td>
                                <td nowrap class="align-text-top">
                                    <template v-if="!noDeleteSpec || isDeliverySpec(item)">
                                        <b-button @click="removeSpec(index)" variant="light" size="sm" style="background: transparent">
                                            <img src="/images/trash_icon.svg" alt="trash_icon" />
                                        </b-button>
                                    </template>
                                </td>
                            </tr>
                            <tr v-if="specs.length === 0">
                                <td :colspan="withPrice ? 10 : 8" align="center">Не добавлено позиций спецификации</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="specErrors" class="text-danger">{{ specErrorMessage }}</p>
            </b-col>
        </b-row>
        <b-row no-gutters v-if="withPrice">
            <b-form-group label="Общая стоимость, руб." class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                <currency-input v-stop-number-mousewheel v-model="roundedSpecSum" class="form-control fs-14" currency="RUB" locale="ru" :class="!specErrors ? 'is-valid' : 'is-invalid'" disabled />
                <template v-if="specErrors">
                    <b-form-invalid-feedback>{{ specErrorMessage }}</b-form-invalid-feedback>
                </template>
            </b-form-group>
        </b-row>

        <purchase-specification-modal :show="showSpec" @cancel="cancelSpecModal" @add="addSpec" />
    </div>
</template>

<script>
import { CurrencyDirective } from 'vue-currency-input';
import Directories from '@/services/api/Directories';
import PurchaseSpecificationModal from '@/components/common/purchase-specification-modal';

export default {
    name: 'FormRowSpecificationTable',
    props: {
        value: {
            type: Array,
            required: true
        },
        grey: {
            type: Boolean,
            default: false
        },
        v: {
            type: Object,
            required: true
        },
        withPrice: {
            type: Boolean,
            default: false
        },
        withCountEdit: {
            type: Boolean,
            default: false
        },
        specSum: {
            type: Number
        },
        specErrors: {
            type: Boolean
        },
        specErrorMessage: {
            type: String,
            default: ''
        },
        noAddSpec: {
            type: Boolean,
            default: false
        },
        noDeleteSpec: {
            type: Boolean,
            default: false
        },
        withAddDelivery: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PurchaseSpecificationModal
    },
    directives: {
        currency: CurrencyDirective
    },
    data() {
        return {
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 1,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                price: 0
            },
            showSpec: false,
            tmpSpecs: [],
            visible: []
        };
    },
    methods: {
        async addDelivery() {
            const ktruItemResponse = await Directories.getKtruItem(this.$deliveryKtruItemId);
            const ktruItem = ktruItemResponse.data;
            const spec = {
                number: 1,
                order: 1,
                name: ktruItem.name,
                count: 1.0,
                okei: ktruItem.okei,
                okpd: ktruItem.okpd,
                ktru: ktruItem,
                ktru_item_id: ktruItem.id
            };
            this.addSpec(spec);
        },
        isDeliverySpec(spec) {
            return spec.ktru_item_id === this.$deliveryKtruItemId;
        },
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 1, okei: null, okpd: null, ktru_item_id: null, ktru: null, price: 0 };
            this.showSpec = false;
        },
        addSpec(spec) {
            this.tmpSpecs = this.specs;
            if (!spec.ktru_item_id) {
                this.pushToast({
                    text: 'Неверный формат спецификации',
                    title: 'Ошибка',
                    variant: 'danger'
                });
            } else {
                this.showSpec = false;
                const alreadyExists = this.specs.find((i) => i.ktru_item_id === spec.ktru_item_id);
                if (alreadyExists) {
                    return;
                }
                let newSpec = JSON.parse(JSON.stringify(spec)); // clone
                newSpec['number'] = this.tmpSpecs.length + 1;
                newSpec['order'] = this.tmpSpecs.length + 1;
                if (this.withPrice) {
                    newSpec['price'] = 0;
                }
                this.tmpSpecs.push(newSpec);
                this.specs = this.tmpSpecs;
            }
        },
        removeSpec(index) {
            this.tmpSpecs = this.specs;
            this.tmpSpecs.splice(index, 1);
            let startIndex = 1;
            this.tmpSpecs.forEach((item) => {
                item.number = startIndex;
                item.order = startIndex;
                startIndex++;
            });
            this.specs = this.tmpSpecs;
        }
    },
    computed: {
        specs: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        roundedSpecSum() {
            return Math.round(this.specSum * 100) / 100;
        },
        hasDeliverySpec() {
            return this.value.filter((e) => e.ktru_item_id === this.$deliveryKtruItemId).length > 0;
        }
    }
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
