'use strict';

import upperFirst from 'lodash.upperfirst';

import Config from '@core/js/ddriven/application/config/Config';
import HTTPRESTAPIFacade from '@core/js/ddriven/application/ports/adapters/http/outgoing/HTTPRESTAPIFacade';
import { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import CommonAPIFacadePartial from './partials/CommonAPIFacadePartial';
import SupplierAPIFacadePartial from './partials/SupplierAPIFacadePartial';
import EntitiesAPIFacadePartial from './partials/EntitiesAPIFacadePartial';
import DictionariesAPIFacadePartial from './partials/DictionariesAPIFacadePartial';
import ATMOAPIResponseNormalizedVO from './ATMOAPIResponseNormalized.valueobject';

export interface IATMOAPIConfig {
    legacy: { url: string };
    v1: { url: string };
}

export interface IATMOAPIs {
    legacy: HTTPRESTAPIFacade;
    v1: HTTPRESTAPIFacade;
}

/**
 * REFACTOR: Should be later refactored as a singeton to be accessible from any module globally.
 * Or added via some IoC harness.
 * Thus I will remove the unnecessary instance at store and the initialization
 * via store plugin and anyway a coupling with a store.
 *
 * Another refactoring has to rethink the necessity of `load` method
 * and overall scheme of how endponts are accessed (simplify).
 */
export default class ATMOAPIFacade {
    legacy: HTTPRESTAPIFacade;
    v1: HTTPRESTAPIFacade;

    common: CommonAPIFacadePartial;
    entities: EntitiesAPIFacadePartial;
    supplier: SupplierAPIFacadePartial;
    dictionaries: DictionariesAPIFacadePartial;

    constructor(apiConfig: IATMOAPIConfig, customHeaders?: Record<string, string | undefined>) {
        const headers = { ...(customHeaders || {}) };

        this.legacy = new HTTPRESTAPIFacade(apiConfig.legacy.url, headers);
        this.v1 = new HTTPRESTAPIFacade(apiConfig.v1.url, headers);

        this.common = new CommonAPIFacadePartial(this);
        this.entities = new EntitiesAPIFacadePartial(this.legacy, this);
        this.supplier = new SupplierAPIFacadePartial(this.legacy, this);

        this.dictionaries = new DictionariesAPIFacadePartial({ legacy: this.legacy, v1: this.v1 } as IATMOAPIs);
    }

    /**
     * REFACTOR: Left the method here for a convinient loading data for
     * different entities (pages).
     *
     * So far no clear decision on how / if it could be refactored away.
     */
    async load(command: string, payload?: unknown): Promise<AxiosResponse> {
        const method = `load${this.getMethod(command)}`;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (typeof this.entities[method] !== 'function') {
            throw new Error(`ATMO Exception: the method ${method} does not exist on the object ${this.constructor.name}.`);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return await this.entities[method](payload);
    }

    public async unifiedResponseCall(apiInstance: HTTPRESTAPIFacade, verb: Method, path: string, payload?: Record<string, unknown>, headers?: Record<string, unknown>, customConfig: AxiosRequestConfig = {}): Promise<ATMOAPIResponseNormalizedVO> {
        try {
            const response = await apiInstance.call(verb, path, payload, headers, customConfig);
            return ATMOAPIResponseNormalizedVO.fromAxiosResponse(response);
        } catch (error) {
            return ATMOAPIResponseNormalizedVO.fromAxiosResponse((error as AxiosError).response);
        }
    }

    /**
     * NB: Same as load() above.
     */
    private getMethod(command: string): string {
        const temp = command.replace(/(:[A-Za-z])/, (match) => {
            return match.replace(':', '').toUpperCase();
        });

        return upperFirst(temp);
    }
}
