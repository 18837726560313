'use strict';

export default class SignatureException extends Error {
    constructor(message, code = 90099) {
        super(message);

        this.name = 'SignatureException';
        this.code = code;
    }
}
