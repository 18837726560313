var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        visible: _vm.show,
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        size: "xl",
        title: "Спецификация",
      },
      on: { cancel: _vm.cancelSpecModal, close: _vm.cancelSpecModal },
    },
    [
      _c(
        "b-row",
        [
          _c("filter-text", {
            attrs: { label: "Рег.номер", placeholder: "Введите рег.номер" },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.reg_number,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "reg_number", $$v)
              },
              expression: "ktruTableFilter.reg_number",
            },
          }),
          _c("filter-text", {
            attrs: { label: "Название", placeholder: "Введите название" },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.name,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "name", $$v)
              },
              expression: "ktruTableFilter.name",
            },
          }),
          _c("filter-text", {
            attrs: {
              label: "Характеристики",
              placeholder: "Введите текст характеристик",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.specs,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "specs", $$v)
              },
              expression: "ktruTableFilter.specs",
            },
          }),
          _c("filter-text", {
            attrs: {
              label: "Код ОКПД2",
              placeholder: "Введите код ОКПД2 или его часть",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.okpd_code,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "okpd_code", $$v)
              },
              expression: "ktruTableFilter.okpd_code",
            },
          }),
          _c("filter-multiple-select-for-popup", {
            attrs: {
              options: _vm.forFilters.countries,
              label: "Страна происхождения",
              placeholder: "Выберите страну происхождения",
            },
            on: { input: _vm.onKtruFilterChange },
            model: {
              value: _vm.ktruTableFilter.country_of_origin_id,
              callback: function ($$v) {
                _vm.$set(_vm.ktruTableFilter, "country_of_origin_id", $$v)
              },
              expression: "ktruTableFilter.country_of_origin_id",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("b-table-simple", { staticClass: "table-atmo" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticStyle: { width: "120px" } }, [
                  _vm._v("Рег.номер"),
                ]),
                _c("th", { staticStyle: { width: "120px" } }, [
                  _vm._v("ОКПД2"),
                ]),
                _c(
                  "th",
                  { staticStyle: { "min-width": "400px", width: "400px" } },
                  [_vm._v("Название")]
                ),
                _c("th", { staticStyle: { "min-width": "300px" } }, [
                  _vm._v("Описание/характеристики"),
                ]),
                _c("th", { staticStyle: { "min-width": "100px" } }, [
                  _vm._v("Ед.изм"),
                ]),
                _c("th", { staticStyle: { "min-width": "100px" } }, [
                  _vm._v("Страна происхождения"),
                ]),
                _c("th", { staticStyle: { width: "100px" } }, [
                  _vm._v("Действия"),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.ktruItems, function (item) {
                  return _c("tr", { key: item.id }, [
                    _c("td", [_vm._v(_vm._s(item.reg_number))]),
                    _c("td", [_vm._v(_vm._s(item.okpd_code))]),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c(
                      "td",
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "collapse-" + item.id,
                                expression: "'collapse-' + item.id",
                              },
                            ],
                            attrs: { size: "sm", variant: "custom-green" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.visible[item.id] ? "Скрыть" : "Показать"
                              ) + " описание/характеристики"
                            ),
                          ]
                        ),
                        _c(
                          "b-collapse",
                          {
                            staticClass: "mt-2",
                            attrs: { id: "collapse-" + item.id },
                            model: {
                              value: _vm.visible[item.id],
                              callback: function ($$v) {
                                _vm.$set(_vm.visible, item.id, $$v)
                              },
                              expression: "visible[item.id]",
                            },
                          },
                          [
                            item.description
                              ? _c("p", [
                                  _c("b", [_vm._v("Описание:")]),
                                  _vm._v(" " + _vm._s(item.description)),
                                ])
                              : _vm._e(),
                            item.specs && item.specs.length > 0
                              ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                              : _vm._e(),
                            item.specs && item.specs.length > 0
                              ? _c(
                                  "ul",
                                  _vm._l(item.specs, function (spec, key) {
                                    return _c("li", { key: "spec" + key }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-weight": "900" },
                                        },
                                        [_vm._v(_vm._s(spec.Name))]
                                      ),
                                      _vm._v(": " + _vm._s(spec.Value) + " "),
                                    ])
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(item.okei_local_symbol))]),
                    _c("td", [_vm._v(_vm._s(item.country_of_origin_name))]),
                    _c(
                      "td",
                      [
                        _c(
                          "b-btn",
                          {
                            attrs: { size: "sm", variant: "custom-green" },
                            on: {
                              click: function ($event) {
                                return _vm.selectKtruItem(item)
                              },
                            },
                          },
                          [_vm._v("Выбрать")]
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                _vm.ktruItems.length === 0
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-center text-danger",
                          attrs: { colspan: "7" },
                        },
                        [
                          _vm._v(
                            " Позиции КТРУ по вашему запросу не найдены. Вы можете "
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { name: "KtruRequestsCreate" },
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "сформировать запрос на добавление позиции КТРУ"
                              ),
                            ]
                          ),
                          _vm._v(" . "),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _vm.ktruItems.length
        ? _c("b-pagination", {
            staticStyle: { "padding-bottom": "0" },
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getKtruItems()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }