<template>
    <section class="atmo-address-book atmo-dropdown-select">
        <dropdown-select v-slot:default="ds" v-bind:list="this.$props.controller.$props.useraddressbook" v-on:dropdown:select:selected="this.$props.controller.add">
            <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                <div class="atmo-dropdown-select">
                    <button v-bind:disabled="isEmpty" type="button" class="atmo-button" v-on:click="onoff.toggle">
                        <span>Адресная книга&nbsp;</span>
                        <span v-if="isEmpty">(пусто)</span>
                        <i v-if="!onoff.ison" class="atmo-icon-angle-down"></i>
                        <i v-else class="atmo-icon-angle-up"></i>
                    </button>

                    <ul v-show="onoff.ison" v-on:click="onoff.off" class="atmo-dropdown">
                        <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id)">
                            <span v-html="item.title"></span>
                        </li>
                    </ul>
                </div>
            </on-off>
        </dropdown-select>
    </section>
</template>

<script>
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

export default {
    props: {
        controller: { required: true, type: Object }
    },
    components: {
        'dropdown-select': DropdownSelect,
        'on-off': new OnOff()
    },
    computed: {
        isEmpty: function () {
            return this.$props.controller.$props.useraddressbook.length < 1;
        }
    }
};
</script>
