'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { cloneDeep } from 'lodash';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import UserVO from '@core/js/ddriven/domain/model/users/User.valueobject';
import { Command, IFileAttachmentVMEvent } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import PurchaseItemEditableVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditable.valueobject';
import StandardDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/StandardDictionaryItem.valueobject';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import DeliverableItemVO from '@core/js/ddriven/domain/model/common/deliverable/DeliverableItem.valueobject';
import PurchaseItemEditableValidationErrorsVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditableValidationErrors.valueobject';
import IOnOffOnToggleEventPayload from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import PurchaseItemEditablePartialDecodedVO from '@core/js/ddriven/domain/model/purchases/update/PurchaseItemEditablePartialDecoded.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import SupplierRequirementSupportingDocumentsController from './SupplierRequirementSupportingDocumentsController.viewmodel';
import SRDA31s1p1SupportingDocumentVO from '@core/js/ddriven/domain/model/purchases/update/supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import SavePurchasePopupController from './SavePurchasePopupController.viewmodel';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';
import Config from '@core/js/ddriven/application/config/Config';

export enum VMOperations {
    Create = 'create',
    Edit = 'edit'
}

interface IData {
    inputs: PurchaseItemEditableVO | null;
    errors: PurchaseItemEditableValidationErrorsVO | null;
    visibleTab: string;
}

/**
 * The controller combines both create and edit behaviours.
 */

@Component
export default class PurchaseItemUpdateController extends BaseViewModel {
    @Prop({ required: true, type: String }) readonly group!: string;
    @Prop() readonly id!: string;

    constructor() {
        super();
        this.name = 'PurchaseItemUpdateController';
    }

    async created() {
        this.$data.inputs = cloneDeep(this.item);
        this.$data.errors = this.validate(this.$data.inputs);
        this.$root.$on('deliverableslist:updated', this.setDeliverables);
        this.$root.$on(SupplierRequirementSupportingDocumentsController.updateEvent, this.updateSupplierRequirementSupportingDocuments);
    }

    data(): IData {
        return {
            inputs: null,
            errors: null,
            visibleTab: 'terms'
        };
    }

    /**
     * Computed
     */
    get features(): Record<string, boolean> | boolean {
        return Config.get('theme.features');
    }

    get item(): PurchaseItemEditableVO {
        return this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/itemeditable`];
    }

    get vmOperation(): VMOperations {
        if (!this.$route.name) {
            throw new Error('ATMO Exception: route name must be defined.');
        }

        return this.$route.name?.includes(VMOperations.Create) ? VMOperations.Create : VMOperations.Edit;
    }

    get isRevokedDraftEdit(): boolean {
        return this.vmOperation === VMOperations.Edit && this.$data.inputs.is_revoked;
    }

    get isUrgentPurchase(): boolean {
        return this.item.isUrgentPurchase(this.$data.inputs);
    }

    get user(): UserVO {
        return this.$store.getters['rearchitected/users/user'];
    }

    get dictionaries(): Record<string, any> {
        return {
            types: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/types`],
            durations: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/durations`],
            urgencyreasons: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/urgencyreasons`],
            deliverablesgroups: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/deliverablesgroups`],
            limityears: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/limityears`],
            useraddressbook: this.$store.getters[`rearchitected/groups/${this.$props.group}/purchases/dictionaries/useraddressbook`]
        };
    }

    get tabbedErrorCounts(): Record<string, number> {
        return this.$data.errors ? this.$data.errors.tabbedErrorCounts() : {};
    }

    get hasErrors(): boolean {
        return this.$data.errors ? this.$data.errors.hasErrors() : false;
    }

    get hasErrorsWithoutContractDraftAttachment(): boolean {
        return this.$data.errors ? this.$data.errors.hasErrorsWithoutContractDraftAttachment() : false;
    }

    get initialLimitYearId(): number {
        const found = this.dictionaries.limityears.find((item: ExtendedDictionaryItem) => {
            return item.value === this.item.limit_year;
        });
        return found?.id ?? this.defaultLimitYear(this.dictionaries.limityears);
    }

    get withoutLimits(): boolean {
        return this.$store.state.fl44_without_limits ?? false;
    }

    /**
     * Watch
     */
    @Debounce(50)
    @Watch('inputs', { deep: true, immediate: true })
    async onInputsChanged(item: PurchaseItemEditableVO) {
        this.$data.errors = this.validate(item);
    }

    /**
     * Methods
     */
    public fileAttachmentEventHandler(event: IFileAttachmentVMEvent): void {
        this.$data.inputs.updateFileAttachment(event);
    }

    public updateUrgencyReasonFileAttachment(event: IFileAttachmentVMEvent): void {
        this.$data.inputs.urgency_reason.attachment = event.command === Command.Add ? event.attachment : null;
    }

    public deliverablesGroupsSelectHandler(selectedGroup: StandardDictionaryItem): void {
        this.$data.inputs.deliverable_group_id = selectedGroup.id;
    }

    public deliverablesGroupsRemoveHandler(): void {
        this.$data.inputs.deliverable_group_id = null;
    }

    public addToAddressesList(payload: IViewmodelEventPayload): void {
        this.$data.inputs.delivery_addresses.push(payload.item!.description);
    }

    public removeFromAddressesList(index: number): void {
        this.$data.inputs.delivery_addresses.splice(index, 1);
    }

    public async invokeSavePurchasePopup() {
        await this.saveDraft();

        const payload: IOnOffOnToggleEventPayload = {
            id: SavePurchasePopupController.popupId,
            ison: true,
            data: {
                user: this.user,
                inputs: this.$data.inputs,
                decoded: new PurchaseItemEditablePartialDecodedVO(this.$data.inputs, this.dictionaries),
                durations: this.dictionaries.durations,
                urgencyreasons: this.dictionaries.urgencyreasons
            }
        };
        this.$root.$emit('public:onoff:toggle', payload);
    }

    public async saveDraft() {
        console.log(this.$data.inputs.contract_template_params);
        this.$data.inputs.id = (
            await ApplicationServiceLocator.get('purchaseItemEditableService').saveDraft(this.$data.inputs, {
                durations: this.dictionaries.durations,
                urgencyreasons: this.dictionaries.urgencyreasons
            })
        ).id;
        if (this.vmOperation === VMOperations.Create) {
            await this.$router.push({ name: 'purchases.grouped.item.edit', params: { group: this.$route.params.group, id: this.$data.inputs.id } });
        }
    }

    // REFACTOR: Remove and replace with the wrapping tabs-menu viewmodel (already written)
    // outside of this VM;
    public setTab(tabCode: string): void {
        window.scrollTo(0, 0);
        this.$data.visibleTab = tabCode;
    }

    /**
     * Prototype general methods
     */
    private setDeliverables(deliverables: DeliverableItemVO[]): void {
        this.$data.inputs.specification.deliverables = deliverables;
        this.$data.inputs.starting_price = deliverables.reduce((accumulator: number, deliverable: DeliverableItemVO) => {
            accumulator = accumulator + (deliverable?.price_total ?? 0);
            return accumulator;
        }, 0);
    }

    private updateSupplierRequirementSupportingDocuments(documents: SRDA31s1p1SupportingDocumentVO[]): void {
        this.$data.inputs.supplier_requirements.a31s1p1_details.updateSupportingDocuments(documents);
    }

    private validate(item: PurchaseItemEditableVO): PurchaseItemEditableValidationErrorsVO {
        return Object.freeze(PurchaseItemEditableValidationErrorsVO.validate(item, this.withoutLimits)) as PurchaseItemEditableValidationErrorsVO;
    }

    private defaultLimitYear(limitYears: ExtendedDictionaryItem[]): number {
        const currentYear = new Date().getFullYear();
        const found = limitYears.find((limitYear: ExtendedDictionaryItem) => {
            return limitYear.value === currentYear;
        });
        return found?.id ?? limitYears[0].id ?? 1;
    }
}
