'use strict';

import FormatNumber from '@lib/js/src/misc/FormatNumber';
import Chartist from 'chartist';
import 'chartist-plugin-axistitle';
import 'chartist-plugin-pointlabels';

const options: Chartist.ILineChartOptions = {
    low: 0,
    high: 100,
    // onlyInteger: true,
    // scaleMinSpace: 100,
    // divisor: 4,
    height: '320px',
    lineSmooth: false,
    chartPadding: {
        bottom: 40,
        left: 40
    },
    axisX: {
        labelOffset: {
            x: -40
        }
    },
    axisY: {
        onlyInteger: true
    },
    plugins: [
        Chartist.plugins.ctPointLabels({
            labelClass: 'ct-label ct-value-label',
            labelOffset: {
                x: 50,
                y: 5
            },
            textAnchor: 'middle',
            labelInterpolationFnc: function (value: any) {
                return FormatNumber.formatNumber(value, FormatNumber.configs.default);
            }
        }),
        Chartist.plugins.ctAxisTitle({
            axisX: {
                axisTitle: 'Дата предложения',
                axisClass: 'ct-axis-title',
                offset: {
                    x: -50,
                    y: 50
                },
                textAnchor: 'middle'
            },
            axisY: {
                axisTitle: 'Цена, руб.',
                axisClass: 'ct-axis-title',
                offset: {
                    x: 20,
                    y: 20
                },
                textAnchor: 'middle',
                flipTitle: true
            }
        })
    ]
};

export default options;
