<template>
    <div id="atmo-supplier-info" class="atmo-hash-jump-point atmo-content-group">
        <h3>Информация о поставщике (исполнителе)</h3>

        <ul v-if="!this.$props.purchaseitem.is_individual_supplier" class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Наименование:</dt>
                <dd><a v-bind:href="`/clients/providers/${this.$props.supplier.id}`" v-text="this.$props.supplier.title"></a></dd>
            </li>
            <li>
                <dt>ИНН:</dt>
                <dd v-text="this.$props.supplier.inn"></dd>
            </li>
            <li v-if="this.$props.supplier.kpp">
                <dt>КПП:</dt>
                <dd v-text="this.$props.supplier.kpp"></dd>
            </li>
            <li>
                <dt>Адрес:</dt>
                <dd v-text="this.$props.supplier.address"></dd>
            </li>
        </ul>

        <ul v-else class="atmo-content-block-2-columns atmo-no-item-separator">
            <li>
                <dt>Наименование:</dt>
                <dd>Физическое лицо</dd>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        purchaseitem: { required: true, type: Object },
        supplier: { required: true, type: Object }
    }
};
</script>
