var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-modal",
        {
          ref: "login-modal",
          attrs: {
            size: "lg",
            centered: "",
            "hide-header-close": _vm.hideHeaderClose,
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "header-class": _vm.hideHeaderClose ? "pl-0 pb-0" : "pb-0",
            "body-class": "pt-0",
            "title-class": "fs-28 pl-4",
            title: "Вход в личный кабинет",
          },
          on: {
            show: _vm.onShow,
            close: function ($event) {
              $event.preventDefault()
              return _vm.onClose.apply(null, arguments)
            },
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isLoading,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c("b-form-group", {
                    staticClass: "fs-18 py-0 fw-600",
                    attrs: { label: "Выберите вариант авторизации" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ ariaDescribedby }) {
                          return [
                            _c(
                              "b-form-radio-group",
                              {
                                staticClass: "fw-400",
                                attrs: { "aria-describedby": ariaDescribedby },
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              },
                              [
                                _c(
                                  "b-form-radio",
                                  { attrs: { value: "certificate" } },
                                  [_vm._v("ЭЦП")]
                                ),
                                _c(
                                  "b-form-radio",
                                  { attrs: { value: "login" } },
                                  [_vm._v("Логин/пароль")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("hr", { staticClass: "my-0" }),
            ],
            1
          ),
          _vm.selected === "certificate"
            ? _c("LoginWithCertificate", {
                on: {
                  close: _vm.onClose,
                  loadStart: _vm.onLoadStart,
                  loadEnd: _vm.onLoadEnd,
                  showClose: function ($event) {
                    _vm.hideHeaderClose = false
                  },
                  hideClose: function ($event) {
                    _vm.hideHeaderClose = true
                  },
                },
              })
            : _vm.selected === "login"
            ? _c("LoginWithPassword", {
                on: {
                  close: _vm.onClose,
                  loadStart: _vm.onLoadStart,
                  loadEnd: _vm.onLoadEnd,
                  hideClose: function ($event) {
                    _vm.hideHeaderClose = true
                  },
                },
              })
            : _c("LoginInstruction", { on: { close: _vm.onClose } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }