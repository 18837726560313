export default [
    {
        path: '/articles',
        component: () => import(/* webpackChunkName: "main" */ '@/components/articles/ArticlesList'),
        name: 'ArticlesList',
        meta: { name: 'Новости' }
    },
    {
        path: '/articles/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/articles/Article'),
        name: 'Article',
        meta: { name: 'Новость' }
    },
    {
        path: '/information',
        component: () => import(/* webpackChunkName: "main" */ '@/components/information/Information'),
        name: 'Information',
        redirect: { name: 'InformationSection', params: { id: 1 } },
        meta: { name: 'Информационные материалы' },
        children: [
            {
                path: ':id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/information/InformationSection'),
                name: 'InformationSection'
            }
        ]
    }
];
