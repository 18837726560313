var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticStyle: { "word-break": "break-all" },
      attrs: {
        label: _vm.title + ":",
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
        "label-cols-lg": _vm.labelColsLg,
        "label-cols-sm": _vm.labelColsSm,
      },
    },
    [
      !!_vm.document
        ? [
            _c(
              "a",
              {
                staticClass: "fs-14 col-form-label d-inline-block",
                attrs: {
                  href: _vm.$getAttachmentDownloadLink(_vm.document.id),
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.document.name))]
            ),
            _c(
              "b-button",
              {
                staticClass: "float-right",
                staticStyle: { background: "transparent" },
                attrs: { disabled: _vm.disabled, size: "sm", variant: "light" },
                on: {
                  click: function ($event) {
                    return _vm.removeFile(0, true)
                  },
                },
              },
              [
                _c("img", {
                  attrs: { alt: "trash_icon", src: "/images/trash_icon.svg" },
                }),
              ]
            ),
          ]
        : [
            _c(
              "p",
              {
                staticClass: "col-form-label d-inline-block",
                class: !_vm.state ? "text-danger" : "",
                staticStyle: { "font-size": "80%" },
              },
              [_vm._v("Прикрепите документ")]
            ),
            _c(
              "file-uploader",
              {
                staticClass: "d-inline-block float-right",
                attrs: {
                  "is-disabled": !!_vm.document || _vm.disabled,
                  "is-main": true,
                },
                on: { set_file: _vm.addFile },
              },
              [_vm._v("Загрузить")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }