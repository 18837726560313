var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "atmo-component-warpper" }, [
    _c(
      "ul",
      {
        staticClass: "atmo-entity-item-title atmo-icon-custom",
        class: _vm.pi.getStatusClass("atmo-entity-status-"),
      },
      [
        _c(
          "li",
          {
            staticClass: "pr-4",
            staticStyle: {
              "white-space": "nowrap",
              overflow: "hidden",
              "text-overflow": "ellipsis",
            },
          },
          [
            _c(
              "a",
              {
                attrs: {
                  href: `/purchases/grouped/${_vm.pi.elpc.group}/item/${_vm.pi.purchaseitem.id}/view`,
                  target: "_blank",
                },
              },
              [
                _vm._v("№ "),
                _c("span", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.pi.purchaseitem.registration_number
                    ),
                  },
                }),
              ]
            ),
            _vm.pi.purchaseitem.purchase_object
              ? [
                  _vm._v(
                    ' На закупку "' +
                      _vm._s(_vm.pi.purchaseitem.purchase_object) +
                      '"'
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _c("li", { staticClass: "text-nowrap" }, [
          _c("span", {
            staticClass: "text-uppercase",
            domProps: { textContent: _vm._s(_vm.pi.status.title) },
          }),
        ]),
      ]
    ),
    _c("ul", { staticClass: "atmo-entity-item-summary" }, [
      _c("li", [
        _vm._m(0),
        _c("dd", [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("formatnumber")(_vm.pi.purchaseitem.starting_price))
            ),
          ]),
          _vm._v(" руб. "),
        ]),
      ]),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Предложений: ")]),
            _c("dd", [
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.pi.purchaseitem.proposals_count),
                },
              }),
            ]),
          ])
        : _vm._e(),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Лучшее: ")]),
            !_vm.pi.purchaseitem.is_best_proposal_hidden
              ? _c("dd", [
                  _vm.pi.purchaseitem.best_proposal_price > 0
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatnumber")(
                              _vm.pi.purchaseitem.best_proposal_price
                            )
                          ) + " руб."
                        ),
                      ])
                    : _c("span", [_vm._v("-")]),
                ])
              : _c("dd", [_vm._v("Скрыто")]),
          ])
        : _vm._e(),
      _vm.pi.hasAllSummaryItemsVisible
        ? _c("li", [
            _c("dt", [_vm._v("Снижение: ")]),
            _c("dd", [
              _vm.pi.purchaseitem.is_best_proposal_hidden
                ? _c("span", [_vm._v("Скрыто")])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.pi.purchaseitem.best_proposal_discount_percent
                      ) + "%"
                    ),
                  ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("ul", { staticClass: "atmo-entity-item-details" }, [
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _vm.pi.status.code === "accepting"
            ? _c("li", [
                _c("section", [
                  _vm.pi.countdown
                    ? _c("span", [
                        _c("dt", [_vm._v("До окончания приема предложений:")]),
                        _c("dd", {
                          domProps: { textContent: _vm._s(_vm.pi.countdown) },
                        }),
                      ])
                    : _vm._e(),
                  _vm.pi.countdown === false
                    ? _c("dt", [_vm._v("Прием предложений завершен")])
                    : _vm._e(),
                ]),
                _vm.pi.countdown &&
                _vm.pi.user &&
                _vm.pi.user.is_logged_in &&
                _vm.pi.user.is_supplier
                  ? _c("section", [
                      _vm.pi.purchaseitem
                        .has_proposal_from_loggedin_organization
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "atmo-button--small atmo-accented-mid",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.pi.submitOrUpdateProposal.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Изменить предложение")]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "atmo-button--small",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.pi.submitOrUpdateProposal.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Подать предложение")]
                          ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.pi.status.code === "summarizing"
            ? _c("li", [_vm._v("Прием предложений завершен")])
            : _vm._e(),
          _vm.pi.status.code === "summarized"
            ? _c("li", [
                _c("dt", [_vm._v("Подведены итоги:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.pi.purchaseitem.results_protocol_date,
                        "removeSeconds"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.pi.status.code === "failed"
            ? _c("li", [
                _c("dt", [_vm._v("Подведены итоги:")]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.pi.purchaseitem.proposal_accept_end_date,
                        "removeSeconds"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _c("li", [
            _c("dt", [_vm._v("Предмет закупки:")]),
            _c("dd", {
              domProps: {
                textContent: _vm._s(_vm.pi.purchaseitem.description),
              },
            }),
          ]),
          _c("li", [
            _c("dt", [_vm._v("Организация:")]),
            _c("dd", [
              _c("a", {
                attrs: {
                  href:
                    "/clients/customers/" +
                    _vm.pi.purchaseitem.customer_organization_id,
                  target: "_blank",
                },
                domProps: {
                  textContent: _vm._s(
                    _vm.pi.purchaseitem.customer_organization_title
                  ),
                },
              }),
            ]),
          ]),
          _vm.pi.hasSupplier
            ? _c("li", [
                _c("dt", [_vm._v("Поставщик:")]),
                _c("dd", [
                  _c("a", {
                    attrs: {
                      href:
                        "/clients/providers/" +
                        _vm.pi.purchaseitem.supplier_organization_id,
                      target: "_blank",
                    },
                    domProps: {
                      textContent: _vm._s(
                        _vm.pi.purchaseitem.supplier_organization_title
                      ),
                    },
                  }),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column" }, [
        _c("ul", [
          _c("li", [
            _c("dt", [_vm._v("Планируемая дата исполнения:")]),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm._f("formatdate")(
                    _vm.pi.purchaseitem.planned_delivery_date,
                    "onlyDate"
                  )
                )
              ),
            ]),
          ]),
          _c("li", [
            _vm._v("Количество позиций: "),
            _c("b", {
              domProps: {
                textContent: _vm._s(_vm.pi.purchaseitem.items_count),
              },
            }),
          ]),
          _vm.pi.hasDeliveryAddresses
            ? _c(
                "li",
                [
                  _c("dt", [
                    _vm._v("Место доставки или указание на самовывоз:"),
                  ]),
                  _vm._l(
                    _vm.pi.purchaseitem.delivery_addresses,
                    function (addressobj, index) {
                      return _c("dd", {
                        key: index,
                        domProps: { textContent: _vm._s(addressobj.address) },
                      })
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
      _c("li", { staticClass: "atmo-column atmo-special-style" }, [
        _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: `/purchases/grouped/${_vm.pi.elpc.group}/item/${_vm.pi.purchaseitem.id}/view`,
                  target: "_blank",
                },
              },
              [
                _c("i", { staticClass: "atmo-icon-search" }),
                _c("span", [_vm._v("Информация о закупке")]),
              ]
            ),
          ]),
          _vm.pi.purchaseitem.hasProposals()
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.pi.elpc.group}/item/${_vm.pi.purchaseitem.id}/view#atmo-proposals`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-dynamic" }),
                    _c("span", [_vm._v("Динамика предложений")]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.pi.hasProtocol
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.pi.elpc.group}/item/${_vm.pi.purchaseitem.id}/view#atmo-supplier-info`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-shield" }),
                    _c("span", [_vm._v("Итоговый протокол")]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.pi.hasContract
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: `/purchases/grouped/${_vm.pi.elpc.group}/item/${_vm.pi.purchaseitem.id}/view#atmo-contract`,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "atmo-icon-contract" }),
                    _c("span", [_vm._v("Договор")]),
                  ]
                ),
              ])
            : _vm._e(),
          _c(
            "li",
            [
              _c("DealStageActionButton", {
                attrs: {
                  group: _vm.pi.elpc.group,
                  buttondata: _vm.pi.buttondata,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _c(
        "abbr",
        { attrs: { title: "Начальная максимальная цена контракта" } },
        [_vm._v("НМЦК")]
      ),
      _vm._v(":"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }