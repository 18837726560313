import store from '@/store';
import CadesMixins from '@/mixins/CadesMixins';
import Signature from '@/services/api/Signature';
import axios from 'axios';
import { BaseFz223ApiService } from '@/services/fz223/_base';

export class Fz223Purchases extends BaseFz223ApiService {
    constructor() {
        super('fz223');
    }

    async SignXML(xml) {
        if (store.state.canAsync) {
            const signedXml = await CadesMixins.methods.signXml(xml, store.state.certificates[store.state.currentCertificateIndex]);
            if (!signedXml) {
                return null;
            }
            const xmlVerified = await CadesMixins.methods.verifyXml(signedXml);
            if (!xmlVerified) {
                // TODO: Если подпись невалидна - отобразить сообщение
                this.pushToast({
                    text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                    title: 'Ошибка подписания документа',
                    variant: 'danger',
                    timer: 10000
                });
                return null;
            }
            const xmlVerifiedByServer = await Signature.validateSignedXml(signedXml);
            if (!xmlVerifiedByServer) {
                // TODO: Если подпись невалидна - отобразить сообщение
                this.pushToast({
                    text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                    title: 'Ошибка подписания документа',
                    variant: 'danger',
                    timer: 10000
                });
                return null;
            }
            return signedXml;
        } else {
            const signedXml = CadesMixins.methods.signXmlNPAPI(xml, store.state.certificates[store.state.currentCertificateIndex]);
            const xmlVerifiedByServer = await Signature.validateSignedXml(signedXml);
            if (!xmlVerifiedByServer) {
                // TODO: Если подпись невалидна - отобразить сообщение
                this.pushToast({
                    text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                    title: 'Ошибка подписания документа',
                    variant: 'danger',
                    timer: 10000
                });
                return null;
            }
            return signedXml;
        }
    }

    async getList(paginationInfo) {
        const url = `/api/v1/fz223/cabinet/purchases?page=${paginationInfo.current_page}`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async get(id) {
        const url = `/api/v1/fz223/purchases/${id}`;
        try {
            const response = await axios.get(url);
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getDraft(id) {
        const url = `/api/v1/fz223/purchases/${id}/draft`;
        try {
            const response = await axios.get(url);
            return response.data.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async getXml(id) {
        const url = `/api/v1/fz223/purchases/${id}/xml`;
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async saveDraft(payload) {
        const url = '/api/v1/fz223/purchases';
        try {
            const response = await axios.post(url, payload);
            await store.dispatch('showSuccessToast', 'Черновик извещения успешно сохранен');
            return response.data.data.id;
        } catch (err) {
            this.handleErrors(err);
        }
    }

    async store(id, xml) {
        const url = `/api/v1/fz223/purchases/${id}`;
        try {
            const response = await axios.put(url, {
                xml: xml,
                thumbprint: store.state.currentThumbprint,
                certificate: store.getters.currentParsedCertificate
            });
            await store.dispatch('showSuccessToast', 'Закупочная сессия сформирована');
            return response.data.data.id;
        } catch (err) {
            this.handleErrors(err);
        }
    }
}
