<template>
    <ul class="atmo-form atmo-filters">
        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="gsc-details-registration-number">Регистрационный номер</label>
                <input v-model="$props.controller.request.filter.reg_number" type="text" id="gsc-details-registration-number" placeholder="Введите регистрационный номер позиции КТРУ" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>

        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="deliverable-item-title">Наименование товара (работ, услуг)</label>
                <input v-model="$props.controller.request.filter.name" type="text" id="deliverable-item-title" placeholder="Введите наименование или его часть" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>

        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="deliverable-item-requirements">Характеристики</label>
                <input v-model="$props.controller.request.filter.specs" type="text" id="deliverable-item-requirements" placeholder="Введите характеристики" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>

        <clearable-input v-slot:default="ci">
            <li class="atmo-form__field atmo-inverse">
                <label for="deliverable-item-okpd-code">Код ОКПД2</label>
                <input v-model="$props.controller.request.filter.okpd_code" type="text" id="deliverable-item-okpd-code" placeholder="Введите код ОКПД2 или его часть" autocomplete="off" />
                <button v-on:click="ci.clear" tabindex="-1" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
            </li>
        </clearable-input>
    </ul>
</template>

<script>
import ClearableInput from '@lib/js/src/vue/components/ClearableInput.viewmodel';

export default {
    props: {
        controller: { required: true, type: Object }
    },
    components: {
        'clearable-input': ClearableInput
    }
};
</script>
