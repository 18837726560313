<template>
    <dadata-suggestions-controller v-slot:default="dsc" v-on:address:add="$props.controller.$props.addhandler">
        <section class="atmo-widget-address-autocomplete">
            <p v-text="dsc.value"></p>

            <div class="atmo-input-group">
                <div class="atmo-form__field atmo-inverse">
                    <input ref="input" v-model="dsc.input" v-on:input="dsc.requestSuggestions" v-on:focus="dsc.updateFocus" v-on:blur="dsc.updateFocus" type="text" placeholder="Начните вводить адрес" />

                    <dropdown-select v-slot:default="ds" v-bind:list="dsc.list" v-on:dropdown:select:selected="dsc.selected">
                        <on-off v-slot:default="onoff" v-bind:options="{ onBody: 'intelligent' }">
                            <div class="atmo-dropdown-select">
                                <ul v-show="!dsc.islistempty && dsc.isfocus" class="atmo-dropdown">
                                    <li v-for="item in ds.list" v-bind:key="item.id" v-on:click="ds.select(item.id), onoff.off()">
                                        <span v-html="item.title"></span>
                                    </li>
                                </ul>
                            </div>
                        </on-off>
                    </dropdown-select>

                    <button v-on:click="dsc.clear" v-bind:disabled="dsc.isinputempty" class="atmo-clear" title="Очистить поле ввода"><i class="atmo-icon-close"></i></button>
                </div>

                <button v-bind:disabled="dsc.isinputempty" v-on:click="dsc.add" class="atmo-button" title="Добавить адрес в список адресов">Добавить</button>
            </div>
        </section>
    </dadata-suggestions-controller>
</template>

<script>
import DadataSuggestionsController from '@core/js/viewmodels/common/address/DadataSuggestionsController.viewmodel';
import DropdownSelect from '@lib/js/src/vue/components/DropdownSelect.viewmodel.ts';
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';

export default {
    props: {
        controller: { required: true, type: Object }
    },
    components: {
        'dadata-suggestions-controller': DadataSuggestionsController,
        'dropdown-select': DropdownSelect,
        'on-off': new OnOff()
    }
};
</script>
