export default [
    //{
    //    path: '/auth/cryptopro_error',
    //    component: () => import(/* webpackChunkName: "main" */ '@/components/auth/CryptoproError'),
    //    name: 'CryptoproError'
    //},
    {
        path: '/auth/register/customer',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/RegisterCustomer'),
        name: 'RegisterCustomer'
    },
    {
        path: '/auth/reset_password/:token',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/reset-password'),
        name: 'ResetPassword'
    },
    //{
    //    path: '/auth/login',
    //    component: () => import(/* webpackChunkName: "main" */ '@/components/auth/Login'),
    //    name: 'Login'
    //},
    {
        path: '/auth/register/provider',
        component: () => import(/* webpackChunkName: "main" */ '@/components/auth/RegisterProvider'),
        name: 'RegisterProvider'
    }

    //{
    //    path: '/auth/logout',
    //    component: () => import(/* webpackChunkName: "main" */ '@/components/auth/Logout'),
    //    name: 'Logout'
    //},
    //{
    //    path: '/auth/error',
    //    component: () => import(/* webpackChunkName: "main" */ '@/components/auth/Error'),
    //    name: 'Error'
    //}
];
