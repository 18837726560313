'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import IOnOffOnToggleEvent from '@core/js/ddriven/application/abstractions/vue/IOnOffEvents';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import DeliverableSearchRequestVO, { IRawRequestData } from '@core/js/ddriven/application/http/requests/deliverables/DeliverableSearchRequest.valueobject';
import StandardPagination from '@core/js/ddriven/domain/model/common/StandardPagination.valueobject';
import DeliverableDictionaryItemVO, { IRawGSCDictionaryEntry } from '@core/js/ddriven/domain/model/common/deliverable/DeliverableDictionaryItem.valueobject';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

interface IData {
    request: DeliverableSearchRequestVO | null;
    list: DeliverableDictionaryItemVO[];
    pagination: StandardPagination | null;
    isloading: boolean;
}

interface LoadedData {
    list: DeliverableDictionaryItemVO[];
    pagination: StandardPagination;
}

@Component
export default class DeliverablesSelectorPopupController extends BaseViewModel {
    public static popupId = 'deliverables-selector-popup';

    constructor() {
        super();
        this.name = 'DeliverablesSelectorPopupController';
    }

    async created() {
        this.$data.request = new DeliverableSearchRequestVO().plainObjectViaStringify();
        const data: LoadedData = await this.load(new DeliverableSearchRequestVO(this.$data.request));
        this.$data.pagination = data.pagination;
        this.$data.list = data.list;
    }

    data(): IData {
        return {
            request: null,
            list: [],
            pagination: null,
            isloading: false
        };
    }

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$data.list.length < 1;
    }

    /**
     * Watch
     */

    /**
     * On filter change always request the first page of the filtered results.
     */
    @Watch('request.filter', { deep: true })
    async onRequestFilterChanged(newFilter: DeliverableSearchRequestVO, oldFilter: DeliverableSearchRequestVO | null) {
        if (!newFilter || (newFilter && !oldFilter)) {
            return;
        }
        this.$data.request.page = 1;
        this.loadAndSetData(this.$data.request);
    }

    @Watch('request.page')
    async onPaginationChanged(newPage: DeliverableSearchRequestVO, oldPage: DeliverableSearchRequestVO | null) {
        if (!newPage || (newPage && !oldPage)) {
            return;
        }
        this.loadAndSetData(this.$data.request);
    }

    /**
     * Methods
     */
    selected(index: number): void {
        this.$root.$emit('deliverable:selected', { key: null, item: Object.assign({}, this.$data.list[index]) } as IViewmodelEventPayload);
        this.close();
    }

    pageUpdate(evt: IViewmodelEventPayload): void {
        this.$data.request.page = evt.value;
    }

    redirectToRequestNewGSCItem(): void {
        this.$router.push({ name: 'KtruRequestsCreate' });
    }

    random(): number {
        // Make the new value surely different vs previous by adding page number.
        return Math.random() * (1000 - 1) + 1 + (this.$data.pagination ? this.$data.pagination.page.current : 1);
    }

    /**
     * Prototype general methods.
     */
    private close(): void {
        this.$root.$emit('public:onoff:toggle', { id: DeliverablesSelectorPopupController.popupId } as IOnOffOnToggleEvent);
    }

    private async loadAndSetData(requestPlain: IRawRequestData) {
        const data: LoadedData = await this.load(new DeliverableSearchRequestVO(requestPlain));
        this.$data.pagination = data.pagination;
        this.$data.list = data.list;
    }

    private async load(request: DeliverableSearchRequestVO): Promise<LoadedData> {
        this.$data.isloading = true;
        const response = await ApplicationServiceLocator.get('api').dictionaries.loadDeliverablesFiltered(request.toQueryString());
        let list: DeliverableDictionaryItemVO[] = response.data.data.map((item: IRawGSCDictionaryEntry) => {
            return Object.freeze(DeliverableDictionaryItemVO.fromGSCDictionaryEntry(item));
        });

        /**
         * REFACTOR: This is temporary filter for delivery item to not show it in the deliverables
         * list. It should be further removed as the backend should filter out the delivery
         * deliverable item itself, so no delivery item is returned in the response.
         */
        list = list.filter((deliverable: DeliverableDictionaryItemVO) => {
            return deliverable.okpd_code !== '49.41.1';
        });

        this.$data.isloading = false;

        return { list: list, pagination: new StandardPagination(response.data.meta) };
    }
}
