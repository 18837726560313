/* eslint-disable */
import store from '@/store';
import Signature from '@/services/api/Signature';
import { mapGetters } from 'vuex';
import SignatureException from '@/services/api/SignatureException';
import { helpers, required } from 'vuelidate/lib/validators';

const certBackError = (param) => helpers.withParams({ type: 'certBackError', value: param }, (value) => !helpers.req(value) || param === false);

export default {
    data() {
        return {
            listCert: [],
            currentCert: null, // certificates: [{ value: null, text: 'Не выбран' }], // сертификаты для селекта
            certificates: [],
            certIndex: null, // индекс выбранного сертификата
            certBackError: false,
            certErrorText: ''
        };
    },
    validations() {
        return {
            certIndex: {
                required,
                certBackError: certBackError(this.certBackError)
            }
        };
    },
    methods: {
        // распарсиваем subject сертификата по тегам
        parseSubject(from, what) {
            let certName = '';
            let begin = from.indexOf(what);
            if (begin >= 0) {
                let end = from.indexOf(', ', begin);
                certName = end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
            }
            return certName.replace(what, '');
        },
        hasCertificates() {
            // если есть сертификаты, выводим их в селекте и показываем modal для выбора
            let certs = this.$store.state.certificates;
            if (certs.length > 0) {
                this.certificates = this.$store.state.selectCertificates;
                return true;
            }
            return false;
        },
        loadCertificates() {
            // загружаем cades plugin, если он не загружен
            this.loadCades();

            this.$store.subscribe((mutation) => {
                // при загрузке плагина и сертификатов выводим сертификаты в селекте и показываем modal для выбора
                if (mutation.type === 'set_parsed_certificates') {
                    this.certificates = this.$store.state.selectCertificates;
                    this.$emit('certificates_loaded');
                }
            });
        }, // подключаем cadesplugin
        loadCades() {
            if (!window.hasOwnProperty('cadesplugin')) {
                let t = this,
                    e = document.createElement('script');
                e.setAttribute('src', '/assets/cadesplugin.js'),
                    document.head.appendChild(e),
                    e.addEventListener('load', function () {
                        t.checkPlugin();
                    });
            } else {
                this.checkPlugin();
            }
        }, // проверяем работу плагина определяем формат работы в браузере (для ИЕ в синхронном режиме, остальные async)
        checkPlugin() {
            let t = this;
            window.cadesplugin.then(
                function () {
                    if (!!cadesplugin.CreateObjectAsync) {
                        t.$store.commit('set_can_async', true);
                    } else {
                        t.$store.commit('set_can_async', false);
                    }
                    t.$store.commit('set_cades_loaded', true);
                },
                function (e) {
                    throw new SignatureException('Плагин КриптоПро не найден.', 90097);
                    /*t.$router.push('/auth/cryptopro_error');
                    t.pushToast({
                        title: 'Ошибка',
                        variant: 'danger',
                        boldText: 'Плагин КриптоПро не найден.',
                        linkText: 'Пожалуйста, прочтите <a href="/docs/ATMO_crypto.pdf" target="_blank" style="color: var(--green-color) !important; text-decoration: underline !important;">Инструкцию по настройке</a> сервисов КриптоПро, для использования электронной подписи.',
                        timer: 10000
                    });*/
                }
            );
        }, // получение списка сертификатов пользователя в асинхронном режиме работы
        getCertificates() {
            let t = this;
            window.cadesplugin.async_spawn(function* () {
                // получаем доступ к хранилищу сертификатов пользователя
                let oStore = yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                console.log(oStore);
                if (!oStore) {
                    throw new SignatureException('Не удалось получить доступ к хранилищу сертификатов');
                    /*t.pushToast({
                        text: 'Не удалось получить доступ к хранилищу сертификатов',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return;*/
                }
                try {
                    yield oStore.Open();
                } catch (err) {
                    throw new SignatureException('Ошибка при открытии хранилища: ' + err.message);
                    /*t.pushToast({
                        text: 'Ошибка при открытии хранилища: ' + err.message,
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return;*/
                }
                let certs;
                try {
                    certs = yield oStore.Certificates;
                } catch (ex) {
                    throw new SignatureException('Ошибка получения списка сертификатов: ' + cadesplugin.getLastError(ex));
                    /*t.pushToast({
                        text: 'Ошибка получения списка сертификатов: ' + cadesplugin.getLastError(ex),
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return;*/
                }
                if (certs) {
                    //выбираем только действующие сертификаты
                    certs = yield certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
                    certs = yield certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY, 2);
                    let count = yield certs.Count;
                    if (!count) {
                        throw new SignatureException('Нет доступных сертификатов');
                        /*t.pushToast({
                            text: 'Нет доступных сертификатов',
                            title: 'Ошибка',
                            variant: 'danger'
                        });
                        return;*/
                    }
                    let listCert = [];
                    let parsedCerts = [];
                    // let selectCert = [{ value: null, text: 'Не выбран' }];
                    let selectCert = [];
                    while (count) {
                        let item = yield certs.Item(count);
                        selectCert[count] = { value: count, text: yield item.SubjectName, valid_from: yield item.ValidFromDate, valid_to: yield item.ValidToDate };
                        listCert[count] = item;
                        parsedCerts[count] = {
                            subject: yield item.SubjectName,
                            issuer: yield item.IssuerName,
                            serial: yield item.SerialNumber,
                            thumbprint: yield item.Thumbprint,
                            valid_from: yield item.ValidFromDate,
                            valid_to: yield item.ValidToDate,
                            version: yield item.Version
                        };
                        count--;
                    }
                    t.$store.commit('set_certificates', listCert);
                    t.$store.commit('set_select_certificates', selectCert);
                    t.$store.commit('set_parsed_certificates', parsedCerts);
                }
                oStore.Close();
            });
        }, // получаем доступ к сертификатам пользователя в синхронном режиме (для ИЕ)
        getCertificatesNPAPI() {
            let oStore;
            try {
                oStore = cadesplugin.CreateObject('CAdESCOM.Store');
                oStore.Open();
            } catch (e) {
                throw new SignatureException('Сертификаты не найдены');
                /*this.pushToast({
                    text: 'Сертификаты не найдены',
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }
            let certs;
            certs = oStore.Certificates;
            if (certs) {
                certs = certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID);
                certs = certs.Find(cadesplugin.CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY, 2);
                let count = certs.Count;
                if (!count) {
                    throw new SignatureException('Нет доступных сертификатов');
                    /*this.pushToast({
                        text: 'Нет доступных сертификатов',
                        title: 'Ошибка',
                        variant: 'danger'
                    });
                    return false;*/
                }
                let listCert = [];
                let parsedCerts = [];
                let selectCert = [{ value: null, text: 'Не выбран' }];
                while (count) {
                    let item = certs.Item(count);
                    selectCert[count] = { value: count, text: item.SubjectName };
                    listCert[count] = item;
                    parsedCerts[count] = {
                        subject: item.SubjectName,
                        issuer: item.IssuerName,
                        serial: item.SerialNumber,
                        thumbprint: item.Thumbprint,
                        valid_from: item.ValidFromDate,
                        valid_to: item.ValidToDate,
                        version: item.Version
                    };
                    count--;
                }
                this.$store.commit('set_certificates', listCert);
                this.$store.commit('set_select_certificates', selectCert);
                this.$store.commit('set_parsed_certificates', parsedCerts);
            }
            oStore.Close();
        }, // парсим json в xml для подписи
        json2xml(o) {
            let toXml = function (v, name, ind) {
                    let xml = '';
                    // убираем поля, которых не должно быть в xml
                    if (name === 'signed_xml' || name === 'password' || name === 'password_confirmation' || name === 'created_at' || name === 'update_at' || name === 'deleted_at') {
                        return xml;
                    }
                    if (typeof v == 'object') {
                        let hasChild = false;
                        xml += ind + '<' + name;
                        for (let m in v) {
                            if (m.charAt(0) === '@') {
                                xml += ' ' + m.substr(1) + '="' + v[m].toString() + '"';
                            } else {
                                hasChild = true;
                            }
                        }
                        xml += hasChild ? '>\n' : '/>\n';
                        if (hasChild) {
                            for (let m in v) {
                                if (m === '#text') {
                                    xml += v[m];
                                } else if (m === '#cdata') {
                                    xml += '<![CDATA[' + v[m] + ']]>';
                                } else if (m.charAt(0) !== '@') {
                                    xml += toXml(v[m], m, ind + '\t');
                                }
                            }
                            xml += (xml.charAt(xml.length - 1) === '\n' ? ind : '') + '</' + name + '>\n';
                        }
                    } else {
                        xml += ind + '<' + name + '>' + v.toString() + '</' + name + '>\n';
                    }
                    return xml;
                },
                xml = '';
            for (let m in o) {
                xml += toXml(o[m], m, '');
            }
            return xml;
        }, // подписываем xml async
        verifyXml(sSignedMessage) {
            return cadesplugin.async_spawn(function* (arg) {
                // Создаем объект CAdESCOM.SignedXML
                var oSignedXML = yield cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML');

                try {
                    yield oSignedXML.Verify(sSignedMessage);
                } catch (err) {
                    throw new SignatureException('Failed to verify signature. Error: ' + cadesplugin.getLastError(err));
                    /*console.log('Failed to verify signature. Error: ' + cadesplugin.getLastError(err));
                    return false;*/
                }

                return true;
            });
        },
        verifyXmlNPAPI(sSignedMessage) {
            // Создаем объект CAdESCOM.SignedXML
            var oSignedXML = cadesplugin.CreateObject('CAdESCOM.SignedXML');

            try {
                oSignedXML.Verify(sSignedMessage);
            } catch (err) {
                console.log('Failed to verify signature. Error: ' + cadesplugin.getLastError(err));
                return false;
            }

            return true;
        },
        signXml(dataToSign, certificate) {
            let _this = this;
            return cadesplugin.async_spawn(function* (arg) {
                let Signature;
                try {
                    let errors = '';
                    let oSigner;
                    try {
                        oSigner = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
                    } catch (err) {
                        throw new SignatureException('Failed to create CAdESCOM.CPSigner: ' + err.number);
                        /*errors = 'Failed to create CAdESCOM.CPSigner: ' + err.number;
                        _this.pushToast({
                            text: errors,
                            title: 'Ошибка',
                            variant: 'danger'
                        });*/
                    }
                    if (oSigner) {
                        yield oSigner.propset_Certificate(certificate);
                    } else {
                        throw new SignatureException('Failed to create CAdESCOM.CPSigner');
                        /*errors = 'Failed to create CAdESCOM.CPSigner';
                        _this.pushToast({
                            text: errors,
                            title: 'Ошибка',
                            variant: 'danger'
                        });*/
                    }
                    let oSignedXML = yield cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML');
                    let signMethod = '';
                    let digestMethod = '';

                    let pubKey = yield certificate.PublicKey();
                    let algo = yield pubKey.Algorithm;
                    let algoOid = yield algo.Value;
                    if (algoOid === '1.2.643.7.1.1.1.1') {
                        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
                        signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256';
                        digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256';
                    } else if (algoOid === '1.2.643.7.1.1.1.2') {
                        // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
                        signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512';
                        digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512';
                    } else if (algoOid === '1.2.643.2.2.19') {
                        // алгоритм ГОСТ Р 34.10-2001
                        signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';
                        digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';
                    } else {
                        throw new SignatureException('Поддерживают только алгоритмы шифрования: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001');
                        /*errors = 'Поддерживают только алгоритмы шифрования: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001';
                        _this.pushToast({
                            text: errors,
                            title: 'Ошибка',
                            variant: 'danger'
                        });*/
                    }

                    let CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0;

                    if (dataToSign) {
                        // Данные на подпись ввели
                        yield oSignedXML.propset_Content(dataToSign);
                        yield oSignedXML.propset_SignatureType(CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED);
                        yield oSignedXML.propset_SignatureMethod(signMethod);
                        yield oSignedXML.propset_DigestMethod(digestMethod);

                        try {
                            Signature = yield oSignedXML.Sign(oSigner);
                            return Signature;
                        } catch (err) {
                            throw new SignatureException('Не удалось создать подпись из-за ошибки: ' + cadesplugin.getLastError(err));
                            /*errors = 'Не удалось создать подпись из-за ошибки: ' + cadesplugin.getLastError(err);
                            _this.pushToast({
                                text: errors,
                                title: 'Ошибка',
                                variant: 'danger'
                            });*/
                        }
                    }
                } catch (err) {
                    throw new SignatureException(err);
                    /*_this.pushToast({
                        text: err,
                        title: 'Ошибка',
                        variant: 'danger'
                    });*/
                }
            });
        }, // подписываем xml sync
        signXmlNPAPI(dataToSign, certObject) {
            let oSigner;
            let errors = '';

            try {
                oSigner = cadesplugin.CreateObject('CAdESCOM.CPSigner');
            } catch (err) {
                throw new SignatureException('Failed to create CAdESCOM.CPSigner: ' + err.number);
                /*errors = 'Failed to create CAdESCOM.CPSigner: ' + err.number;
                this.pushToast({
                    text: errors,
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }

            if (oSigner) {
                oSigner.Certificate = certObject;
            } else {
                throw new SignatureException('Failed to create CAdESCOM.CPSigner');
                /*errors = 'Failed to create CAdESCOM.CPSigner';
                this.pushToast({
                    text: errors,
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }

            let signMethod = '';
            let digestMethod = '';

            let pubKey = certObject.PublicKey();
            let algo = pubKey.Algorithm;
            let algoOid = algo.Value;
            if (algoOid === '1.2.643.7.1.1.1.1') {
                // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
                signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256';
                digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256';
            } else if (algoOid === '1.2.643.7.1.1.1.2') {
                // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
                signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512';
                digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512';
            } else if (algoOid === '1.2.643.2.2.19') {
                // алгоритм ГОСТ Р 34.10-2001
                signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';
                digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';
            } else {
                throw new SignatureException('Поддерживают только алгоритмы шифрования: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001');
                /*errors = 'Поддерживают только алгоритмы шифрования: ГОСТ Р 34.10-2012, ГОСТ Р 34.10-2001';
                this.pushToast({
                    text: errors,
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }

            let CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0;
            let oSignedXML;
            try {
                oSignedXML = cadesplugin.CreateObject('CAdESCOM.SignedXML');
            } catch (err) {
                throw new SignatureException('Failed to create CAdESCOM.SignedXML: ' + cadesplugin.getLastError(err));
                /*this.pushToast({
                    text: 'Failed to create CAdESCOM.SignedXML: ' + cadesplugin.getLastError(err),
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }

            oSignedXML.Content = dataToSign;
            oSignedXML.SignatureType = CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED;
            oSignedXML.SignatureMethod = signMethod;
            oSignedXML.DigestMethod = digestMethod;

            let sSignedMessage = '';
            try {
                sSignedMessage = oSignedXML.Sign(oSigner);
            } catch (err) {
                throw new SignatureException((errors = cadesplugin.getLastError(err)));
                /*errors = cadesplugin.getLastError(err);
                this.pushToast({
                    text: errors,
                    title: 'Ошибка',
                    variant: 'danger'
                });
                return false;*/
            }
            return sSignedMessage;
        },
        async SignXMLForm(xml) {
            if (store.state.canAsync) {
                const signedXml = await this.signXml(xml, store.state.certificates[store.state.currentCertificateIndex]);
                if (!signedXml) {
                    return null;
                }
                const xmlVerified = await this.verifyXml(signedXml);
                if (!xmlVerified) {
                    throw new SignatureException('Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания');
                    /*this.pushToast({
                        text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                        title: 'Ошибка подписания документа',
                        variant: 'danger',
                        timer: 10000
                    });
                    return null;*/
                }
                const xmlVerifiedByServer = await Signature.validateSignedXml(signedXml);
                if (!xmlVerifiedByServer) {
                    throw new SignatureException('Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания');
                    /*this.pushToast({
                        text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                        title: 'Ошибка подписания документа',
                        variant: 'danger',
                        timer: 10000
                    });
                    return null;*/
                }
                return signedXml;
            } else {
                const signedXml = this.signXmlNPAPI(xml, store.state.certificates[store.state.currentCertificateIndex]);
                const xmlVerifiedByServer = await Signature.validateSignedXml(signedXml);
                if (!xmlVerifiedByServer) {
                    throw new SignatureException('Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания');
                    /*this.pushToast({
                        text: 'Попробуйте выйти из системы, перезагрузить страницу, авторизоваться заново и повторить операцию подписания',
                        title: 'Ошибка подписания документа',
                        variant: 'danger',
                        timer: 10000
                    });
                    return null;*/
                }
                return signedXml;
            }
        }
    },
    mounted() {
        //  ждем события загрузки плагина и запрашиваем сертификаты
        this.$store.subscribe((mutation) => {
            if (mutation.type === 'set_cades_loaded' && this.$store.state.isCadesLoaded === true) {
                if (this.$store.state.canAsync) {
                    this.getCertificates();
                } else {
                    this.getCertificatesNPAPI();
                }
            }
        });
    },
    computed: {
        ...mapGetters(['currentParsedCertificate', 'getCurrentThumbprint', 'getCurrentParsedCertificateSubject', 'getCurrentParsedCertificateThumbprint', 'getCurrentCertificateIndex'])
    }
};
