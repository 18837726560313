<template>
    <td class="atmo-ctc-details-dropdown">
        <on-off v-if="this.hasRequirements" v-slot:default="onoff" v-bind:options="{ onEsc: false, onBody: false }">
            <div class="atmo-component-wrapper">
                <button class="atmo-button-icon" v-on:click.stop="onoff.toggle">
                    <span v-if="!onoff.ison" title="Показать характеристики"><i class="atmo-icon-show"></i></span>
                    <span v-else title="Скрыть характеристики"><i class="atmo-icon-hide"></i></span>
                </button>
                <ul v-show="onoff.ison" class="atmo-list" v-on:click.stop>
                    <li>
                        <dt>Описание</dt>
                        :
                        <dd v-text="description"></dd>
                    </li>
                    <li v-for="(requirement, index) in requirements" v-bind:key="index">
                        <dt v-text="requirement.title"></dt>
                        :
                        <dd v-text="requirement.description"></dd>
                    </li>
                </ul>
            </div>
        </on-off>

        <span v-else>—</span>
    </td>
</template>

<script>
import OnOff from '@lib/js/src/vue/components/OnOffV6.component.js';
import isTypeOrNull from '@lib/js/src/misc/isTypeOrNull';

export default {
    props: {
        requirements: {
            required: true,
            validator: (prop) => {
                return isTypeOrNull(prop, 'Array');
            }
        },
        description: { type: String }
    },
    components: {
        'on-off': new OnOff()
    },
    computed: {
        hasRequirements() {
            return this.$props.requirements && this.$props.requirements.length > 0;
        }
    }
};
</script>
