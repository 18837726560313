var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-content-group" },
    [
      _c("h5", [_vm._t("default")], 2),
      _vm._m(0),
      _c(
        "b-form-row",
        [
          _c("frc-select", {
            attrs: {
              options: _vm.bankTypes,
              v: _vm.$v.bank.bank_type,
              label: "Вид обслуживающей организации",
            },
            on: { input: _vm.onInput },
            model: {
              value: _vm.bank.bank_type,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "bank_type", $$v)
              },
              expression: "bank.bank_type",
            },
          }),
          _c(
            "b-col",
            { attrs: { lg: 6, sm: 12 } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    state: _vm.$v.bank.bik.$invalid,
                    label: "БИК банка:",
                    "label-class": "fs-14 grey-color fw-700",
                    "label-for": "supplier-accounting-bik",
                  },
                },
                [
                  _c("VueSuggestions", {
                    staticClass: "fs-14 form-control",
                    class: _vm.$v.bank.bik.$invalid ? "is-invalid" : "is-valid",
                    attrs: {
                      id: "supplier-accounting-bik",
                      model: _vm.bank.bik,
                      options: _vm.suggestionOptions,
                      placeholder: "Начните вводить",
                    },
                  }),
                  _vm.errorsBik
                    ? [
                        _c("b-form-invalid-feedback", [
                          _vm._v("Поле обязательно для заполнения"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c("form-row-edit-text-compact", {
            attrs: {
              v: _vm.$v.bank.bank_name,
              disabled: "disabled",
              label: "Название банка",
            },
            model: {
              value: _vm.bank.bank_name,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "bank_name", $$v)
              },
              expression: "bank.bank_name",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: {
              v: _vm.$v.bank.bank_city,
              disabled: "",
              label: "Город банка",
            },
            model: {
              value: _vm.bank.bank_city,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "bank_city", $$v)
              },
              expression: "bank.bank_city",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: { v: _vm.$v.bank.ks, label: "Кор. счет" },
            on: { input: _vm.onInput },
            model: {
              value: _vm.bank.ks,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "ks", $$v)
              },
              expression: "bank.ks",
            },
          }),
          _c("form-row-edit-text-compact", {
            attrs: { v: _vm.$v.bank.rs, label: "Расчетный счет" },
            on: { input: _vm.onInput },
            model: {
              value: _vm.bank.rs,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "rs", $$v)
              },
              expression: "bank.rs",
            },
          }),
          _c("form-row-edit-text-compact", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.bank.bank_type !== "bank",
                expression: "bank.bank_type !== 'bank'",
              },
            ],
            attrs: { v: _vm.$v.bank.ls, label: "Лицевой счет" },
            on: { input: _vm.onInput },
            model: {
              value: _vm.bank.ls,
              callback: function ($$v) {
                _vm.$set(_vm.bank, "ls", $$v)
              },
              expression: "bank.ls",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", [
        _vm._v(
          "Укажите банковские реквизиты поставщика для заключения договора, которые будут переданы в РИС после заключения."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }