var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "atmo-hash-jump-point atmo-content-group",
      attrs: { id: "atmo-proposals" },
    },
    [
      _c("h3", [_vm._v("Предложения участников закупочной сессии")]),
      !_vm.$props.pivc.hasPoroposals && !_vm.$props.pivc.isProposalsInfoHidden
        ? _c("p", [_vm._v("Нет поданных предложений")])
        : _vm._e(),
      _vm.$props.pivc.isProposalsInfoHidden
        ? _c("p", [_vm._v("Наступил период скрытия предложений")])
        : _vm._e(),
      _vm.$props.pivc.purchaseitem.protocol_date
        ? _c(
            "ul",
            {
              staticClass:
                "atmo-content-block-2-columns atmo-no-item-separator",
            },
            [
              _c("li", [
                _c("dt", [
                  _vm._v("Дата и время формирования итогового протокола:"),
                ]),
                _c("dd", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatdate")(
                        _vm.$props.pivc.purchaseitem.protocol_date,
                        "withSeconds"
                      )
                    )
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "table-responsive" }, [
        _vm.$props.pivc.hasPoroposals
          ? _c("table", { staticClass: "atmo-content-table atmo-proposals" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticClass: "atmo-place" }, [_vm._v("Место")]),
                  _c("th", { staticClass: "atmo-rating" }, [_vm._v("Рейтинг")]),
                  _c("th", { staticClass: "atmo-submit-date" }, [
                    _vm._v("Дата и время подачи"),
                  ]),
                  _c("th", { staticClass: "atmo-price-total" }, [
                    _vm._v("Стоимость, руб."),
                  ]),
                  _c("th", { staticClass: "atmo-supplier" }, [
                    _vm._v("Поставщик"),
                  ]),
                  (_vm.$props.pivc.isPurchaseSummarized ||
                    _vm.$props.pivc.hasContract) &&
                  _vm.$props.pivc.isUserEngagedCustomer
                    ? _c("th", { staticClass: "atmo-attachments" }, [
                        _vm._v("Документы"),
                      ])
                    : _vm._e(),
                  _vm.$props.pivc.isPurchaseSummarized ||
                  _vm.$props.pivc.hasContract
                    ? _c("th", { staticClass: "atmo-signature" }, [
                        _vm._v("Эл. подпись"),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(this.$props.proposals, function (proposal, index) {
                  return _c("tr", { key: index }, [
                    _c("td", {
                      staticClass: "atmo-place",
                      domProps: { textContent: _vm._s(index + 1) },
                    }),
                    _c("td", { staticClass: "atmo-rating" }, [
                      index === 0
                        ? _c("span", [
                            _vm.$props.pivc.isProposalsInfoHidden
                              ? _c("span", [_vm._v("Ожидает расчета")])
                              : _c("span", [
                                  _vm._v("Лучшее предложение"),
                                  proposal.is_current_supplier_proposal
                                    ? _c("span", [_vm._v(" (моё)")])
                                    : _vm._e(),
                                ]),
                          ])
                        : _c("span", [
                            proposal.is_current_supplier_proposal
                              ? _c("span", [_vm._v("Мое предложение")])
                              : _vm._e(),
                          ]),
                      proposal.is_declined
                        ? _c("p", [
                            _c("b", [_vm._v("Предложение отклонено.")]),
                            _c("br"),
                            _vm._v("Причина отклонения: "),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(proposal.decline_reason),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _c("td", { staticClass: "atmo-submit-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatdate")(
                            proposal.submitted_at,
                            "withSeconds"
                          )
                        )
                      ),
                    ]),
                    _c("td", { staticClass: "atmo-price-total" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatnumber")(proposal.total_price))
                      ),
                    ]),
                    _c("td", { staticClass: "atmo-supplier" }, [
                      _vm.$props.pivc.isPurchaseSummarized ||
                      _vm.$props.pivc.hasContract ||
                      _vm.$props.pivc.hasPurchaseProtocol
                        ? _c(
                            "span",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "atmo-button-link",
                                  attrs: {
                                    to: {
                                      name: "ProviderShow",
                                      params: { id: proposal.supplier.id },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(proposal.supplier.title_brief))]
                              ),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v("Поставщик " + _vm._s(index + 1)),
                          ]),
                    ]),
                    (_vm.$props.pivc.isPurchaseSummarized ||
                      _vm.$props.pivc.hasContract) &&
                    _vm.$props.pivc.isUserEngagedCustomer
                      ? _c("td", { staticClass: "atmo-attachments" }, [
                          proposal.hasAttachments()
                            ? _c(
                                "ul",
                                _vm._l(
                                  proposal.attachments,
                                  function (attachment, index) {
                                    return _c("li", { key: index }, [
                                      _c("button", {
                                        staticClass: "atmo-button-link",
                                        domProps: {
                                          textContent: _vm._s(attachment.name),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$props.pivc.downloadFileAttachment(
                                              attachment
                                            )
                                          },
                                        },
                                      }),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c("span", [_vm._v("Нет")]),
                        ])
                      : _vm._e(),
                    _vm.$props.pivc.isPurchaseSummarized ||
                    _vm.$props.pivc.hasContract
                      ? _c("td", { staticClass: "atmo-signature" }, [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button-icon",
                              attrs: {
                                title: "Нажмите, чтобы просмотреть подписи",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$props.pivc.invokeSignaturePopup([
                                    proposal.signature,
                                  ])
                                },
                              },
                            },
                            [_c("i", { staticClass: "atmo-icon-certificate" })]
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      this.$props.proposals.length > 1
        ? _c("proposal-price-chart", {
            attrs: { proposals: this.$props.proposals },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ppc) {
                    return [
                      _c("section", { staticClass: "atmo-chart" }, [
                        _c("h5", [_vm._v("Диаграмма цен предложений")]),
                        _c("span", {
                          domProps: { textContent: _vm._s(ppc.item) },
                        }),
                        _c("div", { staticClass: "ct-chart ct-double-octave" }),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              200993170
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }