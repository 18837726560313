'use strict';

import { upperFirst } from 'lodash';

import { IFileAttachmentVMEvent, Command } from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import FileAttachmentVO, { IRawFileAttachmentData } from '@core/js/ddriven/domain/model/common/FileAttachment.valueobject';
import ExtendedDictionaryItem from '@core/js/ddriven/domain/model/common/dictionaries/ExtendedDictionaryItem.valueobject';
import SupplierRequirementsVO, { TSelfPOJO as SRTSelfPOJO } from './supplier-requirements/SupplierRequirements.valueobject';
import SupplierRequirementDetailsA31s1p1VO from './supplier-requirements/SupplierRequirementDetailsA31s1p1.valueobject';
import SRDA31s1p1SupportingDocumentVO from './supplier-requirements/SRDA31s1p1SupportingDocument.valueobject';
import { IOriginalSpecItem } from '@core/js/ddriven/domain/model/common/deliverable/update/general/harness/OriginalSpecItem.valueobject';
import StandardDictionaryItem from '../../common/dictionaries/StandardDictionaryItem.valueobject';
import SpecificationEditableVO from './SpecificationEditable.valueobject';
import { IRawKBKLimit } from './KBKLimitSpecificationItemEditable.valueobject';
import Config from '@core/js/ddriven/application/config/Config';
import SupplierRequirementAdditionalVO, { TRawAdditional } from './supplier-requirements/SupplierRequirementAdditional.valueobject';

interface Self {
    [key: string]: PurchaseItemEditableVO[keyof PurchaseItemEditableVO];
}

function arrayToObject(input: any[]): Object {
    if (typeof input === 'undefined') {
        return {};
    } else if (!Array.isArray(input) && typeof input === 'object') {
        return input;
    } else {
        return input.reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
        }, {});
    }
}

export interface IPurchaseDraft {
    id: number;
    draft: {
        additional_contact_info: string | null;
        purchase_comment: string | null;
        purchase_limit_year: number;
        purchase_category_id: number;
        purchase_object: string | null;
        order_type_id: number;
        purchase_length: number;
        planned_contract_date: string;
        planned_end_date: string;
        start_price: number;
        additional_info: string | null;
        addresses: string[];
        order_plan: string | null;
        main_file: IRawFileAttachmentData;
        files: IRawFileAttachmentData[];
        specs: IOriginalSpecItem[];
        limits: IRawKBKLimit[];
        delivery_address: string | null;
        delivery_region: string | null;
        purchase_start: string | null;
        purchase_end: string | null;
        payment_deadline: string | null;
        payment_deadline_days: number;
        status_id: number;
        was_canceled: boolean;
        is_copy: boolean;
        notice_reg_number: string | null;

        fast_purchase_justification: string | null;
        fast_purchase_justification_stored_document: {
            id: string;
            name: string;
            type: string;
        } | null;

        // Supplier Requirements.
        only_smp: boolean;
        supplier_reqs_sonko: boolean;
        supplier_reqs_b: boolean;
        supplier_reqs_a: boolean;
        supplier_reqs_c: boolean;
        supplier_reqs_a_text: string | null;
        supplier_reqs_rating: boolean;
        supplier_required_documents: string[];
        supplier_requirements: {
            additional: TRawAdditional;
        };
        with_advance: boolean;
        advance_percentage: number;
        contract_template_params: any;
        with_contract_template: boolean;
    };
}

export default class PurchaseItemEditableVO {
    [index: string]: any;

    id: number | null;
    is_revoked: boolean;
    additional_contact_info: string | null;
    note: string | null;
    limit_year: number | null;
    deliverable_group_id: number | null;
    purchase_object: string | null;
    with_advance: boolean;
    advance_percentage: number;
    type_id: number | null;
    duration_id: number | null;
    planned_contract_signature_date: string | null;
    planned_contract_fulfillment_date: string | null;
    starting_price: number | null;
    unilateral_contract_termination_info: string | null;
    delivery_addresses: string[];
    delivery_schedule: string | null;

    supplier_requirements: SupplierRequirementsVO;
    contract_draft_attachment: FileAttachmentVO[];
    notice_supplementary_attachments: FileAttachmentVO[];
    specification: SpecificationEditableVO;

    urgency_reason: {
        id: number | null;
        attachment: FileAttachmentVO | {};
    };

    contract_template_params: any;
    with_contract_template: boolean;

    constructor(data?: Self) {
        this.id = this.valueOrDefault('id', data);
        this.is_revoked = this.valueOrDefault('is_revoked', data, false);
        this.additional_contact_info = this.valueOrDefault('additional_contact_info', data);
        this.note = this.valueOrDefault('note', data);
        this.limit_year = this.valueOrDefault('limit_year', data, new Date().getFullYear());
        this.deliverable_group_id = this.valueOrDefault('deliverable_group_id', data);
        this.purchase_object = this.valueOrDefault('purchase_object', data);
        this.with_advance = this.valueOrDefault('with_advance', data, false);
        this.advance_percentage = this.valueOrDefault('advance_percentage', data, 0);
        this.type_id = this.valueOrDefault('type_id', data, 2);
        this.duration_id = this.valueOrDefault('duration_id', data, 2);
        this.planned_contract_signature_date = this.valueOrDefault('planned_contract_signature_date', data);
        this.planned_contract_fulfillment_date = this.valueOrDefault('planned_contract_fulfillment_date', data);
        this.starting_price = this.valueOrDefault('starting_price', data, 0);
        this.unilateral_contract_termination_info = this.valueOrDefault('unilateral_contract_termination_info', data);
        this.is_only_trusted_supplier_required = this.valueOrDefault('is_only_trusted_supplier_required', data, false);
        this.is_only_small_business_supplier_required = this.valueOrDefault('is_only_small_business_supplier_required', data, false);
        this.delivery_addresses = this.valueOrDefault('delivery_addresses', data, []);
        this.delivery_schedule = this.valueOrDefault('delivery_schedule', data);
        this.supplier_requirements = data?.supplier_requirements ? new SupplierRequirementsVO(data.supplier_requirements) : new SupplierRequirementsVO();
        this.contract_draft_attachment = this.valueOrDefault('contract_draft_attachment', data, []);
        this.notice_supplementary_attachments = this.valueOrDefault('notice_supplementary_attachments', data, []);
        this.specification = data?.specification ? new SpecificationEditableVO(data?.specification) : new SpecificationEditableVO();

        this.urgency_reason = {
            id: data?.urgency_reason.id ?? null,
            attachment: data?.urgency_reason.attachment
        };
        this.contract_template_params = arrayToObject(data?.contract_template_params) ?? {};
        this.with_contract_template = this.valueOrDefault('with_contract_template', data, false);
    }

    public static fromDraft(data: IPurchaseDraft, dictionaries: { durations: ExtendedDictionaryItem[]; urgencyreasons: StandardDictionaryItem[]; deliverablegroups: Array<StandardDictionaryItem> }): PurchaseItemEditableVO {
        const deliverableGroupActive = dictionaries.deliverablegroups?.find((item) => item.is_active && item.id === data.draft.purchase_category_id);
        const supplierRequirements: SRTSelfPOJO = {
            is_only_smb: data.draft.only_smp,
            supplier_reqs_sonko: data.draft.supplier_reqs_sonko,
            a31s1ps3_5ps7_11: data.draft.supplier_reqs_b,
            a31s1p1: data.draft.supplier_reqs_a,
            a31s1_1: data.draft.supplier_reqs_c,
            a31s1p1_details: {
                description: data.draft.supplier_reqs_a_text as string,
                supporting_documents: data.draft.supplier_required_documents
                    ? data.draft.supplier_required_documents.map((document: string) => {
                          return { description: document, attachment: null } as SRDA31s1p1SupportingDocumentVO;
                      })
                    : []
            } as SupplierRequirementDetailsA31s1p1VO,
            rating: data.draft.supplier_reqs_rating,
            additional: new SupplierRequirementAdditionalVO(data.draft.supplier_requirements.additional)
        };

        const temp: Self = {
            id: data.id,
            is_revoked: data.draft.was_canceled,
            additional_contact_info: data.draft.additional_contact_info,
            note: data.draft.purchase_comment,
            limit_year: data.draft.purchase_limit_year,
            deliverable_group_id: deliverableGroupActive || data.draft.was_canceled ? data.draft.purchase_category_id : null,
            purchase_object: data.draft.purchase_object,
            with_advance: data.draft.with_advance ?? false,
            advance_percentage: data.draft.advance_percentage ?? 0,
            type_id: data.draft.order_type_id,

            // NB: Encode this to duration dictionary ID via matching value.
            duration_id: dictionaries.durations.find((item: ExtendedDictionaryItem) => {
                return item.value === data.draft.purchase_length;
            })!.id,

            planned_contract_signature_date: data.draft.planned_contract_date,
            planned_contract_fulfillment_date: data.draft.planned_end_date,
            starting_price: data.draft.start_price,
            unilateral_contract_termination_info: data.draft.additional_info,
            supplier_requirements: supplierRequirements,

            // NB: Extract values from API response object.
            delivery_addresses: data.draft.addresses.map((address: string) => {
                return address;
            }),

            delivery_schedule: data.draft.order_plan,

            contract_draft_attachment: data.draft.main_file ? [new FileAttachmentVO(data.draft.main_file)] : [],

            notice_supplementary_attachments: data.draft.files.map((file: IRawFileAttachmentData) => {
                return new FileAttachmentVO(file);
            }),
            specification: SpecificationEditableVO.fromDraft(data),
            urgency_reason: {
                id: StandardDictionaryItem.findByTitle(data.draft.fast_purchase_justification, dictionaries.urgencyreasons)?.id ?? null,
                attachment: data.draft.fast_purchase_justification_stored_document ? new FileAttachmentVO(data.draft.fast_purchase_justification_stored_document) : null
            },
            contract_template_params: arrayToObject(data.draft.contract_template_params) ?? {},
            with_contract_template: data.draft.with_contract_template ?? false
        };

        return new PurchaseItemEditableVO(temp);
    }

    public updateFileAttachment(event: IFileAttachmentVMEvent): void {
        if (!Object.values(Command).includes(event.command)) {
            throw new Error(`ATMO Exception: "${event.command}" is invalid file attachment update command.`);
        }

        const config = {
            contract: { propertyName: 'contract_draft_attachment' },
            notice: { propertyName: 'notice_supplementary_attachments' }
        };

        const methodName = `fileAttachment${upperFirst(event.command)}`;

        this[methodName](config, event);
    }

    public isUrgentPurchase(purchase: PurchaseItemEditableVO): boolean {
        return purchase.duration_id === 1;
    }

    private fileAttachmentAdd(config: Record<string, Record<string, string>>, event: IFileAttachmentVMEvent): void {
        (this[config[event.origin].propertyName] as FileAttachmentVO[]).push(event.attachment);
    }

    private fileAttachmentRemove(config: Record<string, Record<string, string>>, event: IFileAttachmentVMEvent): void {
        const propertyName = config[event.origin].propertyName;

        (this[propertyName] as FileAttachmentVO[]).splice(
            (this[propertyName] as FileAttachmentVO[])
                // REFACTOR: The event.attachment is FileAttachmentVO object in case of 'add' command
                // and FileAttachmentVO[] in case of 'remove' command. Must normalize it to be the same later
                // to get rid of a type conversion here.
                .findIndex((file: FileAttachmentVO) => {
                    return file.file_id === event.attachment.file_id;
                }),
            1
        );
    }

    private valueOrDefault(propertyName: keyof PurchaseItemEditableVO, data?: Self, defaultValue?: any): any {
        /**
         * NB: When data is defined normalize the empty strings properties values to null;
         * Otherwise, when the property exists in data or the property exists and it is Boolean,
         * set the property value as is. Otherwise set null.
         */
        const value = data && typeof data[propertyName] === 'string' && (data[propertyName] as string).length < 1 ? null : (data && data[propertyName]) || (data && typeof data[propertyName] === 'boolean') ? data[propertyName] : null;

        return data ? value : defaultValue ?? null;
    }
}
