var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "ktru-select-modal",
      attrs: {
        title: "Поиск ТРУ",
        size: "xl",
        scrollable: "",
        centered: "",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-footer": "",
        visible: _vm.show,
      },
      on: {
        close: _vm.cancelKtruSelectModal,
        cancel: _vm.cancelKtruSelectModal,
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Рег.номер" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Введите рег.номер" },
                        on: { change: _vm.onKtruFilterChange },
                        model: {
                          value: _vm.ktruTableFilter.reg_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.ktruTableFilter, "reg_number", $$v)
                          },
                          expression: "ktruTableFilter.reg_number",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.ktruTableFilter.reg_number,
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.ktruTableFilter.reg_number = ""
                                  _vm.onKtruFilterChange()
                                },
                              },
                            },
                            [_c("span", [_vm._v("×")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Название" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Введите название" },
                        on: { change: _vm.onKtruFilterChange },
                        model: {
                          value: _vm.ktruTableFilter.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ktruTableFilter, "name", $$v)
                          },
                          expression: "ktruTableFilter.name",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.ktruTableFilter.name,
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.ktruTableFilter.name = ""
                                  _vm.onKtruFilterChange()
                                },
                              },
                            },
                            [_c("span", [_vm._v("×")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Спецификация" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: { placeholder: "Введите текст спецификации" },
                        on: { change: _vm.onKtruFilterChange },
                        model: {
                          value: _vm.ktruTableFilter.specs,
                          callback: function ($$v) {
                            _vm.$set(_vm.ktruTableFilter, "specs", $$v)
                          },
                          expression: "ktruTableFilter.specs",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.ktruTableFilter.specs,
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.ktruTableFilter.specs = ""
                                  _vm.onKtruFilterChange()
                                },
                              },
                            },
                            [_c("span", [_vm._v("×")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Код ОКПД2" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Введите код ОКПД2 или его часть",
                        },
                        on: { change: _vm.onKtruFilterChange },
                        model: {
                          value: _vm.ktruTableFilter.okpd_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.ktruTableFilter, "okpd_code", $$v)
                          },
                          expression: "ktruTableFilter.okpd_code",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                disabled: !_vm.ktruTableFilter.okpd_code,
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.ktruTableFilter.okpd_code = ""
                                  _vm.onKtruFilterChange()
                                },
                              },
                            },
                            [_c("span", [_vm._v("×")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-responsive" },
        [
          _c("vuetable", {
            ref: "ktruvuetable",
            attrs: {
              fields: _vm.ktruTableColumns,
              css: _vm.vuetable2Config.table,
              "sort-order": _vm.sortOrder,
              "load-on-start": true,
              "query-params": _vm.ktruTableQueryParams,
              "append-params": { filter: _vm.ktruTableFilter },
              "http-fetch": _vm.ktruFetchData,
              "api-url": "/ktru",
              "data-path": "data",
              "pagination-path": "meta",
              "no-data-template": "Данные не найдены",
            },
            on: { "vuetable:pagination-data": _vm.onKtruPaginationData },
            scopedSlots: _vm._u([
              {
                key: "specs",
                fn: function (props) {
                  return [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle",
                            value: "collapse-" + props.rowData.id,
                            expression: "'collapse-' + props.rowData.id",
                          },
                        ],
                        attrs: { size: "sm", variant: "custom-green" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.visible[props.rowData.id]
                              ? "Скрыть"
                              : "Показать"
                          ) + " описание/характеристики"
                        ),
                      ]
                    ),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "mt-2",
                        attrs: { id: "collapse-" + props.rowData.id },
                        model: {
                          value: _vm.visible[props.rowData.id],
                          callback: function ($$v) {
                            _vm.$set(_vm.visible, props.rowData.id, $$v)
                          },
                          expression: "visible[props.rowData.id]",
                        },
                      },
                      [
                        props.rowData.description
                          ? _c("p", [
                              _c("b", [_vm._v("Описание:")]),
                              _vm._v(" " + _vm._s(props.rowData.description)),
                            ])
                          : _vm._e(),
                        props.rowData.specs && props.rowData.specs.length > 0
                          ? _c("p", [_c("b", [_vm._v("Характеристики:")])])
                          : _vm._e(),
                        props.rowData.specs && props.rowData.specs.length > 0
                          ? _c(
                              "ul",
                              _vm._l(props.rowData.specs, function (spec, key) {
                                return _c("li", { key: "spec" + key }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "900" } },
                                    [_vm._v(_vm._s(spec.Name))]
                                  ),
                                  _vm._v(": " + _vm._s(spec.Value) + " "),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "actions",
                fn: function (props) {
                  return [
                    _c(
                      "b-btn",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.selectKtruItem(props.rowData)
                          },
                        },
                      },
                      [_vm._v("Выбрать")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("vuetable-pagination", {
            ref: "ktrupagination",
            attrs: { css: _vm.vuetable2Config.pagination },
            on: { "vuetable-pagination:change-page": _vm.onKtruChangePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }