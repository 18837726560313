'use strict';

import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import ProposalItemVO from '@core/js/ddriven/domain/model/purchases/view/item/ProposalItem.valueobject';
import Chartist, { IChartistData } from 'chartist';
import FormatDate from '@lib/js/src/misc/FormatDate';
import options from './options';

@Component
export default class ProposalsPriceChart extends BaseViewModel {
    constructor() {
        super();
        this.name = 'ProposalsPriceChart';
    }

    @Prop({ required: true, type: Array }) readonly proposals?: ProposalItemVO[];

    mounted() {
        const deltaLow = 1 / Math.sqrt(Math.min(...this.chartData.series[0])) / 2;
        const deltaHigh = 1 / Math.sqrt(Math.max(...this.chartData.series[0])) / 2;

        options.low = Math.min(...this.chartData.series[0]) * (1 - deltaLow);
        options.high = Math.max(...this.chartData.series[0]) * (1 + deltaHigh);
        this.chartData.series[0].unshift(undefined as any);
        this.chartData.labels.unshift('');
        new Chartist.Line('.ct-chart', this.chartData as IChartistData, options);
    }

    data() {
        return {};
    }

    /**
     * Computed
     */
    get chartData() {
        return this.prepareChartData(this.$props.proposals);
    }

    /**
     * Prototype general methods.
     */
    private prepareChartData(proposals: ProposalItemVO[]) {
        const data: { labels: string[]; series: Array<number>[] } = { labels: [], series: [] };
        const prices = proposals
            .sort(function (a, b) {
                const dateA = new Date(a.submitted_at).getTime();
                const dateB = new Date(b.submitted_at).getTime();
                return dateA < dateB ? -1 : 1; // ? -1 : 1 for ascending/increasing order
            })
            .map((proposal: ProposalItemVO): number => {
                data.labels.push(FormatDate.removeSeconds(proposal.submitted_at));
                return proposal.total_price;
            });
        data.series.push(prices);
        return data;
    }
}
