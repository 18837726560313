var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("dadata-suggestions-controller", {
    on: { "address:add": _vm.$props.controller.$props.addhandler },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (dsc) {
          return [
            _c("section", { staticClass: "atmo-widget-address-autocomplete" }, [
              _c("p", { domProps: { textContent: _vm._s(dsc.value) } }),
              _c("div", { staticClass: "atmo-input-group" }, [
                _c(
                  "div",
                  { staticClass: "atmo-form__field atmo-inverse" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: dsc.input,
                          expression: "dsc.input",
                        },
                      ],
                      ref: "input",
                      attrs: {
                        type: "text",
                        placeholder: "Начните вводить адрес",
                      },
                      domProps: { value: dsc.input },
                      on: {
                        input: [
                          function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(dsc, "input", $event.target.value)
                          },
                          dsc.requestSuggestions,
                        ],
                        focus: dsc.updateFocus,
                        blur: dsc.updateFocus,
                      },
                    }),
                    _c("dropdown-select", {
                      attrs: { list: dsc.list },
                      on: { "dropdown:select:selected": dsc.selected },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ds) {
                              return [
                                _c("on-off", {
                                  attrs: { options: { onBody: "intelligent" } },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (onoff) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "atmo-dropdown-select",
                                              },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          !dsc.islistempty &&
                                                          dsc.isfocus,
                                                        expression:
                                                          "!dsc.islistempty && dsc.isfocus",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "atmo-dropdown",
                                                  },
                                                  _vm._l(
                                                    ds.list,
                                                    function (item) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key: item.id,
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              ds.select(
                                                                item.id
                                                              ),
                                                                onoff.off()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("span", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.title
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "atmo-clear",
                        attrs: {
                          disabled: dsc.isinputempty,
                          title: "Очистить поле ввода",
                        },
                        on: { click: dsc.clear },
                      },
                      [_c("i", { staticClass: "atmo-icon-close" })]
                    ),
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "atmo-button",
                    attrs: {
                      disabled: dsc.isinputempty,
                      title: "Добавить адрес в список адресов",
                    },
                    on: { click: dsc.add },
                  },
                  [_vm._v("Добавить")]
                ),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }