<template>
    <div class="table-responsive">
        <table class="atmo-content-table atmo-addresses">
            <thead>
                <tr>
                    <th>№</th>
                    <th>Адрес</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(address, index) in $props.controller.$props.addresseslist" v-bind:key="index">
                    <td v-text="index + 1"></td>
                    <td v-text="address"></td>
                    <td>
                        <button v-on:click="$props.controller.remove(index)" class="atmo-button-icon" title="Удалить адрес"><i class="atmo-icon-trash"></i></button>
                    </td>
                </tr>
                <tr v-if="$props.controller.islistempty">
                    <td colspan="3">Не добавлено адресов</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        controller: { required: true, type: Object }
    }
};
</script>
