<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <ul :id="id">
            <li v-for="document of value" :key="document.id">
                <a class="fs-14 col-form-label d-inline-block" :href="$getAttachmentDownloadLink(document.id)" target="_blank">{{ document.name }}</a>
            </li>
        </ul>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-documents',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            default: () => []
        },
        description: {
            type: String,
            default: ''
        }
    },
    computed: {
        id() {
            return 'text_row_documents_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
a {
    color: var(--green-color) !important;
}
a:hover {
    color: var(--green-color) !important;
    text-decoration: underline !important;
}
</style>
