export default [
    {
        path: '/external_purchases/create/:id?',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/Create'),
        name: 'ExternalPurchaseCreate'
    },
    {
        path: '/external_purchases/:id/edit',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/ExternalPurchaseEdit'),
        name: 'ExternalPurchaseEdit'
    },
    {
        path: '/external_purchases/:purchase',
        name: 'ExternalPurchaseShow',
        component: () => import(/* webpackChunkName: "main" */ '@/components/externalPurchases/Show.vue')
        // component: () => import( /* webpackChunkName: "main" */ '@/components/purchases/outAtmo/PurchaseInfo'),
        // children: [{
        //         path: 'info',
        //         alias: 'order-info',
        //         name: 'PurchaseInfo',
        //         component: () => import( /* webpackChunkName: "main" */ '@/components/purchases/outAtmo/purchaseInfo/PurchaseInfo'),
        //     },
        //     {
        //         path: 'provider',
        //         name: 'PurchaseProvider',
        //         component: () => import( /* webpackChunkName: "main" */ '@/components/purchases/outAtmo/purchaseInfo/PurchaseProvider'),
        //     },
        //     {
        //         path: 'contract',
        //         name: 'PurchaseContract',
        //         component: () => import( /* webpackChunkName: "main" */ '@/components/purchases/outAtmo/purchaseInfo/PurchaseContract'),
        //     },
        // ],
    },
    {
        path: '/purchases/create/:id?',
        redirect: () => {
            return `/purchases/grouped/fl44/item/create`;
        }
    },
    {
        path: '/purchases/show/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/Show'),
        name: 'PurchasesShow'
    },
    {
        path: '/purchases',
        redirect: { name: 'PurchasesList', params: { path: 'new' } },
        name: 'TestTest',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/PurchasesList'),
        children: [
            {
                path: ':path',
                name: 'PurchasesList',
                component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/PurchasesShow')
            }
        ]
    },
    {
        path: '/purchases/:id/new_offer',
        name: 'PurchasesNewOffer',
        component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/NewOffer')
    },
    {
        path: '/purchases/:order',
        // NB: Rearchitectured. Commented out as being replaced by wildcards route redirect.
        redirect: (to) => {
            console.dir(to);
            /**
             * NB: Still unclear how to apply :group route parameter;
             * Probably deafult 'fl44' will do as it will be delivered
             * to production sooner than 'fl223`. So the links in emails
             * will be updated to the new format by the time fl223 is introduced.
             */
            return `/purchases/grouped/fl44/item/${to.params.order}/view`;
        },
        // component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/PurchaseInfo'),
        children: [
            {
                path: '*',
                redirect: (to) => {
                    return `/purchases/grouped/fl44/item/${to.params.order}/view`;
                }
            }
            // NB: Rearchitectured. Commented out as being replaced by wildcards route redirect.
            //   {
            //     path: 'order-info',
            //     name: 'OrderInfo',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/PurchaseOrderInfo'),
            //   },
            //   {
            //     path: 'proposal-dynamics',
            //     name: 'ProposalDynamics',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/PurchaseProposalDynamics'),
            //   },
            //   {
            //     path: 'final-protocol',
            //     name: 'FinalProtocol',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/PurchaseFinalProtocol'),
            //   },
            //   {
            //     path: 'contract',
            //     name: 'Contract',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/PurchaseContract'),
            //   },
            //   {
            //     path: 'contract-execution',
            //     name: 'ContractExecution',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/ContractExecution'),
            //   },
            //   {
            //     path: 'execution',
            //     name: 'PurchaseExecutionDocuments',
            //     component: () => import(/* webpackChunkName: "main" */ '@/components/purchases/purchaseInfo/PurchaseExecutionDocuments'),
            //   },
        ]
    }
];
