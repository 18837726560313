<template>
    <section v-on:click.stop v-if="data" class="atmo-component-wrapper">
        <ul class="atmo-popup-block-2-columns">
            <li>
                <dt>
                    <span v-if="this.$props.data.userType() === 'customer'">Заказчик:</span>
                    <span v-if="this.$props.data.userType() === 'supplier'">Поставщик:</span>
                </dt>
                <dd v-text="this.$props.data.organization_title"></dd>
            </li>
            <li v-if="this.$props.data.certificate_date">
                <dt>Дата и время:</dt>
                <dd>{{ this.$props.data.certificate_date | formatdate('withSeconds') }}</dd>
            </li>
            <li v-else>
                <span>Договор ожидает подписи </span>
                <span v-if="this.$props.data.userType() === 'customer'">Заказчика</span>
                <span v-if="this.$props.data.userType() === 'supplier'">Поставщика</span>
            </li>
        </ul>

        <div v-if="this.$props.data.certificate_key" class="atmo-signature">
            <dd>
                <pre>{{ this.$props.data.certificate_key }}</pre>
            </dd>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        data: { required: true, type: Object }
    }
};
</script>
