'use strict';

import { Debounce } from 'vue-debounce-decorator';
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseViewModel from '@lib/js/src/vue/vm/BaseViewModel';
import DadataService from '@core/js/ddriven/domain/services/address/DadataService';
import { FileInputEvent } from '@core/js/ddriven/application/abstractions/types/FileInputEvent';
import IViewmodelEventPayload from '@core/js/ddriven/application/abstractions/vue/IViewmodelEventPayload';
import ApplicationServiceLocator from '@core/js/ddriven/application/services/ApplicationServiceLocator';

interface IData {
    input: string | null;
    list: { id: number; title: string }[];
    isfocus: boolean;
}

@Component
export default class DadataSuggestionsController extends BaseViewModel {
    service?: DadataService;

    constructor() {
        super();
        this.name = 'DadataSuggestionsController';
        this.service = ApplicationServiceLocator.get('dadata');

        (async () => {
            const locationsBoost = await this.service!.iplocate();
            this.service!.config({ locations_boost: [locationsBoost] });
        })();
    }

    // @Prop({ default: 'short', type: String }) readonly representaton!: string;

    data(): IData {
        return {
            input: null,
            list: [],
            isfocus: false
        };
    }

    /**
     * Computed
     */
    get islistempty(): boolean {
        return this.$data.list.length < 1;
    }

    get isinputempty(): boolean {
        return !this.$data.input || this.$data.input.length < 1;
    }

    /**
     * Methods
     */
    updateFocus(evt: InputEvent): void {
        window.setTimeout(() => {
            this.$data.isfocus = evt.type === 'focus' ? true : false;
        }, 500);
    }

    @Debounce(600)
    async requestSuggestions(evt: FileInputEvent) {
        this.$data.list.splice(0);
        if (!evt.target.value || evt.target.value.length < 1) {
            return;
        }

        const addresses = (await this.service!.addresses(evt.target.value, true)).map((address: string, index: number) => {
            return Object.freeze({ id: index, title: address });
        });

        this.$data.list.push(...addresses);
    }

    selected(evt: IViewmodelEventPayload) {
        this.setInputValue(evt.item!.title as string);
    }

    clear(): void {
        this.setInputValue(null);
    }

    add(): void {
        this.$emit('address:add', { item: { description: this.$data.input } });
        this.clear();
    }

    /**
     * Common prototype methods
     */
    private setInputValue(value: string | null): void {
        this.$data.input = value;
        ((this.$parent!.$refs.input as HTMLInputElement).value as any) = value;
        (this.$parent!.$refs.input as HTMLInputElement)?.dispatchEvent(new Event('input', { bubbles: true }));
    }
}
