var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { attrs: { md: "4" } },
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.label } },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
                on: { input: _vm.onFilterChange },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: !_vm.text || _vm.disabled,
                        size: "sm",
                      },
                      on: { click: _vm.clearValue },
                    },
                    [_c("span", [_vm._v("×")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }