var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("purchase-item-update-controller", {
    attrs: { group: this.$props.group, id: this.$props.id },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (piuc) {
          return [
            _c("div", { staticClass: "atmo-page" }, [
              _c("section", { staticClass: "atmo-fast-access-menu" }, [
                _c("ul", { staticClass: "atmo-container-fixed" }, [
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: { "atmo-active": piuc.visibleTab === "terms" },
                        on: {
                          click: function ($event) {
                            return piuc.setTab("terms")
                          },
                        },
                      },
                      [_vm._v("Условия закупки и поставки")]
                    ),
                    piuc.tabbedErrorCounts.terms
                      ? _c("span", {
                          staticClass: "atmo-badge",
                          attrs: { title: "Количество ошибочных полей" },
                          domProps: {
                            textContent: _vm._s(piuc.tabbedErrorCounts.terms),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        class: {
                          "atmo-active": piuc.visibleTab === "deliverables",
                        },
                        on: {
                          click: function ($event) {
                            return piuc.setTab("deliverables")
                          },
                        },
                      },
                      [_vm._v("Спецификация и документы")]
                    ),
                    piuc.tabbedErrorCounts.deliverables
                      ? _c("span", {
                          staticClass: "atmo-badge",
                          attrs: { title: "Количество ошибочных полей" },
                          domProps: {
                            textContent: _vm._s(
                              piuc.tabbedErrorCounts.deliverables
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c(
                "header",
                [_c("InfoBanner.view", { attrs: { user: piuc.user } })],
                1
              ),
              _c(
                "main",
                { staticClass: "atmo-container-fixed atmo-page-content" },
                [
                  _c("div", { staticClass: "atmo-buttons" }, [
                    _c(
                      "a",
                      {
                        staticClass: "atmo-button atmo-accented",
                        attrs: {
                          href: "https://app.hinted.me/simulations/7a63d719-ee38-4b27-9c11-92d26293d354/view",
                          rel: "noopener",
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-play" }),
                        _vm._v(" Инструкция по созданию извещения"),
                      ]
                    ),
                  ]),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: piuc.visibleTab === "terms",
                          expression: "piuc.visibleTab === 'terms'",
                        },
                      ],
                      staticClass: "atmo-tab",
                    },
                    [
                      _c("CustomerInfo.view", {
                        attrs: { user: piuc.user, inputs: piuc.inputs },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("PurchaseTerms.view", {
                        attrs: {
                          controller: piuc,
                          inputs: piuc.inputs,
                          errors: piuc.errors,
                          dictionaries: piuc.dictionaries,
                          deliverablesgroupsselecthandler:
                            piuc.deliverablesGroupsSelectHandler,
                          deliverablesgroupsremovehandler:
                            piuc.deliverablesGroupsRemoveHandler,
                        },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("SupplierRequirements.view", {
                        attrs: { inputs: piuc.inputs },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("SupplyTerms.view", {
                        attrs: {
                          inputs: piuc.inputs,
                          errors: piuc.errors,
                          addhandler: piuc.addToAddressesList,
                          removehandler: piuc.removeFromAddressesList,
                          useraddressbook: piuc.dictionaries.useraddressbook,
                        },
                        on: {
                          "update:inputs": function ($event) {
                            return _vm.$set(piuc, "inputs", $event)
                          },
                        },
                      }),
                      _c("ul", { staticClass: "atmo-buttons" }, [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "atmo-button-inverse atmo-dimmed",
                                attrs: { to: { name: "CustomerMainCabinet" } },
                              },
                              [_vm._v("Отменить")]
                            ),
                          ],
                          1
                        ),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button",
                              on: {
                                click: function ($event) {
                                  return piuc.setTab("deliverables")
                                },
                              },
                            },
                            [_vm._v("Далее")]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button atmo-accented",
                              on: { click: piuc.saveDraft },
                            },
                            [_vm._v("Сохранить черновик")]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: piuc.visibleTab === "deliverables",
                          expression: "piuc.visibleTab === 'deliverables'",
                        },
                      ],
                      staticClass: "atmo-tab",
                    },
                    [
                      _c("deliverables-editable-list-controller", {
                        attrs: {
                          initiallist: piuc.inputs.specification.deliverables,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (dsc) {
                                return [
                                  _c("DeliverablesEditableList.view", {
                                    attrs: {
                                      controller: dsc,
                                      deliverables: dsc.list,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "errors",
                                          fn: function () {
                                            return [
                                              dsc.islistempty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Заполните спецификацию"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors.is_quantity_empty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        'У одной или нескольких позиций спецификации неправильно заполнено поле "Количество".'
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors.is_price_per_unit_empty
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        'У одной или нескольких позиций спецификации неправильно заполнено поле "Цена за единицу".'
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !dsc.islistempty &&
                                              dsc.errors
                                                .is_specification_total_price_invalid
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "atmo-error",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Общая стоимость спецификации превышает 600,000 руб."
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      piuc.features.kbk_limits && !piuc.withoutLimits
                        ? _c("KBKLimitsEditableList.view", {
                            attrs: {
                              initiallist: piuc.inputs.specification.kbkLimits,
                              totalamount:
                                piuc.inputs.specification.total_amount,
                              limitfilterpresets: {
                                year: piuc.inputs.limit_year,
                                purchase_type_id: piuc.inputs.type_id,
                                purchase_id: piuc.inputs.id,
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "info",
                                  fn: function () {
                                    return [
                                      _c("p", [
                                        _c("i", {
                                          staticClass:
                                            "atmo-warning atmo-icon-warning",
                                        }),
                                        _vm._v(
                                          ' Внимание! Перед добавление лимитов проверьте правильность значений полей "Год ПГ" и "Тип закупки" в разделе "Условия закупки". При изменении любого из этих полей все добавленные в таблицу лимиты по КБК будут удалены. '
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _c("editable-file-attachments-list-controller", {
                        attrs: {
                          initiallist: piuc.inputs.contract_draft_attachment,
                          isrequired: true,
                          origin: "contract",
                          maxfilesallowed: 1,
                        },
                        on: {
                          "fileattachment:changed":
                            piuc.fileAttachmentEventHandler,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (efalc) {
                                return [
                                  _c(
                                    "EditableFileAttachmentsList.view",
                                    {
                                      attrs: { controller: efalc },
                                      scopedSlots: _vm._u(
                                        [
                                          piuc.inputs.deliverable_group_id ===
                                          16
                                            ? {
                                                key: "controls",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "li",
                                                      [
                                                        piuc.hasErrorsWithoutContractDraftAttachment
                                                          ? [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "atmo-button",
                                                                  attrs: {
                                                                    id: "popover-contract-template",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      "popover-contract-template",
                                                                    triggers:
                                                                      "hover",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "fs-12 my-1 py-1",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        'Для автоматического формирования проекта контракта необходимо заполнить все обязательные поля, за исключением документа в поле "Проект ГК".'
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : _vm.isContractTemplateFormVisible ||
                                                            !piuc.errors
                                                              .contract_draft_attachment
                                                          ? [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "atmo-button-inverse",
                                                                  attrs: {
                                                                    id: "popover-contract-template",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          : [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "atmo-button",
                                                                  attrs: {
                                                                    id: "popover-contract-template",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.showContractTemplateForm(
                                                                          piuc
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "ВНЕСТИ УСЛОВИЯ ПО КОНТРАКТУ"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : null,
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("h3", [_vm._v("Проект ГК")])]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm.isContractTemplateFormVisible
                        ? _c(
                            "div",
                            { staticClass: "atmo-content-group" },
                            [
                              _c("h3", [_vm._v("Условия по контракту")]),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label: "Контракт на поставку",
                                  v: _vm.$v.contract_template_params
                                    .deliverable_name,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .deliverable_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "deliverable_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.deliverable_name",
                                },
                              }),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label:
                                    "Должность и ФИО подписанта контракта (в родительном падеже)",
                                  v: _vm.$v.contract_template_params
                                    .signer_position_and_name,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .signer_position_and_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "signer_position_and_name",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.signer_position_and_name",
                                },
                              }),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label:
                                    "Действует на основании (в родительном падеже)",
                                  v: _vm.$v.contract_template_params
                                    .signer_acts_on_basis,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .signer_acts_on_basis,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "signer_acts_on_basis",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.signer_acts_on_basis",
                                },
                              }),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label: "Источник финансирования",
                                  v: _vm.$v.contract_template_params
                                    .source_of_funding,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .source_of_funding,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "source_of_funding",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.source_of_funding",
                                },
                              }),
                              piuc.inputs.with_advance
                                ? _c("form-row-edit-number", {
                                    attrs: {
                                      label:
                                        "Количество дней на выплату аванса от даты заключения контракта",
                                      v: _vm.$v.contract_template_params
                                        .days_for_advance_payment,
                                    },
                                    model: {
                                      value:
                                        _vm.contract_template_params
                                          .days_for_advance_payment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract_template_params,
                                          "days_for_advance_payment",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "contract_template_params.days_for_advance_payment",
                                    },
                                  })
                                : _vm._e(),
                              _c("form-row-edit-date", {
                                attrs: {
                                  label: "Максимальный срок поставки товаров",
                                  "max-date":
                                    piuc.inputs
                                      .planned_contract_fulfillment_date,
                                  v: _vm.$v.contract_template_params
                                    .max_delivery_date,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .max_delivery_date,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "max_delivery_date",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.max_delivery_date",
                                },
                              }),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label: "Адрес доставки товаров",
                                  v: _vm.$v.contract_template_params
                                    .delivery_address,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .delivery_address,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "delivery_address",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.delivery_address",
                                },
                              }),
                              _c("form-row-edit-checkbox", {
                                attrs: {
                                  "label-cols-lg": 11,
                                  "label-cols-sm": 11,
                                  label:
                                    "Необходимо ли осуществить монтаж и наладку Товара в соответствии со Спецификацией",
                                  v: _vm.$v.contract_template_params
                                    .installation_needed,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .installation_needed,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "installation_needed",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.installation_needed",
                                },
                              }),
                              _c("form-row-edit-checkbox", {
                                attrs: {
                                  "label-cols-lg": 11,
                                  "label-cols-sm": 11,
                                  label:
                                    "Необходимо ли провести обучение лиц, осуществляющих использование и обслуживание Товара в соответствии со Спецификацией",
                                  v: _vm.$v.contract_template_params
                                    .training_needed,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .training_needed,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "training_needed",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.training_needed",
                                },
                              }),
                              _c("form-row-edit-number", {
                                attrs: {
                                  label:
                                    "Количество дней, за которое поставщик должен уведомить о поставке товара",
                                  v: _vm.$v.contract_template_params
                                    .delivery_notification_days,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .delivery_notification_days,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "delivery_notification_days",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "contract_template_params.delivery_notification_days",
                                },
                              }),
                              _c("form-row-edit-number", {
                                attrs: {
                                  label:
                                    "Количество дней, в течение которых стороны должны уведомить друг друга о наступлении обстоятельств непреодолимой силы",
                                  v: _vm.$v.contract_template_params
                                    .force_majeure_notification_days,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .force_majeure_notification_days,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "force_majeure_notification_days",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "contract_template_params.force_majeure_notification_days",
                                },
                              }),
                              _c("form-row-edit-text", {
                                attrs: {
                                  label:
                                    "Требования к гарантии качества Товара",
                                  v: _vm.$v.contract_template_params
                                    .quality_assurance,
                                },
                                model: {
                                  value:
                                    _vm.contract_template_params
                                      .quality_assurance,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract_template_params,
                                      "quality_assurance",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "contract_template_params.quality_assurance",
                                },
                              }),
                              _c("p", [
                                _c("span", [
                                  _vm._v(
                                    "Укажите банковские реквизиты Вашей организации для заполнения шаблона договора."
                                  ),
                                ]),
                              ]),
                              _c(
                                "b-form-row",
                                [
                                  _c("form-row-edit-text-compact", {
                                    attrs: {
                                      v: _vm.$v.contract_template_params
                                        .customer_bank_name,
                                      label:
                                        "Наименование органа, в котором открыт лицевой счет",
                                    },
                                    model: {
                                      value:
                                        _vm.contract_template_params
                                          .customer_bank_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract_template_params,
                                          "customer_bank_name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "contract_template_params.customer_bank_name",
                                    },
                                  }),
                                  _c("form-row-edit-text-compact", {
                                    attrs: {
                                      v: _vm.$v.contract_template_params
                                        .customer_bank_bik,
                                      label: "БИК банка",
                                    },
                                    model: {
                                      value:
                                        _vm.contract_template_params
                                          .customer_bank_bik,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract_template_params,
                                          "customer_bank_bik",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "contract_template_params.customer_bank_bik",
                                    },
                                  }),
                                  _c("form-row-edit-text-compact", {
                                    attrs: {
                                      v: _vm.$v.contract_template_params
                                        .customer_bank_rs,
                                      label: "Банковские реквизиты счета",
                                    },
                                    model: {
                                      value:
                                        _vm.contract_template_params
                                          .customer_bank_rs,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract_template_params,
                                          "customer_bank_rs",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "contract_template_params.customer_bank_rs",
                                    },
                                  }),
                                  _c("form-row-edit-text-compact", {
                                    attrs: {
                                      v: _vm.$v.contract_template_params
                                        .customer_bank_ls,
                                      label: "Лицевой счет",
                                    },
                                    model: {
                                      value:
                                        _vm.contract_template_params
                                          .customer_bank_ls,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contract_template_params,
                                          "customer_bank_ls",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "contract_template_params.customer_bank_ls",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("ul", { staticClass: "atmo-buttons" }, [
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "atmo-button",
                                      attrs: {
                                        disabled:
                                          _vm.$v.contract_template_params
                                            .$invalid,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveContractTemplateForm(
                                            piuc
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Сохранить")]
                                  ),
                                ]),
                                _c("li", [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "atmo-button-inverse atmo-dimmed",
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearContractTemplateForm(
                                            piuc
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Очистить")]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("editable-file-attachments-list-controller", {
                        attrs: {
                          initiallist:
                            piuc.inputs.notice_supplementary_attachments,
                          origin: "notice",
                        },
                        on: {
                          "fileattachment:changed":
                            piuc.fileAttachmentEventHandler,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (efalc) {
                                return [
                                  _c(
                                    "EditableFileAttachmentsList.view",
                                    { attrs: { controller: efalc } },
                                    [
                                      _c("h3", [
                                        _vm._v("Дополнительные документы"),
                                      ]),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("ul", { staticClass: "atmo-buttons" }, [
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button-inverse atmo-dimmed",
                              on: {
                                click: function ($event) {
                                  return piuc.setTab("terms")
                                },
                              },
                            },
                            [_vm._v("Назад")]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button atmo-accented",
                              on: { click: piuc.saveDraft },
                            },
                            [_vm._v("Сохранить черновик")]
                          ),
                        ]),
                        _c("li", [
                          _c(
                            "button",
                            {
                              staticClass: "atmo-button",
                              attrs: {
                                disabled:
                                  piuc.hasErrors ||
                                  !_vm.$store.getters.getCanSign,
                              },
                              on: { click: piuc.invokeSavePurchasePopup },
                            },
                            [_vm._v("Подписать и сохранить")]
                          ),
                        ]),
                        !_vm.$store.getters.getCanSign
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "text-initialcase text-danger pl-2 pt-1",
                              },
                              [
                                _vm._v(
                                  "Подписание возможно только при авторизации при помощи ЭЦП"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }