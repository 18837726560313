<template>
    <b-form-group :label="label + ':'" :description="description" :state="state" class="w-100" label-cols-sm="4" label-cols-lg="3" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <currency-input v-stop-number-mousewheel v-model="text" class="form-control fs-14" currency="RUB" locale="ru" :class="v !== null ? (state ? 'is-valid' : 'is-invalid') : ''" />
        <template v-if="!state">
            <b-form-invalid-feedback force-show>{{ errorMessage }}</b-form-invalid-feedback>
        </template>
    </b-form-group>
</template>

<script>
export default {
    name: 'FormRowEditPrice',
    props: {
        label: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 0
        },
        v: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: null
        }
    },
    computed: {
        text: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            }
        },
        stateClass() {
            if (this.disabled) return null;
            if (this.state === null) return null;
            return this.state ? 'is-valid' : 'is-invalid';
        },
        state() {
            return this.v !== null ? !this.v.$invalid && !this.errorMessage : null;
        },
        errorMessage() {
            if (this.v && !this.v.required && !!this.v.$params.required) return 'Поле обязательно для заполнения';
            if (this.v && this.value === 0) return 'Поле "' + this.label + '" должно быть больше 0';
            if (this.limit !== null && this.value > this.limit) return 'Поле "' + this.label + '" превышает доступный лимит в ' + this.limit + ' руб.';
            return '';
        }
    }
};
</script>
