<template>
    <div id="atmo-deliverables" class="atmo-content-group table-responsive">
        <table class="atmo-content-table">
            <thead>
                <tr>
                    <th>Рег.номер ТРУ</th>
                    <th>Код ОКПД2</th>
                    <th>Наименование товара (работ, услуг)</th>
                    <th>Характеристики ТРУ</th>
                    <th>Ед.изм</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(deliverable, index) in this.$props.deliverables" v-bind:key="index">
                    <td v-text="deliverable.gsc_details.registration_number"></td>
                    <td v-text="deliverable.okpd_code"></td>
                    <td v-text="deliverable.title"></td>

                    <DeliverableRequirements.view v-bind:requirements="deliverable.requirements" v-bind:description="deliverable.gsc_details ? deliverable.gsc_details.description : null" />

                    <td v-text="deliverable.okei_symbolic_code"></td>
                    <td>
                        <button v-on:click="$props.controller.selected(index)" class="atmo-button-icon" title="Добавить спецификацию в закупку"><i class="atmo-icon-add-to-list"></i></button>
                    </td>
                </tr>
                <tr v-if="this.isEmpty" class="atmo-centered">
                    <td colspan="6">
                        <span class="atmo-error">Позиции КТРУ по вашему запросу не найдены.</span>
                        <span>
                            Вы можете
                            <router-link v-bind:to="{ name: 'KtruRequestsCreate' }" class="atmo-button-link" target="_blank">сформировать запрос на добавление позиции КТРУ</router-link>.</span
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DeliverableRequirementsView from 'themes/views/pages/purchases/item/common/partials/deliverables/view/DeliverableRequirements.view.vue';

export default {
    computed: {
        isEmpty: function () {
            return this.$props.deliverables.length < 1;
        }
    },
    props: {
        controller: { required: true, type: Object },
        deliverables: { required: true, type: Array }
    },
    components: {
        'DeliverableRequirements.view': DeliverableRequirementsView
    }
};
</script>
