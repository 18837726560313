import axios from 'axios';

export default {
    getOrderType() {
        return axios.get('/directories/order-types').then((response) => {
            return response.data;
        });
    },
    getOrderTypes() {
        return axios.get('/directories/order-types');
    },
    getNationalProjects() {
        return axios.get('/directories/national_projects').then((response) => {
            return response.data;
        });
    },
    getOkeis() {
        return axios.get('/directories/okeis');
    },
    findOkeis(query) {
        return axios.get('/directories/okeis_find', { params: { query: query } });
    },
    getOkpds() {
        return axios.get('/directories/okpds');
    },
    findOkpds(query) {
        return axios.get('/directories/okpds_find', { params: { query: query } });
    },
    getDocumentType() {
        return axios.get('/directories/document-types');
    },
    getKtruGroups() {
        return axios.get('/directories/ktruGroups').then((response) => {
            return response.data;
        });
    },
    getKtruGroupsSelectItems() {
        return axios.get('/directories/ktruGroups');
    },
    getKtruTypes() {
        return axios.get('/directories/ktruTypes');
    },
    getExternalPurchaseCategories() {
        return axios.get('/directories/external_purchase_categories');
    },
    getKtruItem(id) {
        return axios.get('/directories/ktru_items/' + id);
    },
    getPurchaseCategories() {
        return axios.get('/directories/purchase_categories');
    },
    getMunicipalities() {
        return axios.get('/directories/municipalities');
    },
    getBudgetaryManagers() {
        return axios.get('/directories/budgetary_managers');
    },
    getOkopfs() {
        return axios.get('/directories/okopfs');
    },
    getFnsSystems() {
        return axios.get('/directories/fns_systems');
    }
};
