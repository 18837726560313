<template>
    <b-form-group label-cols-sm="4" label-cols-lg="3" :description="description" :label="label + ':'" :label-for="id" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
        <p :id="id" class="fs-14 grey-color my-auto col-form-label">{{ $formatPrice(value) }} руб.</p>
    </b-form-group>
</template>

<script>
export default {
    name: 'text-row-price',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    },
    computed: {
        id() {
            return 'text_row_price_' + this._uid;
        }
    }
};
</script>

<style scoped>
.form-row {
    margin-left: 0;
    margin-right: 0;
}
</style>
