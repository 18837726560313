<template>
    <b-container fluid class="footer">
        <b-container>
            <b-row>
                <b-col>
                    <div class="d-flex flex-column align-items-start">
                        <router-link v-bind:to="{ name: 'purchases.grouped', params: { group: 'fl44' } }" class="fs-10 white-color text-uppercase text-decoration-none my-1"> ИЗВЕЩЕНИЯ </router-link>
                        <router-link v-bind:to="{ name: 'contracts.grouped', params: { group: 'fl44' } }" class="fs-10 white-color text-uppercase text-decoration-none my-1"> ДОГОВОРЫ </router-link>
                        <router-link to="/quotation-requests" class="fs-10 white-color text-uppercase text-decoration-none my-1"> ЗАПРОСЫ КП </router-link>
                        <router-link to="/clients" class="fs-10 white-color text-uppercase text-decoration-none my-1"> ЗАКАЗЧИКИ И ПОСТАВЩИКИ </router-link>
                    </div>
                </b-col>
                <b-col>
                    <div class="d-flex flex-column align-items-start">
                        <!-- Add .atmo-override-link-off class to switch off link in themes  -->
                        <a :href="$links.info_materials" target="_blank" class="atmo-override-link-off fs-10 white-color text-uppercase text-decoration-none my-1"> ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ </a>
                        <!-- Add .atmo-override-link-off class to switch off link in themes  -->
                        <a :href="$links.legal_documents" target="_blank" class="atmo-override-link-off fs-10 white-color text-uppercase text-decoration-none my-1"> Правовые документы </a>
                        <router-link to="/articles" class="fs-10 white-color text-uppercase text-decoration-none my-1"> НОВОСТИ </router-link>
                    </div>
                </b-col>
                <b-col cols="12" sm="auto">
                    <b-row class="flex-row-568">
                        <b-col cols="6" md="12" class="d-flex flex-column align-items-start mt-568">
                            <p class="fs-10 white-color text-uppercase text-decoration-none my-1">Режим работы</p>
                            <span class="fs-10 white-color text-decoration-none my-1"> 9:00 до 18:00 (по времени г. {{ theme.variables.view.city }}) </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="fs-10 white-color text-decoration-none my-2 py-2" v-bind:title="`v${theme.version.full}`" style="cursor: help"> v{{ theme.version.core }} &copy; {{ theme.variables.view.copyright_name }} 2023 </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<style scoped>
.footer {
    background-color: #959ba4;
    padding-top: 26px;
    padding-bottom: 26px;
}

.mt-568 {
    margin-top: 0px;
}
.feed_footer:hover {
    color: var(--green-color);
}

@media screen and (max-width: 568px) {
    .footer {
        padding-top: 22px;
    }

    a,
    p,
    span {
        font-size: 8px !important;
    }

    a,
    p,
    span {
        margin: 6px 0 !important;
    }

    .mt-568 {
        margin-top: 12px;
    }

    .flex-row-568 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
</style>

<script>
import { mapGetters } from 'vuex';
import { __LINKS } from '@/const';
import Config from '@core/js/ddriven/application/config/Config';

export default {
    name: 'LayoutFooter',
    computed: {
        theme() {
            return Config.get('theme');
        },
        ...mapGetters(['appVersion']),
        links() {
            return __LINKS;
        }
    }
};
</script>
