export default [
    {
        path: '/clients',
        redirect: { name: 'Customers' }
    },
    {
        path: '/clients/customers',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "main" */ '@/components/clients/Customer')
    },
    {
        path: '/clients/providers',
        name: 'Providers',
        component: () => import(/* webpackChunkName: "main" */ '@/components/clients/Provider')
    },
    {
        path: '/clients/customers/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/clients/CustomerShow'),
        name: 'CustomerShow'
    },
    {
        path: '/clients/providers/:id',
        component: () => import(/* webpackChunkName: "main" */ '@/components/clients/ProviderShow'),
        name: 'ProviderShow'
    }
];
