<template>
    <b-modal :visible="show" hide-footer no-close-on-backdrop no-close-on-esc size="xl" title="Спецификация" @cancel="cancelSpecModal" @close="cancelSpecModal">
        <b-row>
            <filter-text v-model="ktruTableFilter.reg_number" label="Рег.номер" placeholder="Введите рег.номер" @input="onKtruFilterChange" />
            <filter-text v-model="ktruTableFilter.name" label="Название" placeholder="Введите название" @input="onKtruFilterChange" />
            <filter-text v-model="ktruTableFilter.specs" label="Характеристики" placeholder="Введите текст характеристик" @input="onKtruFilterChange" />
            <filter-text v-model="ktruTableFilter.okpd_code" label="Код ОКПД2" placeholder="Введите код ОКПД2 или его часть" @input="onKtruFilterChange" />
            <filter-multiple-select-for-popup v-model="ktruTableFilter.country_of_origin_id" :options="forFilters.countries" label="Страна происхождения" placeholder="Выберите страну происхождения" @input="onKtruFilterChange" />
        </b-row>
        <div class="table-responsive">
            <b-table-simple class="table-atmo">
                <thead>
                    <tr>
                        <th style="width: 120px">Рег.номер</th>
                        <th style="width: 120px">ОКПД2</th>
                        <th style="min-width: 400px; width: 400px">Название</th>
                        <th style="min-width: 300px">Описание/характеристики</th>
                        <th style="min-width: 100px">Ед.изм</th>
                        <th style="min-width: 100px">Страна происхождения</th>
                        <th style="width: 100px">Действия</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in ktruItems" :key="item.id">
                        <td>{{ item.reg_number }}</td>
                        <td>{{ item.okpd_code }}</td>
                        <td>{{ item.name }}</td>
                        <td>
                            <b-button v-b-toggle="'collapse-' + item.id" size="sm" variant="custom-green">{{ visible[item.id] ? 'Скрыть' : 'Показать' }} описание/характеристики</b-button>
                            <b-collapse :id="'collapse-' + item.id" v-model="visible[item.id]" class="mt-2">
                                <p v-if="item.description"><b>Описание:</b> {{ item.description }}</p>
                                <p v-if="item.specs && item.specs.length > 0"><b>Характеристики:</b></p>
                                <ul v-if="item.specs && item.specs.length > 0">
                                    <li v-for="(spec, key) in item.specs" v-bind:key="'spec' + key">
                                        <span style="font-weight: 900">{{ spec.Name }}</span
                                        >: {{ spec.Value }}
                                    </li>
                                </ul>
                            </b-collapse>
                        </td>
                        <td>{{ item.okei_local_symbol }}</td>
                        <td>{{ item.country_of_origin_name }}</td>
                        <td>
                            <b-btn size="sm" variant="custom-green" @click="selectKtruItem(item)">Выбрать</b-btn>
                        </td>
                    </tr>
                    <tr v-if="ktruItems.length === 0">
                        <td class="text-center text-danger" colspan="7">
                            Позиции КТРУ по вашему запросу не найдены. Вы можете
                            <router-link :to="{ name: 'KtruRequestsCreate' }" target="_blank">сформировать запрос на добавление позиции КТРУ</router-link>
                            .
                        </td>
                    </tr>
                </tbody>
            </b-table-simple>
        </div>
        <b-pagination v-if="ktruItems.length" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" style="padding-bottom: 0" @input="getKtruItems()" />
    </b-modal>
</template>

<script>
import axios from 'axios';
import FilterText from '@/components/common/filter-components/filter-text';
import FilterMultipleSelectForPopup from '@/components/common/filter-components/filter-multiple-select-for-popup';

export default {
    name: 'PurchaseSpecificationModal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        isImportSubstitutionRequest: {
            type: Boolean,
            default: false
        },
        isImportSubstitutionReply: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FilterMultipleSelectForPopup,
        FilterText
    },
    data() {
        return {
            currentPage: 1,
            perPage: 5,
            ktruItems: [],
            ktruSelectModalShown: false,
            selectedKtruItem: null,
            selectedKtruItemId: null,
            visible: [],
            spec: {
                number: 1,
                order: 1,
                name: '',
                count: 0,
                okei: null,
                ktru_item_id: null,
                ktru: null,
                okpd: null
            },
            forFilters: {
                countries: []
            },
            sortOrder: [{ field: 'id', direction: 'asc' }],
            ktruTableFilter: {
                name: '',
                reg_number: '',
                specs: '',
                okpd_code: '',
                country_of_origin_id: []
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1
            }
        };
    },
    async created() {
        this.$_debounceTimer = null;
        await this.fillFilters();
        await this.getKtruItems();
    },
    methods: {
        async fillFilters() {
            const response = await this.$api.dictionaries.countries.getSelectItems();
            this.forFilters.countries = response.data;
        },
        getFilters(page = true) {
            return {
                filter: {
                    ...this.ktruTableFilter,
                    is_import_substitution_request: this.isImportSubstitutionRequest,
                    is_import_substitution_reply: this.isImportSubstitutionReply
                },
                page: page ? this.meta.current_page : 1
            };
        },
        async getKtruItems(page = true, loading = true) {
            this.loading = loading;
            const ktruItemsData = await axios.get('/ktru', { params: this.getFilters(page) });
            this.ktruItems = ktruItemsData.data.data;
            this.meta = ktruItemsData.data.meta;
            this.loading = false;
        },

        debounce(method, timer) {
            if (this.$_debounceTimer !== null) {
                clearTimeout(this.$_debounceTimer);
            }
            this.$_debounceTimer = setTimeout(() => {
                method();
            }, timer);
        },
        cancelKtruSelectModal() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.ktruSelectModalShown = false;
        },
        showKtruSelectModal() {
            this.ktruSelectModalShown = true;
        },
        cancelSpecModal() {
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.clearSelectedKtru();
            this.$emit('cancel');
        },
        addSpec() {
            this.$emit('add', this.spec);
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.cancelKtruSelectModal();
        },
        selectKtruItem(selectedItem) {
            this.selectedKtruItem = selectedItem;
            this.selectedKtruItemId = this.selectedKtruItem.id;
            this.spec.name = this.selectedKtruItem.name;
            this.spec.okei = this.selectedKtruItem.okei;
            this.spec.okpd = this.selectedKtruItem.okpd;
            this.spec.ktru = this.selectedKtruItem;
            this.spec.ktru_item_id = this.selectedKtruItem.id;
            this.ktruSelectModalShown = false;
            this.$emit('add', this.spec);
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
            this.cancelKtruSelectModal();
        },
        clearSelectedKtru() {
            this.selectedKtruItem = null;
            this.selectedKtruItemId = null;
            this.spec = { number: 1, order: 1, name: '', count: 0, okei: null, ktru_item_id: null, ktru: null, okpd: null, price: 0 };
        },
        onKtruFilterChange() {
            this.meta.current_page = 1;
            this.getKtruItems();
        }
    }
};
</script>

<style>
.modal-xl {
    max-width: 95%;
}
</style>
