var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isDevEnv()
        ? _c(
            "div",
            {
              staticClass: "text-center",
              staticStyle: { "background-color": "#f8c8c3", padding: "0 5px" },
            },
            [
              _c(
                "b-container",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c("strong", [
                    _vm._v(
                      "ВНИМАНИЕ!!! Это тестовая площадка " +
                        _vm._s(
                          _vm.theme.variables.view.application_name_short
                        ) +
                        ". Основной адрес " +
                        _vm._s(
                          _vm.theme.variables.view.application_name_short
                        ) +
                        " - "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.theme.variables.view.production_url,
                        },
                      },
                      [_vm._v(_vm._s(_vm.theme.variables.view.production_url))]
                    ),
                  ]),
                  _c("span", { staticClass: "grey-color fs-12" }, [
                    _vm._v("v" + _vm._s(_vm.theme.version.full)),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { staticClass: "top_info-line", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            { staticStyle: { "max-height": "100px" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "w-100",
                        staticStyle: {
                          height: "36px",
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center w-100",
                          },
                          [
                            _c("div", { staticClass: "d-flex times" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "today",
                                  staticStyle: { "margin-right": "42px" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "grey-color fs-12" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.localDate) +
                                          " (" +
                                          _vm._s(_vm.weekday) +
                                          ")"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "local_time mr-4" }, [
                                _c("img", {
                                  attrs: {
                                    alt: "clock",
                                    src: "/images/clock.svg",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "ml-2 grey-color fs-12" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.theme.variables.view.city) +
                                        " — " +
                                        _vm._s(_vm.localTime)
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "moscow_time ml-1" }, [
                                _c("img", {
                                  attrs: {
                                    alt: "clock",
                                    src: "/images/clock.svg",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticClass: "ml-2 grey-color fs-12" },
                                  [_vm._v("Москва — " + _vm._s(_vm.moscowTime))]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "d-flex ml-auto center-768" },
                              [
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "atmo-override-link-off grey-color fs-12 fw-600 text-nowrap cursor-pointer",
                                      attrs: {
                                        href: _vm.$links.legal_documents,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("ПРАВОВЫЕ ДОКУМЕНТЫ")]
                                  ),
                                ]),
                                _c("div", { staticClass: "ml-4" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "atmo-override-link-off grey-color fs-12 fw-600 text-nowrap cursor-pointer",
                                      attrs: {
                                        href: _vm.$links.info_materials,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("ИНФОРМАЦИОННЫЕ МАТЕРИАЛЫ")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "nav_line", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            { staticClass: "py-4" },
            [
              _c(
                "b-row",
                { staticClass: "d-flex flex-nowrap" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticStyle: { border: "none !important" },
                          attrs: { to: "/" },
                        },
                        [_c("ThemeLogo")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "h-100 fast_links" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link right_line",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-nowrap",
                                  class:
                                    _vm.$route.name === "purchases.grouped" &&
                                    "router-link-active",
                                  attrs: {
                                    to: {
                                      name: "purchases.grouped",
                                      params: { group: "fl44" },
                                      query: { status: 1 },
                                    },
                                  },
                                },
                                [_vm._v("ИЗВЕЩЕНИЯ ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link right_line",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-nowrap",
                                  class:
                                    _vm.$route.name === "contracts.grouped" &&
                                    "router-link-active",
                                  attrs: {
                                    to: {
                                      name: "contracts.grouped",
                                      params: { group: "fl44" },
                                    },
                                  },
                                },
                                [_vm._v("ДОГОВОРЫ ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link right_line",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-nowrap",
                                  attrs: { to: "/quotation-requests" },
                                },
                                [_vm._v("ЗАПРОСЫ КП")]
                              ),
                            ],
                            1
                          ),
                          [
                            _vm.$links.marketplace_link
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center desktop_link right_line",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-center",
                                        attrs: {
                                          href: _vm.$links.marketplace_link,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("Башмаркет")]
                                    ),
                                  ]
                                )
                              : _c(
                                  "b-col",
                                  {
                                    staticClass:
                                      "d-flex align-items-center desktop_link right_line",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-center",
                                        attrs: {
                                          to: {
                                            name: "ImportSubstitutionRequestsList",
                                            params: { path: "open" },
                                          },
                                        },
                                      },
                                      [_vm._v("Импортозамещение")]
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center desktop_link",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "text-center",
                                  attrs: { to: "/clients" },
                                },
                                [
                                  _vm._v("Заказчики и"),
                                  _c("br"),
                                  _vm._v("поставщики"),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "cursor-pointer grey-color",
                                  class: {
                                    "fas fa-times": _vm.menuVisible,
                                    "fas fa-bars": !_vm.menuVisible,
                                  },
                                  staticStyle: { width: "15px" },
                                  attrs: { "aria-controls": "full-menu" },
                                  on: {
                                    click: function ($event) {
                                      _vm.menuVisible = !_vm.menuVisible
                                    },
                                  },
                                },
                                [_c("span"), _c("span"), _c("span")]
                              ),
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "d-flex align-items-center fast_auth",
                              staticStyle: { "margin-left": "35px" },
                            },
                            [
                              !_vm.$store.state.token
                                ? [
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass:
                                          "text-nowrap text-uppercase",
                                        attrs: { variant: "custom-danger" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showModal = true
                                          },
                                        },
                                      },
                                      [_vm._v("Регистрация")]
                                    ),
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass:
                                          "text-nowrap text-uppercase ml-2",
                                        attrs: { variant: "custom-green" },
                                        on: { click: _vm.goToLogin },
                                      },
                                      [_vm._v("Вход в ЛК")]
                                    ),
                                  ]
                                : [
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass:
                                          "text-nowrap text-uppercase",
                                        attrs: {
                                          to: { name: "Cabinet" },
                                          exact: "",
                                          variant: "custom-green",
                                        },
                                      },
                                      [_vm._v("Личный кабинет")]
                                    ),
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass:
                                          "text-nowrap text-uppercase ml-2",
                                        attrs: {
                                          variant: "custom-outline-secondary",
                                        },
                                        on: { click: _vm.logout },
                                      },
                                      [_vm._v("Выйти")]
                                    ),
                                  ],
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Login", {
        attrs: { "show-modal": _vm.showLoginModal },
        on: { close: _vm.onCloseLoginModal },
      }),
      _c(
        "b-collapse",
        {
          staticClass: "full-screen",
          attrs: { id: "full-menu" },
          model: {
            value: _vm.menuVisible,
            callback: function ($$v) {
              _vm.menuVisible = $$v
            },
            expression: "menuVisible",
          },
        },
        [
          _c(
            "div",
            [
              _vm.windowWidth >= 768
                ? _c(
                    "b-container",
                    { staticClass: "top_info-line py-4", attrs: { fluid: "" } },
                    [
                      _c("full-menu", {
                        on: {
                          "hide-menu": function ($event) {
                            _vm.menuVisible = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.windowWidth < 768
                ? _c(
                    "b-container",
                    { staticClass: "top_info-line py-4", attrs: { fluid: "" } },
                    [
                      _c("mobile-menu", {
                        on: {
                          "hide-menu": function ($event) {
                            _vm.menuVisible = false
                          },
                          "show-modal": function ($event) {
                            _vm.showModal = true
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.menuVisible
        ? _c("div", {
            staticClass: "backdrop",
            on: { click: _vm.toggleFullMenu },
          })
        : _vm._e(),
      _c("RegisterModal", {
        attrs: { "show-modal": _vm.showModal },
        on: {
          close: function ($event) {
            _vm.showModal = false
          },
        },
      }),
      _vm.visibleVerificationEmailModal
        ? [
            _c("VerificationEmailModal", {
              attrs: {
                email: _vm.userEmail,
                visible: _vm.visibleVerificationEmailModal,
              },
              on: { hidden: _vm.hideVerificationModal },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }