<template>
    <contract-annex-dispute-resolution-controller v-slot:default="cadrc" v-bind:contract="this.$props.pivc.purchaseitem.contract" v-bind:user="this.$props.pivc.user">
        <div class="atmo-component-wrapper">
            <ContractAnnexUpdate.view v-if="$props.pivc.isUserEngagedCustomer && $props.annex.hasUnresolvedDisputes()" v-bind:mode="'edit'" v-bind:pivc="$props.pivc" v-bind:disputecontroller="cadrc" />

            <editable-file-attachments-list-controller v-slot:default="efalc" v-bind:origin="'dispute'" v-bind:isrequired="true" v-bind:maxfilesallowed="1" v-on:fileattachment:changed="cadrc.handleAttachment">
                <EditableFileAttachmentsList.view v-bind:controller="efalc">
                    <h5>Добавьте <span v-if="$props.pivc.isUserEngagedCustomer">ответ на</span> протокол разногласий</h5>
                </EditableFileAttachmentsList.view>
            </editable-file-attachments-list-controller>

            <section class="atmo-form__field atmo-inverse">
                <label for="atmo-dispute-comment"
                    >Комментарий к
                    <span v-if="$props.pivc.isUserEngagedCustomer">ответу на протокол</span>
                    <span v-else>протоколу</span>
                    разногласий:
                </label>
                <textarea v-model="cadrc.comment" id="atmo-dispute-comment" v-bind:class="{ 'atmo-empty': cadrc.isCommentEmpty }" cols="2"></textarea>
            </section>

            <div class="atmo-buttons">
                <button v-if="$props.pivc.isUserEngagedSupplier" v-on:click.stop="cadrc.invokeSignContractAnnexDisputePopup" v-bind:disabled="cadrc.isCommentEmpty || cadrc.isDisputeAttachmentEmpty || !$store.getters.getCanSign" class="atmo-button">
                    Подписать протокол разногласий и отправить заказчику
                </button>

                <button v-if="$props.pivc.isUserEngagedCustomer" v-on:click.stop="cadrc.invokeSignContractAnnexDisputePopup" v-bind:disabled="cadrc.isCommentEmpty || cadrc.isAnnexAttachmentEmpty || cadrc.isAnnexEditableEmpty || !$store.getters.getCanSign" class="atmo-button">
                    Подписать ответ на протокол разногласий и отправить поставщику
                </button>
                <span v-if="!$store.getters.getCanSign" class="text-initialcase text-danger pl-2 pt-1">Подписание возможно только при авторизации при помощи ЭЦП</span>
            </div>
        </div>
    </contract-annex-dispute-resolution-controller>
</template>

<script>
import EditableFileAttachmentsListController from '@core/js/viewmodels/common/attachment/EditableFileAttachmentsListController.viewmodel';
import ContractAnnexDisputeResolutionController from '@core/js/viewmodels/purchases/item/view/widgets/contract/annexes/ContractAnnexDisputeResolutionController.viewmodel';

import EditableFileAttachmentsListView from 'themes/views/pages/purchases/item/common/partials/EditableFileAttachmentsList.view.vue';
import ContractAnnexUpdateView from 'themes/views/pages/purchases/item/view/partials/update/annexes/ContractAnnexUpdate.view.vue';

export default {
    props: {
        pivc: { required: true, type: Object },
        annex: { required: true, type: Object }
    },
    components: {
        'editable-file-attachments-list-controller': EditableFileAttachmentsListController,
        'contract-annex-dispute-resolution-controller': ContractAnnexDisputeResolutionController,

        'EditableFileAttachmentsList.view': EditableFileAttachmentsListView,
        'ContractAnnexUpdate.view': ContractAnnexUpdateView
    }
};
</script>

<style scoped>
.text-initialcase {
    text-transform: initial;
}
</style>
