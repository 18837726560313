<template>
    <entity-list-page-controller v-slot:default="elpc">
        <div class="atmo-page-entities-list">
            <!-- // WARNING: This is basic purchase group (Federal Law 44 | 223) menu control. -->
            <!-- // WARNING: Should be uncommented and used when FL223 is introduced into planned relase. -->
            <!-- <section class="atmo-entity-groups-menu atmo-container-fixed">
                <router-link v-bind:to="{name: 'purchases.grouped', params: {group: 'fl44'}, query: {status: 1}}"
                    v-bind:class="$route.params.group === 'fl44' && 'router-link-active'">44-ФЗ</router-link>&nbsp;
                <router-link v-bind:to="{name: 'purchases.grouped', params: {group: 'fl223'}, query: {status: 1}}"
                    v-bind:class="$route.params.group === 'fl223' && 'router-link-active'">223-ФЗ</router-link>
            </section> -->

            <section class="atmo-page-banner-wrapper">
                <div class="atmo-container-fixed">
                    <h2>Реестр извещений <span v-if="elpc.group === 'fl44'">44-ФЗ</span><span v-if="elpc.group === 'fl223'">223-ФЗ</span></h2>
                    <PurchaseFiltersView v-bind:elpc="elpc"></PurchaseFiltersView>
                </div>
            </section>

            <entity-status-menu-controller v-slot:default="esmc" v-bind:route="elpc.storeprefix" v-bind:statuses="elpc.dictionaries.menustatuses" v-bind:currentstatus="elpc.currentstatus" v-on:status:update="elpc.processRequestUpdate">
                <responsive-on-off v-slot:default="ronoff">
                    <div class="atmo-container-fixed">
                        <div class="atmo-status-menu-controller">
                            <button v-on:click="ronoff.toggle" class="atmo-onoff"><i class="atmo-icon-burger"></i>&nbsp;<span v-text="esmc.currentTitle"></span></button>

                            <ul v-show="ronoff.ison" class="atmo-menu">
                                <li v-for="menuitem in esmc.statuses" v-bind:key="menuitem.id" v-on:click="ronoff.off">
                                    <button v-bind:disabled="esmc.isDisabledMenuItem(menuitem.id)" v-on:click="esmc.status(menuitem.id)">
                                        <span v-text="menuitem.title"></span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </responsive-on-off>
            </entity-status-menu-controller>

            <StandardPaginationView v-bind:pagination="elpc.pagination" v-bind:pageupdatehandler="elpc.processRequestUpdate" />

            <ul class="atmo-container-fixed">
                <li v-for="purchaseitem in elpc.list" v-bind:key="purchaseitem.id" class="atmo-entity-item">
                    <purchase-item v-slot:default="pi" v-bind:elpc="elpc" v-bind:purchaseitem="purchaseitem">
                        <PurchaseItemView v-bind:pi="pi"></PurchaseItemView>
                    </purchase-item>
                </li>

                <li v-if="elpc.isListEmpty">
                    <figure class="atmo-notfound-image">
                        <img src="/images/common/other/empty-box.svg" alt="Список пуст" />
                        <figcaption>По вашему запросу ничего не найдено</figcaption>
                    </figure>
                </li>
            </ul>

            <StandardPaginationView v-bind:pagination="elpc.pagination" v-bind:pageupdatehandler="elpc.processRequestUpdate" />
        </div>
    </entity-list-page-controller>
</template>

<script>
import EntityListPageController from '@core/js/viewmodels/common/entity/EntityListPageController.viewmodel.ts';
import EntityStatusMenuController from '@core/js/viewmodels/common/entity/EntityStatusMenuController.viewmodel.ts';
import PurchaseItem from '@core/js/viewmodels/purchases/list/view/PurchaseItem.viewmodel.ts';
import ResponsiveOnOff from '@lib/js/src/vue/components/ResponsiveOnOff.viewmodel.ts';

import StandardPaginationView from '@core/views/layouts/common/StandardPagination.view.vue';
import PurchaseFiltersView from './partials/PurchaseFilters.view.vue';
import PurchaseItemView from './partials/PurchaseItem.view.vue';

export default {
    metaInfo: {
        title: 'Реестр извещений'
    },
    components: {
        StandardPaginationView,
        PurchaseFiltersView,
        PurchaseItemView,
        'entity-list-page-controller': EntityListPageController,
        'entity-status-menu-controller': EntityStatusMenuController,
        'purchase-item': PurchaseItem,
        'responsive-on-off': ResponsiveOnOff
    }
};
</script>
