'use strict';

import { AxiosResponse } from 'axios';

import HTTPRESTAPIFacade from '@core/js/ddriven/application/ports/adapters/http/outgoing/HTTPRESTAPIFacade';
import { IATMOAPIs } from '../ATMOAPIFacade';
import KBKDictionaryFilteredRequestVO from '@core/js/ddriven/application/http/requests/dictionaries/KBKDictionaryFilteredRequest.valueobject';

export default class DictionariesAPIFacadePartial {
    legacy: HTTPRESTAPIFacade;
    v1: HTTPRESTAPIFacade;

    constructor(apis: IATMOAPIs) {
        this.legacy = apis.legacy;
        this.v1 = apis.v1;
    }

    public async loadPurchaseTypes(): Promise<AxiosResponse> {
        const path = '/directories/order-types';

        return await this.legacy.call('GET', path);
    }

    public async loadDeliverablesGroups(): Promise<AxiosResponse> {
        const path = '/directories/purchase_categories';

        return await this.legacy.call('GET', path);
    }

    public async loadMunicipalities(): Promise<AxiosResponse> {
        const path = '/directories/municipalities';

        return await this.legacy.call('GET', path);
    }

    public async loadUserAddressBook(): Promise<AxiosResponse> {
        const path = '/organizations/addresses';

        return await this.legacy.call('GET', path);
    }

    public async loadDeliverablesFiltered(query?: string): Promise<AxiosResponse> {
        const queryPrepared = query ? `?${query}` : '?filter';
        const path = `/ktru${queryPrepared}`;

        return await this.legacy.call('GET', path);
    }

    public async loadKBKLimitsFiltered(request?: KBKDictionaryFilteredRequestVO): Promise<AxiosResponse> {
        const query = request ? `?${request.toQueryString()}` : '';
        const path = `/dictionaries/organization/limits${query}`;

        return await this.v1.call('GET', path);
    }

    public async loadDeclineReasons(): Promise<AxiosResponse> {
        const path = '/offer/get_decline_statuses';

        return await this.legacy.call('GET', path);
    }
}
