var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.show,
            title: "Спецификация",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
          on: { close: _vm.cancelSpecModal, cancel: _vm.cancelSpecModal },
        },
        [
          _c(
            "b-row",
            { staticClass: "py-3" },
            [
              _c(
                "b-col",
                {
                  staticClass: "fs-14 grey-color d-flex align-items-center",
                  attrs: { cols: "3" },
                },
                [_c("p", { staticClass: "m-0" }, [_vm._v("ТРУ из каталога")])]
              ),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  !_vm.selectedKtruItem
                    ? _c("b-btn", { on: { click: _vm.showKtruSelectModal } }, [
                        _vm._v("Выбрать ТРУ"),
                      ])
                    : _vm._e(),
                  _vm.selectedKtruItem
                    ? _c("b-btn", { on: { click: _vm.clearSelectedKtru } }, [
                        _vm._v("Сбросить"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedKtruItem
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Группа ТРУ:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.selectedKtruItem.ktru_group_name))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Рег.номер:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.selectedKtruItem.reg_number))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "3" } },
                        [_vm._v("Наименование:")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [_vm._v(_vm._s(_vm.selectedKtruItem.name))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "py-3" },
            [
              _c(
                "b-col",
                {
                  staticClass: "fs-14 grey-color d-flex align-items-center",
                  attrs: { cols: "3" },
                },
                [
                  _c("p", { staticClass: "m-0" }, [
                    _vm._v("Наименование товара/работы/услуги"),
                  ]),
                ]
              ),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("b-textarea", {
                    attrs: {
                      rows: "5",
                      disabled: !!_vm.selectedKtruItem,
                      state: _vm.$v.spec.name.$invalid
                        ? !_vm.$v.spec.name.$invalid
                        : null,
                      "aria-describedby": "formdata_name_liveFeedback",
                    },
                    model: {
                      value: _vm.spec.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.spec, "name", $$v)
                      },
                      expression: "spec.name",
                    },
                  }),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: "formdata_name_liveFeedback",
                        state: !_vm.$v.spec.name.$invalid,
                      },
                    },
                    [_vm._v("Поле не заполнено, минимум 3 символа")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "py-3" },
            [
              _c(
                "b-col",
                {
                  staticClass: "fs-14 grey-color d-flex align-items-center",
                  attrs: { cols: "3" },
                },
                [_c("p", { staticClass: "m-0" }, [_vm._v("ОКПД2")])]
              ),
              _c(
                "b-col",
                { staticClass: "create-purchase-select", attrs: { cols: "9" } },
                [
                  _c(
                    "multiselect",
                    {
                      class: _vm.$v.spec.okpd.$invalid ? "is-invalid" : "",
                      attrs: {
                        placeholder: "Начните вводить для поиска",
                        "deselect-label": "Отменить",
                        "selected-label": "Выбрано",
                        "select-label": "Выбрать",
                        "track-by": "id",
                        label: "breadcrumb",
                        options: _vm.okpds,
                        searchable: true,
                        "allow-empty": false,
                        loading: _vm.isOkpdsLoading,
                        disabled: !!_vm.selectedKtruItem,
                        "internal-search": false,
                        "clear-on-select": true,
                        "close-on-select": true,
                        "options-limit": 300,
                        limit: 3,
                        "max-height": 600,
                        "show-no-results": false,
                        "hide-selected": true,
                        state: _vm.$v.spec.okpd.$invalid
                          ? !_vm.$v.spec.okpd.$invalid
                          : null,
                        "aria-describedby": "formdata_okpd_liveFeedback",
                      },
                      on: { "search-change": _vm.asyncFindOkpds },
                      model: {
                        value: _vm.spec.okpd,
                        callback: function ($$v) {
                          _vm.$set(_vm.spec, "okpd", $$v)
                        },
                        expression: "spec.okpd",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Нет данных.")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [
                          _vm._v(
                            "Записей, соответствующих вашему запросу, не найдено."
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: "formdata_okpd_liveFeedback",
                        state: !_vm.$v.spec.okpd.$invalid,
                      },
                    },
                    [_vm._v("Выберите ОКПД2")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "py-3" },
            [
              _c(
                "b-col",
                {
                  staticClass: "fs-14 grey-color d-flex align-items-center",
                  attrs: { cols: "3" },
                },
                [_c("p", { staticClass: "m-0" }, [_vm._v("Единица измерения")])]
              ),
              _c(
                "b-col",
                { staticClass: "create-purchase-select", attrs: { cols: "9" } },
                [
                  _c(
                    "multiselect",
                    {
                      class: _vm.$v.spec.okei.$invalid ? "is-invalid" : "",
                      attrs: {
                        placeholder: "Начните вводить для поиска",
                        "deselect-label": "Отменить",
                        "selected-label": "Выбрано",
                        "select-label": "Выбрать",
                        "track-by": "id",
                        label: "breadcrumb",
                        options: _vm.okeis,
                        searchable: true,
                        "allow-empty": false,
                        loading: _vm.isOkeisLoading,
                        disabled: !!_vm.selectedKtruItem,
                        "internal-search": false,
                        "clear-on-select": true,
                        "close-on-select": true,
                        "options-limit": 300,
                        limit: 3,
                        "max-height": 600,
                        "show-no-results": false,
                        "hide-selected": true,
                        state: _vm.$v.spec.okei.$invalid
                          ? !_vm.$v.spec.okei.$invalid
                          : null,
                        "aria-describedby": "formdata_okpd_liveFeedback",
                      },
                      on: { "search-change": _vm.asyncFindOkeis },
                      model: {
                        value: _vm.spec.okei,
                        callback: function ($$v) {
                          _vm.$set(_vm.spec, "okei", $$v)
                        },
                        expression: "spec.okei",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Нет данных.")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [
                          _vm._v(
                            "Записей, соответствующих вашему запросу, не найдено."
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "b-form-invalid-feedback",
                    {
                      attrs: {
                        id: "formdata_okpd_liveFeedback",
                        state: !_vm.$v.spec.okei.$invalid,
                      },
                    },
                    [_vm._v("Выберите единицу измерения")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelSpecModal },
                    },
                    [_vm._v("Отменить")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        variant: "custom-green",
                        disabled: _vm.$v.spec.$invalid,
                      },
                      on: { click: _vm.addSpec },
                    },
                    [_vm._v("Добавить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ktru-select-modal", {
        attrs: { show: _vm.ktruSelectModalShown },
        on: { cancel: _vm.cancelKtruSelectModal, select: _vm.selectKtruItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }