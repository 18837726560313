import axios from 'axios';
import Contracts from '@/services/api/Contracts';
import DownloadFilesApi from '@/services/api/DownloadFiles';
import { __LINKS } from '@/const';
import Config from '@/rearchitected/core/js/ddriven/application/config/Config';

export default {
    methods: {
        async prepareCertificate(certificate) {
            const serial = await certificate.getCadesProp('SerialNumber');
            return {
                thumbprint: certificate.thumbprint,
                serial: serial,
                issuer: certificate.issuerName,
                subject: certificate.subjectName,
                valid_from: certificate.validFrom,
                valid_to: certificate.validTo
            };
        },
        $formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        $parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
            return '-';
        },
        getCurrentYear() {
            return parseInt(new Date().getFullYear());
        },
        getCurrentDate() {
            return [new Date().getFullYear(), (new Date().getMonth() + 1 > 9 ? '' : '0') + (new Date().getMonth() + 1), (new Date().getDate() + 1 > 9 ? '' : '0') + (new Date().getDate() + 1)].join('-');
        },
        isObject(item) {
            return item && typeof item === 'object' && !Array.isArray(item) && item !== null;
        },
        fetchData(apiUrl, httpOptions) {
            return axios.get(apiUrl, httpOptions);
        },
        $scrollToTop() {
            window.scrollTo(0, 0);
        },

        /**
         * Deep merge two objects.
         * @param target
         * @param source
         */
        mergeDeep(target, source) {
            if (this.isObject(target) && this.isObject(source)) {
                for (const key in source) {
                    if (this.isObject(source[key])) {
                        if (!target[key]) Object.assign(target, { [key]: {} });
                        this.mergeDeep(target[key], source[key]);
                    } else {
                        Object.assign(target, { [key]: source[key] });
                    }
                }
            }
            return target;
        },
        isDevEnv() {
            return process.env.NODE_ENV !== 'production';
        },
        checkIfPurchaseCreator(purchase) {
            return this.$store.getters.isLoggedIn && !this.$store.getters.isProvider && this.$store.state.user.organization_id === purchase.organization_id;
        },
        isPurchaseCreator() {
            return this.$store.getters.isLoggedIn && !this.$store.getters.isProvider && this.$store.state.user.organization_id === this.purchase.organization_id;
        },
        isProvider() {
            return this.$store.getters.isLoggedIn && this.$store.getters.isProvider;
        },
        isCustomer() {
            return this.$store.getters.isLoggedIn && !this.$store.getters.isProvider;
        },
        isGuest() {
            return !this.$store.getters.isLoggedIn;
        },
        isPurchaseOnHold() {
            return this.purchase.is_hold && this.purchase.status_id === this.PURCHASE_STATUS.status_start;
        },
        checkIfPurchaseOnHold(purchase) {
            return purchase.is_hold && purchase.status_id === this.PURCHASE_STATUS.status_start;
        },
        isPurchaseConcludedAndAvailable() {
            return (
                this.purchase.status_id !== this.PURCHASE_STATUS.status_start &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_cancel &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_fail &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_control &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_draft &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_blocked &&
                this.purchase.status_id !== this.PURCHASE_STATUS.status_draft_save_signed
            );
        },
        checkIfPurchaseConcludedAndAvailable(purchase) {
            return (
                purchase.status_id !== this.PURCHASE_STATUS.status_start &&
                purchase.status_id !== this.PURCHASE_STATUS.status_cancel &&
                purchase.status_id !== this.PURCHASE_STATUS.status_fail &&
                purchase.status_id !== this.PURCHASE_STATUS.status_control &&
                purchase.status_id !== this.PURCHASE_STATUS.status_draft &&
                purchase.status_id !== this.PURCHASE_STATUS.status_blocked &&
                purchase.status_id !== this.PURCHASE_STATUS.status_draft_save_signed
            );
        },
        $downloadContractFile(file) {
            Contracts.downloadFileNew(file);
        },
        $downloadPurchaseFile(file) {
            DownloadFilesApi.downloadFileNew(file);
        },
        $documentDownload(file) {
            DownloadFilesApi.documentDownload(file);
        },
        $commonDocumentDownload(file) {
            DownloadFilesApi.commonDocumentDownload(file);
        },
        $getAttachmentDownloadLink(id) {
            return `${Config.get('api.config.legacy.url')}/documents/${id}`;
        }
    },
    computed: {
        $links() {
            return __LINKS;
        }
    }
};
