export default [
    {
        path: '/cabinet',
        component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/Cabinet'),
        name: 'Cabinet',
        children: [
            {
                path: 'ktru_requests',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru-requests/KtruRequestsList'),
                name: 'KtruRequestsList'
            },
            {
                path: 'ktru_requests/create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru-requests/KtruRequestsCreate'),
                name: 'KtruRequestsCreate'
            },
            {
                path: 'ktru_requests/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/ktru-requests/KtruRequestsEdit'),
                name: 'KtruRequestsEdit'
            },
            {
                path: 'notifications',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/Notifications'),
                name: 'NotificationsList'
            },
            {
                path: 'notifications/settings',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/NotificationSettings'),
                name: 'NotificationSettings'
            },
            {
                path: 'users',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UsersList'),
                name: 'UsersList'
            },
            {
                path: 'users/create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UserCreate'),
                name: 'UserCreate'
            },
            {
                path: 'users/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/users/UserEdit'),
                name: 'UserEdit'
            },
            {
                path: 'user_data',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/UserData'),
                name: 'UserData'
            },
            {
                path: 'organization_data',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/common/OrganizationData'),
                name: 'OrganizationData'
            }
        ]
    },
    {
        path: '/cabinet/customer',
        component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/Cabinet'),
        name: 'CustomerMainCabinet',
        redirect: { name: 'ShowPurchases', params: { cat: 'all' } },
        children: [
            {
                path: 'last_purchases/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Main'),
                name: 'ShowPurchases'
            },
            {
                path: 'addresses',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Addresses'),
                name: 'CustomerAddressesCabinet'
            },
            {
                path: 'purchases',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Purchases'),
                name: 'CustomerPurchasesCabinet'
            },
            { path: 'contracts', redirect: 'contracts/all' },
            {
                path: 'contracts/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Contracts'),
                name: 'CustomerContractsCabinet'
            },
            {
                path: 'favorite',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/Favorite'),
                name: 'FavoriteCustomerCabinet'
            },
            {
                path: 'quotation_requests/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/customer/quotation-requests/QuotationRequestsList'),
                name: 'CustomerQuotationRequestsList'
            }
        ]
    },

    {
        path: '/cabinet/provider',
        component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/Cabinet'),
        name: 'ProviderMainCabinet',
        redirect: { name: 'ProviderOffersCabinet', params: { cat: 'all' } },
        children: [
            {
                //     path: '/',
                //     component: () => import( /* webpackChunkName: "main" */ '@/components/cabinets/provider/Main'),
                //     name: 'ProviderMainCabinet',
                // },
                // {
                path: 'account',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Account'),
                name: 'ProviderAccountCabinet'
            },
            {
                path: 'account/refund_request_create',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/accounting/refund-request-create'),
                name: 'CabinetProviderAccountingRefundRequestsCreate'
            },
            {
                path: 'info/bank',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/parts/BankPart'),
                name: 'ProviderBankPart'
            },
            {
                path: 'info/bank/:id',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/parts/BankEdit'),
                name: 'ProviderBankEdit'
            },
            {
                path: 'info/address',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/parts/AddressPart'),
                name: 'ProviderAddressPart'
            },
            {
                path: 'purchases',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Purchases'),
                name: 'ProviderPurchasesCabinet'
            },
            {
                path: 'offers',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Offers'),
                name: 'ProviderOffersCabinet'
            },
            {
                path: 'contracts',
                redirect: 'contracts/all',
                name: 'ProviderContractsAllCabinet'
            },
            {
                path: 'contracts/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Contracts'),
                name: 'ProviderContractsCabinet'
            },
            {
                path: 'favorite',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/Favorite'),
                name: 'FavoriteProviderCabinet'
            },
            {
                path: 'organization-reliability-report',
                component: () => import(/* webpackChunkName: "main" */ 'themes/views/pages/client-space/supplier/organization-reliability-report/OrganizationReliabilityReport.view.vue'),
                name: 'OrganizationReliabilityReport'
            },
            {
                path: 'quotations/:cat',
                component: () => import(/* webpackChunkName: "main" */ '@/components/cabinets/provider/quotations/QuotationsList'),
                name: 'ProviderQuotationsList'
            },
            {
                path: 'productions',
                component: () => import(/* webpackChunkName: "provider-production-list" */ '@/components/cabinets/provider/productions/production-list'),
                name: 'ProviderProductionsList'
            },
            {
                path: 'productions/create',
                component: () => import(/* webpackChunkName: "provider-production-create" */ '@/components/cabinets/provider/productions/production-create'),
                name: 'ProviderProductionsCreate'
            },
            {
                path: 'productions/:id',
                component: () => import(/* webpackChunkName: "provider-production-edit" */ '@/components/cabinets/provider/productions/production-edit'),
                name: 'ProviderProductionsEdit'
            }
        ]
    }
];
