'use strict';

import BaseValueObject from '@core/js/ddriven/application/abstractions/ddriven/BaseValueObject.valueobject';
import { Types as UserTypes } from '@core/js/ddriven/domain/model/users/User.valueobject';

export interface IRawContractDisagreementSignatureData {
    organization_title?: string;
    organization_name?: string;
    owner: string;
    x509certificate: {
        '0': string;
    };
    x509certificateDate: string;
}

export interface IRawSupplementaryAgreementSignatureData {
    user_name: string;
    organization_name: string;
    md5: string;
    signing_datetime: string;
}

export default class X509SignatureVO extends BaseValueObject {
    private _usertype;

    owner: string | null;
    certificate_key: string | null;
    certificate_date: string | null;
    organization_title: string | null;

    constructor(usertype: UserTypes, data?: IRawContractDisagreementSignatureData) {
        super();

        this.defineHiddenProperty('_usertype');
        this._usertype = usertype;

        this.owner = data && data.owner ? data.owner : null;
        this.certificate_key = data && data.x509certificate ? data.x509certificate['0'] : null;
        this.certificate_date = data && data.x509certificateDate ? data.x509certificateDate : null;
        this.organization_title = data?.organization_title ?? data?.organization_name ?? null;
    }

    public static fromSupplementaryAgreement(usertype: UserTypes, data?: IRawSupplementaryAgreementSignatureData): X509SignatureVO {
        if (!data) {
            return new X509SignatureVO(usertype);
        }

        const interimData: IRawContractDisagreementSignatureData = {
            organization_title: data.organization_name,
            owner: data.user_name,
            x509certificate: {
                '0': data.md5
            },
            x509certificateDate: data.signing_datetime
        };

        return new X509SignatureVO(usertype, interimData);
    }

    public hasSignature(): boolean {
        return this.certificate_key !== null;
    }

    public userType(): UserTypes {
        return this._usertype;
    }
}
